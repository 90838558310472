import { generateModuleStore } from "@/shared/lib";
import { http } from "@/shared/api";

const namespace = "fieldValues";

const module = generateModuleStore({
  namespace,
  filterName: "value",
  generateActions: false,
  actions: {
    create(_, data) {
      return new Promise((resolve, reject) => {
        http
          .post("/users/field-values", data)
          .then((response) => {
            resolve(response);
          })
          .catch((e) => reject(e));
      });
    },
    update(_, { id, data }) {
      return new Promise((resolve, reject) => {
        http
          .patch(`/users/field-values/${id}`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((e) => reject(e));
      });
    },
    delete(_, id) {
      return new Promise((resolve, reject) => {
        http
          .delete(`/users/field-values/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((e) => reject(e));
      });
    },
  },
});

export { namespace, module };
