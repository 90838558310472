<template>
  <ui-table
    :loading="pending"
    :concating="concating"
    :columns="columns"
    :data="data"
    @rowClick="rowClick"
  >
    <template #row="{ row, column }">
      <strong v-if="column.field === 'name'">
        {{ row[column.field] }}
      </strong>
      <div v-else-if="column.field === 'id'" class="admin-id">
        ID-{{ row[column.field] }}
      </div>
      <span v-else-if="column.field === 'owner'" class="opacity">
        {{ row[column.field]?.length ? row[column.field][0].email : "" }}
      </span>
      <span v-else-if="column.field === 'employees_count'" class="employees">
        {{ getText(row[column.field]) }}
      </span>
      <span v-else-if="column.field === 'created_at'" class="opacity">
        {{ row[column.field] | convertDate }}
      </span>
      <span v-else class="opacity">
        {{ row[column.field] }}
      </span>
    </template>
  </ui-table>
</template>

<script>
import { UiTable } from "@/shared/ui";
import { convertDate } from "@/shared/lib";
import { companyModel } from "@/entities/company";
import Cookies from "js-cookie";
import { checkAccess } from "@/shared/lib";

export default {
  name: "entity-company-list",
  components: { UiTable },
  filters: {
    convertDate,
  },
  data() {
    return {
      columns: [
        {
          field: "name",
          label: "name",
        },
        {
          field: "owner",
          label: "owner",
        },
        {
          field: "created_at",
          label: "created_at",
        },
      ],
    };
  },
  created() {
    const accessLevel = Cookies.get("ft_access_level");
    if (checkAccess("company-employee-list", Number(accessLevel))) {
      this.columns.push({
        field: "employees_count",
        label: "employees_count",
        style: checkAccess("company-update", Number(accessLevel))
          ? ""
          : "text-align: right",
      });
      if (checkAccess("company-update", Number(accessLevel))) {
        this.columns.push({
          field: "id",
          label: "id",
        });
      }
    }
    this.$store.dispatch(companyModel.namespace + "/get");
  },
  computed: {
    data() {
      return this.$store.getters[companyModel.namespace + "/companies"];
    },
    concating() {
      return this.$store.getters[companyModel.namespace + "/concating"];
    },
    pending() {
      return this.$store.getters[companyModel.namespace + "/pending"];
    },
  },
  methods: {
    rowClick({ row, column }) {
      if (column.field !== "employees_count") {
        this.$router.push(`/company/${row.id}/update`);
      } else {
        this.$router.push(`/company/${row.id}/employee/list`);
      }
    },
    getText(countUser) {
      let n = Math.abs(countUser);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return countUser + " пользователей";
      }
      n %= 10;
      if (n === 1) {
        return countUser + " пользователь";
      }
      if (n >= 2 && n <= 4) {
        return countUser + " пользователя";
      }
      return countUser + " пользователей";
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  background: #fafafa;
  height: 100%;
  @include padding(20, 50, 0, 50);
  overflow: auto;
}
.opacity {
  opacity: 0.5;
}
.employees {
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
    color: #f55320;
    text-decoration: underline;
  }
}
.admin-id {
  opacity: 0.5;
  @include width(60);
  @include fontSize(10);
}
</style>
