<template>
  <button
    class="ui-border-button"
    :class="{
      'ui-border-button--disabled': disabled,
    }"
  >
    <div v-if="loadingLeft" class="ui-border-button--loading-left">
      <simple-loading />
    </div>
    <div v-if="hasIcon" class="ui-border-button__icon">
      <slot name="icon"></slot>
    </div>
    <slot></slot>
  </button>
</template>

<script>
import SimpleLoading from "@/shared/ui/loading/simple";

export default {
  name: "VBorderButton",
  components: { SimpleLoading },
  props: {
    loadingLeft: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasIcon() {
      return !!this.$slots.icon;
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-border-button {
  font-family: "gilroy-medium", "Helvetica", "Arial", "sans-serif";
  display: flex;
  align-items: center;
  background: #fff;
  border: 1 / 7.1 + vh solid #f55320;
  border-radius: 30 / 7.1 + vh;
  /* height: 42 / 7.1 + vh; */
  @include height(33);
  /* padding: 0 18 / 7.1 + vh; */
  @include padding(0, 18, 0, 18);
  box-sizing: border-box;
  color: #f55320;
  cursor: pointer;
  /* font-size: 14 / 7.1 + vh; */
  @include fontSize(10);
  white-space: nowrap;
  &--loading-left {
    @include width(14);
    @include height(14);
    @include margin(0, 10, 0, 0);
  }
  &--disabled {
    border-color: #e8e8e8;
    background: #eeeeee;
    color: #c3c3c3;
    pointer-events: none;
  }
  @media (max-width: 500px) {
    font-size: 14 / 4.8 + vw;
    border: 1 / 4.8 + vw solid #f55320;
    border-radius: 30 / 4.8 + vw;
    height: 42 / 4.8 + vw;
    padding: 0 18 / 4.8 + vw;
  }
  &__icon {
    display: flex;
    @include margin(0, 9, 0, 0);
    @include width(11);
    @include height(11);
    color: #f55320;
    svg {
      height: 100%;
      width: auto;
    }
  }
}
</style>
