export const deleteEntityMixin = {
  data() {
    return {
      id: null,
      namespace: null,
      redirectAfterDeletion: null,
    };
  },
  methods: {
    remove() {
      this.$store.dispatch(this.namespace + "/delete", this.id).then(() => {
        this.$router.push({ name: this.redirectAfterDeletion });
      });
    },
  },
};
