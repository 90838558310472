<template>
  <aside>
    <div class="dot-fire"></div>
  </aside>
</template>

<script>
export default {
  name: "loading-pagination",
};
</script>

<style lang="scss" scoped>
aside {
  display: flex;
  justify-content: center;
  @include margin(20, 0, 0, 0);
}
.dot-fire {
  position: relative;
  left: -9999px;
  /* width: 10px; */
  @include width(10);
  @include height(10);
  /* height: 10px; */
  border-radius: 5 / 7.1 + vh;
  background-color: #f55320;
  color: #f55320;
  box-shadow: 9999px 22.5px 0 -5px #f55320;
  animation: dot-fire 1.5s infinite linear;
  animation-delay: -0.85s;
}
.dot-fire::before,
.dot-fire::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  /* width: 10px; */
  /* height: 10px; */
  @include width(10);
  @include height(10);
  border-radius: 5 / 7.1 + vh;
  /* border-radius: 5px; */
  background-color: #f55320;
  color: #f55320;
}
.dot-fire::before {
  box-shadow: 9999px 22.5px 0 -5px #f55320;
  animation: dot-fire 1.5s infinite linear;
  animation-delay: -1.85s;
}
.dot-fire::after {
  box-shadow: 9999px 22.5px 0 -5px #f55320;
  animation: dot-fire 1.5s infinite linear;
  animation-delay: -2.85s;
}

@keyframes dot-fire {
  1% {
    box-shadow: 9999px 22.5px 0 -5px #f55320;
  }
  50% {
    box-shadow: 9999px -5.625px 0 2px #f55320;
  }
  100% {
    box-shadow: 9999px -22.5px 0 -5px #f55320;
  }
}
</style>
