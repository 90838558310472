var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{ref:"select",staticClass:"ui-select",class:{
    'ui-select--not-data': !_vm.options?.length,
    'ui-select--focus-within': _vm.viewOptions,
  },attrs:{"tabindex":"0"}},[(!_vm.loading && _vm.multiselect && !_vm.viewOptions)?_c('ul',{staticClass:"ui-select__box-tag"},_vm._l((_vm.value),function(tag,index){return _c('li',{key:'tag' + index,staticClass:"ui-select__tag"},[_vm._v(" "+_vm._s(_vm.outputIdentificator ? _vm.identificatorApplyMask(tag) : _vm.applyMask(tag))+" ")])}),0):_vm._e(),_c('input',{ref:"input",staticClass:"ui-select__value",attrs:{"placeholder":_vm.placeholder},on:{"input":_vm.search,"click":_vm.focusInput}}),_c('div',{staticClass:"ui-select__icon"},[(!_vm.loading)?_c('chevron-icon'):_c('loading-simple')],1),_c('ul',{staticClass:"ui-select__options",on:{"scroll":_vm.scrollPage}},[(!_vm.searchLoading && _vm.options.length)?_vm._l((_vm.options),function(option,index){return _c('li',{key:index,staticClass:"ui-select__option",class:[
          {
            'ui-select__option--active': _vm.outputIdentificator
              ? _vm.multiselect
                ? _vm.value?.length
                  ? _vm.value.includes(option[_vm.outputIdentificator])
                  : false
                : _vm.value === option[_vm.outputIdentificator]
              : _vm.multiselect
              ? _vm.value?.length
                ? _vm.value
                    .map((value) => value[_vm.identificator])
                    .includes(option[_vm.identificator])
                : false
              : _vm.value
              ? _vm.value[_vm.identificator] === option[_vm.identificator]
              : false,
          },
        ],on:{"click":function($event){_vm.selected(
            option,
            _vm.outputIdentificator
              ? _vm.multiselect
                ? _vm.value?.length
                  ? _vm.value.includes(option[_vm.outputIdentificator])
                  : false
                : _vm.value === option[_vm.outputIdentificator]
              : _vm.multiselect
              ? _vm.value?.length
                ? _vm.value
                    .map((value) => value[_vm.identificator])
                    .includes(option[_vm.identificator])
                : false
              : _vm.value
              ? _vm.value[_vm.identificator] === option[_vm.identificator]
              : false
          )}}},[_vm._t("option",function(){return [_vm._v(_vm._s(_vm.applyMask(option)))]},{"option":option})],2)}):(!_vm.searchLoading && !_vm.options.length)?_c('li',{staticClass:"ui-select__option--notfound"},[_vm._v(" Нет данных ")]):_c('li',{staticClass:"ui-select__option--loading"},[_c('loading-simple')],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }