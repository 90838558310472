<template>
  <div id="app">
    <router-view v-if="!pending" />
    <modal-simple
      v-if="display"
      :notClose="notClose"
      :label="label"
      @close="close"
    >
      <component :is="content" @close="close" />
    </modal-simple>
  </div>
</template>

<script>
import { ModalSimple } from "@/shared/ui";
import { notificationModel } from "@/entities/notification";
import { viewerModel } from "@/entities/viewer";

export default {
  name: "app",
  components: { ModalSimple },
  data() {
    return {
      pending: true,
    };
  },
  computed: {
    notClose() {
      if (
        this.$store.getters[
          notificationModel.namespace + "/" + notificationModel.namespace
        ]["notClose"]
      ) {
        return this.$store.getters[
          notificationModel.namespace + "/" + notificationModel.namespace
        ]["notClose"];
      } else {
        return false;
      }
    },
    display() {
      return this.$store.getters[
        notificationModel.namespace + "/" + notificationModel.namespace
      ]["display"];
    },
    label() {
      return this.$store.getters[
        notificationModel.namespace + "/" + notificationModel.namespace
      ]["label"];
    },
    content() {
      return this.$store.getters[
        notificationModel.namespace + "/" + notificationModel.namespace
      ]["content"];
    },
  },
  created() {
    this.$store
      .dispatch(viewerModel.namespace + "/current")
      .finally(() => {
        this.pending = false;
      })
      .catch(() => {});
  },
  methods: {
    close() {
      this.$store.commit(
        notificationModel.namespace + "/" + notificationModel.namespace,
        {
          display: false,
          label: null,
          content: null,
        }
      );
    },
  },
};
</script>

<style lang="scss">
@import "./styles/index.scss";
</style>
