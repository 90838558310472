export const namespace = "modal";
export const module = {
  namespaced: true,
  state: {
    show: false,
    label: null,
    content: null,
    selected: null,
  },
  getters: {
    show: (s) => s.show,
    label: (s) => s.label,
    content: (s) => s.content,
    selected: (s) => s.selected,
  },
  mutations: {
    show(state, value) {
      state.show = value;
    },
    label(state, value) {
      state.label = value;
    },
    content(state, value) {
      state.content = value;
    },
    selected(state, value) {
      state.selected = value;
    },
  },
  actions: {
    setup(
      { commit },
      { show = false, label = null, content = null, selected = null }
    ) {
      commit("show", show);
      commit("label", label);
      commit("content", content);
      commit("selected", selected);
    },
  },
};
