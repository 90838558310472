import Vue from "vue";
import Vuex from "vuex";
import { entities } from "@/entities";
import { features } from "@/features";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    errors: {},
  },
  getters: {
    errors: (s) => s.errors,
  },
  mutations: {
    errors(state, value) {
      state.errors = value;
    },
  },
  actions: {},
  modules: getModules(),
});

function getModules() {
  let modules = {};
  Object.values(entities).forEach((model) => {
    modules[model.namespace] = model.module;
  });
  Object.values(features).forEach((model) => {
    modules[model.namespace] = model.module;
  });
  return modules;
}
