import { generateModuleStore } from "@/shared/lib";
const namespace = "responses";

const module = generateModuleStore({
  namespace,
  filterName: "offer_id",
  state: {
    aditionFilter: "",
  },
});

export { namespace, module };
