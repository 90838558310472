import { generateModuleStore } from "@/shared/lib";
import { http } from "@/shared/api";

const namespace = "candidates";

const module = generateModuleStore({
  namespace,
  filterName: "surname",
  actions: {
    import({ state, commit }, { companyId, data }) {
      return new Promise((resolve, reject) => {
        if (!state.pending) {
          commit("pending", true);
          http
            .post(`companies/${companyId}/candidates/import`, data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((errors) => {
              reject(errors);
            })
            .finally(() => {
              commit("pending", false);
            });
        }
      });
    },
  },
});

export { namespace, module };
