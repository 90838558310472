import { checkAccess, checkRole } from ".";

function accessCheck(entities, accessLevel, roleLevel) {
  if (!accessLevel && !roleLevel) return false;

  if (accessLevel && roleLevel) {
    return checkAccess(entities, accessLevel) || checkRole(entities, roleLevel);
  }

  if (!accessLevel) return false;

  return checkAccess(entities, accessLevel);
}

export { accessCheck };
