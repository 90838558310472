<template>
  <ui-table
    @rowClick="rowClick"
    :loading="pending"
    :columns="columns"
    :data="data"
  >
    <template #row="{ row, column }">
      <strong v-if="column.field === 'position'">
        {{ row[column.field] }}
      </strong>
      <div v-else-if="column.field === 'id'" class="admin-id">
        ID-{{ row[column.field] }}
      </div>
      <span v-else class="opacity">
        {{ row[column.field] }}
      </span>
    </template>
  </ui-table>
</template>

<script>
import UiTable from "@/shared/ui/table";
import { managerModel } from "@/entities/manager";
import { viewerModel } from "@/entities/viewer";
import { convertAccessLevel } from "@/shared/lib";
import Cookies from "js-cookie";
import { checkAccess } from "@/shared/lib";

export default {
  name: "entity-manager-list",
  components: { UiTable },
  filters: { convertAccessLevel },
  data() {
    return {
      columns: [
        {
          field: "position",
          label: "position",
        },
        {
          field: "email",
          label: "email",
        },
        {
          field: "telegram",
          label: "telegram",
        },
      ],
    };
  },
  created() {
    const accessLevel = Cookies.get("ft_access_level");
    this.$store.dispatch(managerModel.namespace + "/get");
    if (checkAccess("company-update", Number(accessLevel))) {
      this.columns.push({
        field: "id",
        label: "id",
      });
    }
  },
  watch: {
    currentCompany(company) {
      if (!company) {
        return this.$router.push(`/company/list`);
      }

      const companyId = company.id;

      if (companyId !== 1001) {
        return this.$router.push(`/company/list`);
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters[managerModel.namespace + "/managers"];
    },
    pending() {
      return this.$store.getters[managerModel.namespace + "/pending"];
    },
    currentCompany() {
      return this.$store.getters[viewerModel.namespace + "/currentCompany"];
    },
  },
  methods: {
    rowClick({ row }) {
      this.$router.push(`/manager/${row.id}/update`);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  background: #fafafa;
  height: 100%;
  @include padding(20, 50, 0, 50);
  overflow: auto;
}
.opacity {
  opacity: 0.5;
}
.manager {
  &--blocked {
    color: #f55320;
    display: flex;
    align-items: center;
    svg {
      width: auto;
      @include margin(0, 5, 0, 0);
      @include height(11);
    }
  }
  &--unblocked {
    color: #14b011;
    display: flex;
    align-items: center;
    svg {
      width: auto;
      @include margin(0, 5, 0, 0);
      @include height(11);
    }
  }
}
.access-level {
  background: #fceee9;
  border-radius: 20 / 7.1 + vh;
  color: #e57047;
  max-width: 130px;
  width: 100%;
  text-align: center;
  @include padding(4, 15, 4, 15);
}
.admin-id {
  opacity: 0.5;
  @include width(60);
  @include fontSize(10);
}
</style>
