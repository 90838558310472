<template>
  <section>
    Импортирование кандидатов прошло успешно. Новые кандидаты появятся в скором
    времени.
  </section>
</template>

<script>
export default {
  name: "notification-candidate-import",
};
</script>

<style lang="scss" scoped>
section {
  opacity: 0.8;
  @include fontSize(13);
}
</style>
