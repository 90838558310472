<template>
  <section v-if="tabs?.length" class="tabs">
    <div
      v-for="(tab, index) in tabs"
      class="tab"
      :class="active === tab.field ? 'tab--active' : ''"
      :key="`tab-${index}`"
      @click="$emit('change', tab.field)"
    >
      <div v-if="tab.name" class="tab__name">{{ tab.name }}</div>
      <div v-if="tab.notification" class="tab__notification">
        {{ tab.notification }}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "tabs",
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  @include height(45);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
}
.tab {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  box-sizing: border-box;
  /* margin-right: 16 / 14.4 + vw; */
  cursor: pointer;
  @include padding(0, 24, 0, 24);
  @media (max-width: 500px) {
    width: 100%;
    justify-content: center;
  }
  &__name {
    font-family: "gilroy-medium", "Helvetica", "Arial", "sans-serif";
    text-transform: uppercase;
    @include fontSize(13);
    color: #9999a1;
  }
  &__notification {
    box-sizing: border-box;
    @include height(18);
    /* height: 18 / 7.1 + vh; */
    min-width: 18 / 7.1 + vh;
    border-radius: 9 / 7.1 + vh;
    background: #f55320;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    /* font-size: 7 / 7.1 + vh; */
    @include fontSize(7);
    font-weight: 800;
    /* margin-left: 6 / 7.1 + vh; */
    @include margin(0, 0, 0, 6);
    /* padding-top: 0; */
    /* padding-bottom: 0; */
    /* padding-left: 6 / 7.1 + vh; */
    /* padding-right: 6 / 7.1 + vh; */
    @include padding(0, 6, 0, 6);
    /* @media (max-width: 500px) { */
    /* height: 18 / 4.8 + vw; */
    /* border-radius: 9 / 4.8 + vh; */
    /* font-size: 10 / 4.8 + vh; */
    /* padding-left: 6 / 4.8 + vh; */
    /* padding-right: 6 / 4.8 + vh; */
    /* margin-left: 6 / 4.8 + vh; */
    /* } */
  }
  &--active {
    &:before {
      font-family: "gilroy-semibold", "Helvetica", "Arial", "sans-serif";
      content: "";
      width: 100%;
      /* height: 3 / 7.1 + vh; */
      @include height(3);
      background: #f55320;
      position: absolute;
      bottom: -1 / 7.1 + vh;
      left: 0;
      border-top-left-radius: 2 / 7.1 + vh;
      border-top-right-radius: 2 / 7.1 + vh;
      @media (max-width: 500px) {
        border-top-left-radius: 2 / 4.8 + vw;
        border-top-right-radius: 2 / 4.8 + vw;
        /* height: 4 / 4.8 + vw; */
      }
    }
  }
}
</style>
