<template>
  <section>
    <textarea
      :value="value || ''"
      :placeholder="placeholder"
      @input="(e) => $emit('input', e.target.value)"
      class="simple-textarea"
    ></textarea>
    <div v-if="error" class="simple-error">{{ error }}</div>
  </section>
</template>

<script>
export default {
  name: "UiInput",
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.simple-textarea {
  font-family: "gilroy-regular", "Helvetica", "Arial", "sans-serif";
  @include fontSize(10);
  border-radius: 15 / 7.1 + vh;
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  min-height: 100 / 7.1 + vh;
  border: 1 / 7.1 + vh solid #e8e8e8;
  background: #fff;
  outline: none;
  @include padding(15, 18, 15, 18);
  &:focus {
    border-color: #f55320;
  }
  &::-webkit-input-placeholder {
    color: #c8c8c8;
  }
  &::-moz-placeholder {
    color: #c8c8c8;
  }
  &:-ms-input-placeholder {
    color: #c8c8c8;
  }
  &:-moz-placeholder {
    color: #c8c8c8;
  }
}
.simple-error {
  color: #ff0000;
  @include fontSize(10);
  @include margin(2, 0, 0, 0);
}
</style>
