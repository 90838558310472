<template>
  <ui-table
    @rowClick="rowClick"
    :loading="pending"
    :columns="columns"
    :data="data"
  >
    <template #row="{ row, column }">
      <strong v-if="column.field === 'name'">
        {{ row[column.field] }}
      </strong>
      <div v-else-if="column.field === 'id'" class="admin-id">
        ID-{{ row[column.field] }}
      </div>
      <div v-else-if="column.field === 'blocked'">
        <div v-if="row[column.field]" class="user--blocked">
          <cross-icon />Заблокирован
        </div>
        <div v-else class="user--unblocked"><check-icon />Активен</div>
      </div>
      <span v-else-if="column.field === 'access_level'" class="access-level">
        {{ row[column.field] | convertAccessLevel }}
      </span>
      <span v-else class="opacity">
        {{ row[column.field] }}
      </span>
    </template>
  </ui-table>
</template>

<script>
import UiTable from "@/shared/ui/table";
import { userModel } from "@/entities/user";
import { convertAccessLevel } from "@/shared/lib";
import { CheckIcon, CrossIcon } from "@/shared/icons";
import Cookies from "js-cookie";
import { checkAccess } from "@/shared/lib";

export default {
  name: "entity-user-list",
  components: { UiTable, CheckIcon, CrossIcon },
  filters: { convertAccessLevel },
  data() {
    return {
      columns: [
        {
          field: "name",
          label: "name",
        },
        {
          field: "email",
          label: "email",
        },
        {
          field: "blocked",
          label: "blocked",
        },
      ],
    };
  },
  created() {
    const accessLevel = Cookies.get("ft_access_level");
    this.$store.dispatch(userModel.namespace + "/get");
    if (checkAccess("company-update", Number(accessLevel))) {
      this.columns.push({
        field: "access_level",
        label: "access_level",
      });
      this.columns.push({
        field: "id",
        label: "id",
      });
    } else {
      this.columns.push({
        field: "access_level",
        label: "access_level",
        style: "text-align: right",
      });
    }
  },
  computed: {
    data() {
      return this.$store.getters[userModel.namespace + "/users"];
    },
    pending() {
      return this.$store.getters[userModel.namespace + "/pending"];
    },
  },
  methods: {
    rowClick({ row }) {
      this.$router.push(`/user/${row.id}/update`);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  background: #fafafa;
  height: 100%;
  @include padding(20, 50, 0, 50);
  overflow: auto;
}
.opacity {
  opacity: 0.5;
}
.user {
  &--blocked {
    color: #f55320;
    display: flex;
    align-items: center;
    svg {
      width: auto;
      @include margin(0, 5, 0, 0);
      @include height(11);
    }
  }
  &--unblocked {
    color: #14b011;
    display: flex;
    align-items: center;
    svg {
      width: auto;
      @include margin(0, 5, 0, 0);
      @include height(11);
    }
  }
}
.access-level {
  background: #fceee9;
  border-radius: 20 / 7.1 + vh;
  color: #e57047;
  max-width: 130px;
  width: 100%;
  text-align: center;
  @include padding(4, 15, 4, 15);
}
.admin-id {
  opacity: 0.5;
  @include width(60);
  @include fontSize(10);
}
</style>
