<template>
  <form @submit.prevent="restore" class="restore-password">
    <h2>Восстановление пароля</h2>
    <template v-if="!isRestoreSuccess">
      <ui-input
        v-model="email"
        name="email"
        placeholder="E-mail"
        class="restore-password__input"
        :error="error('email')"
      />
      <ui-button
        class="restore-password__button"
        :disabled="pending"
        :loading-left="pending"
        >Восстановить</ui-button
      >
    </template>
    <template v-else>
      <div style="color: #787878">
        Восстановление пароля прошло успешно. Электронное письмо с дальнейшими
        инструкциями придёт в ближайшее время.
      </div>
    </template>
    <router-link class="link" to="/sign-in">Авторизация</router-link>
  </form>
</template>

<script>
import { AuthRestorePasswordModel } from "@/features/auth";
import { UiInput, UiButton } from "@/shared/ui";

export default {
  name: "AuthRestorePassword",
  components: { UiInput, UiButton },
  data() {
    return {
      namespace: AuthRestorePasswordModel.namespace,
      isRestoreSuccess: false,
    };
  },
  created() {
    this.isRestoreSuccess = false;
    this.$store.commit("errors", {});
  },
  computed: {
    pending() {
      return this.$store.getters[this.namespace + "/pending"];
    },
    email: {
      get() {
        return this.$store.getters[this.namespace + "/email"];
      },
      set(value) {
        this.$store.commit(this.namespace + "/email", value);
      },
    },
  },
  methods: {
    error(key) {
      if (this.$store.getters.errors[key]) {
        return this.$store.getters.errors[key][0];
      } else {
        return "";
      }
    },
    restore() {
      this.$store.dispatch(this.namespace + "/restorePassword").then(() => {
        this.isRestoreSuccess = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  display: block;
  width: 100%;
  text-align: right;
  margin-top: 20px;
  font-size: 14px;
  color: #b6b6b6;
  transition: color 0.35s ease;
  &:hover {
    color: #f55320;
  }
}

.restore-password {
  border: 1px solid #e8e8e8;
  border-radius: 7 / 7.1 + vh;
  background: #fff;
  @include padding(40, 40, 70, 40);
  @include width(350);
  @media (max-width: 500px) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    padding: 20px;
    height: 100%;
  }
  &__input {
    @include margin(15, 0, 0, 0);
  }
  &__button {
    @include margin(15, 0, 0, 0);
  }
  h2 {
    text-align: center;
    @include margin(0, 0, 30, 0);
    @include fontSize(20);
    @media (max-width: 500px) {
      margin-bottom: 20px;
    }
  }
}
</style>
