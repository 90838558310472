const statusList = {
  creating: "Новый",
  created: "Создан",
  updating: "Обновление",
  published: "Опубликован",
  validating: "На проверке",
  validated: "Проверен",
  sending: "Отправка",
  sent: "Отправлен",
  seen: "Просмотрен",
  rejected: "Отклонён",
  accepted: "Принят",
  in_progress: "В обработке",
  archived: "В архиве",
  unarchived: "Разархивирован",
  responded: "Отвечен",
  expired: "Истёк",
};

function convertStatus(status) {
  if (statusList[status]) {
    return statusList[status];
  } else {
    return status;
  }
}

export { statusList, convertStatus };
