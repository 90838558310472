<template>
  <label
    ref="select"
    tabindex="0"
    class="ui-select"
    :class="{
      'ui-select--not-data': !options?.length,
      'ui-select--focus-within': viewOptions,
    }"
  >
    <ul
      v-if="!loading && multiselect && !viewOptions"
      class="ui-select__box-tag"
    >
      <li
        v-for="(tag, index) in value"
        class="ui-select__tag"
        :key="'tag' + index"
      >
        {{ outputIdentificator ? identificatorApplyMask(tag) : applyMask(tag) }}
      </li>
    </ul>
    <input
      ref="input"
      @input="search"
      @click="focusInput"
      class="ui-select__value"
      :placeholder="placeholder"
    />
    <div class="ui-select__icon">
      <chevron-icon v-if="!loading" />
      <loading-simple v-else />
    </div>
    <ul class="ui-select__options" @scroll="scrollPage">
      <template v-if="!searchLoading && options.length">
        <li
          v-for="(option, index) in options"
          :key="index"
          class="ui-select__option"
          :class="[
            {
              'ui-select__option--active': outputIdentificator
                ? multiselect
                  ? value?.length
                    ? value.includes(option[outputIdentificator])
                    : false
                  : value === option[outputIdentificator]
                : multiselect
                ? value?.length
                  ? value
                      .map((value) => value[identificator])
                      .includes(option[identificator])
                  : false
                : value
                ? value[identificator] === option[identificator]
                : false,
            },
          ]"
          @click="
            selected(
              option,
              outputIdentificator
                ? multiselect
                  ? value?.length
                    ? value.includes(option[outputIdentificator])
                    : false
                  : value === option[outputIdentificator]
                : multiselect
                ? value?.length
                  ? value
                      .map((value) => value[identificator])
                      .includes(option[identificator])
                  : false
                : value
                ? value[identificator] === option[identificator]
                : false
            )
          "
        >
          <slot name="option" :option="option">{{ applyMask(option) }}</slot>
        </li>
      </template>
      <li
        v-else-if="!searchLoading && !options.length"
        class="ui-select__option--notfound"
      >
        Нет данных
      </li>
      <li v-else class="ui-select__option--loading">
        <loading-simple />
      </li>
    </ul>
  </label>
</template>

<script>
import { ChevronIcon } from "@/shared/icons";
import { LoadingSimple } from "@/shared/ui";

export default {
  name: "UiSelect",
  components: { ChevronIcon, LoadingSimple },
  props: {
    notFirstSelect: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Array, Number, Object],
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    maskValue: {
      type: String,
      default: null,
    },
    outputIdentificator: {
      type: String,
      defult: null,
    },
    identificator: {
      type: String,
      required: true,
    },
    options: {
      type: [Array, Object],
      defult: function () {
        return [];
      },
    },
  },
  data() {
    return {
      searchTimer: 0,
      viewOptions: false,
      placeholder: "Нет данных",
      searchLoading: false,
      savedInputValue: null,
      savedValue: null,
    };
  },
  created() {
    if (this.loading) {
      this.placeholder = "Загрузка данных...";
    } else {
      this.placeholder = "Поиск...";
    }
  },
  mounted() {
    if (!this.loading && this.value) {
      this.$refs.input.value = this.applyMask(this.value);
      this.savedInputValue = this.$refs.input.value;
    }
  },
  watch: {
    value(value) {
      if (this.savedValue) {
        this.$refs.input.value = this.applyMask(this.savedValue);
      } else {
        this.$refs.input.value = this.applyMask(value);
        this.savedValue = null;
      }
      this.savedInputValue = this.$refs.input.value;
    },
    options() {
      this.searchLoading = false;
    },
    loading(value) {
      if (value) {
        this.$refs.input.value = "";
        this.placeholder = "Загрузка данных...";
      } else {
        if (!this.options?.length) {
          this.placeholder = "Нет данных";
        } else {
          if (this.value) {
            if (this.outputIdentificator) {
              if (!this.multiselect) {
                this.options.forEach((option) => {
                  if (option[this.outputIdentificator] === this.value) {
                    this.$refs.input.value = this.applyMask(option);

                    this.savedInputValue = this.$refs.input.value;
                  }
                });
              }
            } else {
              if (!this.multiselect) {
                this.$refs.input.value = this.applyMask(this.value);

                this.savedInputValue = this.$refs.input.value;
              }
            }

            if (this.outputIdentificator) {
              if (this.multiselect) {
                this.options.forEach((option) => {
                  if (
                    option[this.identificator] ===
                    this.value[this.identificator]
                  ) {
                    this.$emit("input", [option[this.outputIdentificator]]);
                  }
                });
              } else {
                this.options.forEach((option) => {
                  if (
                    option[this.identificator] ===
                    this.value[this.identificator]
                  ) {
                    this.$emit("input", option[this.outputIdentificator]);
                  }
                });
              }
            } else {
              if (this.multiselect) {
                this.options.forEach((option) => {
                  if (
                    option[this.identificator] ===
                    this.value[this.identificator]
                  ) {
                    this.$emit("input", [option]);
                  }
                });
              } else {
                this.options.forEach((option) => {
                  if (
                    option[this.identificator] ===
                    this.value[this.identificator]
                  ) {
                    this.$emit("input", option);
                  }
                });
              }
            }
          } else {
            if (!this.notFirstSelect) {
              this.$refs.input.value = this.applyMask(this.options[0]);
              this.savedInputValue = this.$refs.input.value;
            }

            if (this.outputIdentificator) {
              if (this.multiselect) {
                this.$emit("input", [
                  this.options[0][this.outputIdentificator],
                ]);
              } else {
                this.$emit("input", this.options[0][this.outputIdentificator]);
              }
            } else {
              if (this.multiselect) {
                this.$emit("input", [this.options[0]]);
              } else {
                if (!this.notFirstSelect) {
                  this.$emit("input", this.options[0]);
                }
              }
            }
          }

          this.placeholder = "";
        }
      }
    },
  },
  methods: {
    identificatorApplyMask(identificator) {
      let temp = null;
      this.options.forEach((val) => {
        if (val[this.outputIdentificator] === identificator) {
          temp = val;
        }
      });
      return this.applyMask(temp);
    },
    applyMask(obj) {
      if (obj) {
        if (typeof obj === "object") {
          return (
            this.maskValue.replace(/{(.*?)}/gm, function (value) {
              return obj[value.replace(/{|}/g, "")] || "";
            }) || ""
          );
        } else {
          let temp = "";
          this.options.forEach((option) => {
            if (option[this.identificator] === obj) {
              temp =
                this.maskValue.replace(/{(.*?)}/gm, function (value) {
                  return option[value.replace(/{|}/g, "")] || "";
                }) || "";
            }
          });
          return temp;
        }
      } else {
        return "";
      }
    },
    blurSelect(event, value) {
      let checkClassName = true;
      const list = [
        "ui-select__value",
        "ui-select__option",
        "ui-select__options",
        "ui-select__icon",
      ];
      if (event?.target?.classList) {
        [...event.target.classList].forEach((className) => {
          if (list.includes(className)) {
            checkClassName = false;
          }
        });
      }

      if (checkClassName) {
        document.body.removeEventListener("click", this.blurSelect);

        if (this.outputIdentificator) {
          if (!this.multiselect) {
            if (value) {
              this.$refs.input.value = this.applyMask(value);
              this.savedInputValue = this.$refs.input.value;
            } else {
              if (this.savedInputValue) {
                this.$refs.input.value = this.savedInputValue;
              } else {
                this.$refs.input.value = "";
              }
            }
          }
        } else {
          if (value) {
            this.$refs.input.value = this.applyMask(value);
            this.savedInputValue = this.$refs.input.value;
          } else {
            this.$refs.input.value = this.applyMask(this.value);
            this.savedInputValue = this.$refs.input.value;
          }
        }

        this.placeholder = "";

        if (event) {
          this.viewOptions = false;
        }
        this.$emit("blur");
      }
    },
    focusInput(event) {
      if (!this.viewOptions) {
        this.placeholder = "Поиск...";
        this.$refs.input.value = "";
        event.preventDefault();
        this.viewOptions = true;
        document.body.addEventListener("click", this.blurSelect);
        this.$emit("focus");
      } else {
        this.viewOptions = false;
        this.$refs.input.blur();
      }
    },
    scrollPage(e) {
      if (
        e.target.scrollTop + e.target.clientHeight + 20 >=
        e.target.scrollHeight
      ) {
        this.$emit("scrollend");
      }
    },
    search(event) {
      this.searchLoading = true;
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.$emit("search", event.target.value);
      }, 800);
    },
    selected(value, checked) {
      // удаляем выбранный элемент
      if (checked) {
        if (this.multiselect) {
          if (this.outputIdentificator) {
            const temp = this.value.filter(
              (val) => val !== value[this.outputIdentificator]
            );
            this.$emit("input", temp);
          } else {
            const temp = this.value.filter((val) => val !== value);
            this.$emit("input", temp);
          }
        } else {
          this.viewOptions = false;
        }
      } else {
        // выбираем элемент
        if (this.outputIdentificator) {
          if (this.multiselect) {
            if (this.value?.length) {
              this.$emit(
                "input",
                this.value.concat(value[this.outputIdentificator])
              );
            } else {
              this.$emit("input", [value[this.outputIdentificator]]);
            }
          } else {
            // ToDo
            this.savedValue = value;
            this.$refs.input.value = this.applyMask(
              value[this.outputIdentificator]
            );
            // this.savedInputValue = this.$refs.input.value;
            this.$emit("input", value[this.outputIdentificator]);
            this.blurSelect(null, value);
          }
        } else {
          if (this.multiselect) {
            this.$emit("input", this.value.concat(value));
          } else {
            this.$emit("input", value);
            this.blurSelect(null, value);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-select {
  position: relative;
  border: 1 / 7.1 + vh solid #e8e8e8;
  border-radius: 17 / 7.1 + vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  background: #fff;
  margin-bottom: 0;
  @include fontSize(10);
  @include height(33);
  @include padding(0, 18, 0, 18);
  &--focus-within {
    border-color: #f55320;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .ui-select__icon {
      color: #f55320;
      transform: rotate(180deg);
    }
    .ui-select__options {
      display: block;
      color: #f55320;
      height: max-content;
      max-height: 250 / 7.1 + vh;
    }
  }
  @media (max-width: 500px) {
    border: 1px solid #e8e8e8;
  }
  &__value {
    outline: none;
    border: none;
    width: 100%;
    @include padding(0, 10, 0, 0);
    @include fontSize(10);
  }
  &__option {
    color: #000;
    margin: 0;
    list-style-type: none;
    @include padding(10, 18, 10, 18);
    cursor: pointer;
    &:hover {
      background: #f0f0f0;
    }
    &--loading {
      color: #f55320;
      @include padding(18, 0, 18, 0);
      aside {
        @include height(20);
      }
    }
    &--notfound {
      @include padding(18, 0, 18, 0);
      text-align: center;
      color: #f55320;
    }
    &--active {
      background: #f55320;
      color: #fff;
      &:hover {
        background: #f55320;
      }
    }
  }
  &__options {
    display: none;
    position: absolute;
    top: 100%;
    left: -1px;
    overflow: auto;
    border: 1px solid red;
    background: #fff;
    z-index: 1;
    margin: 0;
    border-radius: 17 / 7.1 + vh;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: calc(100% + 2px);
    margin: 0;
    padding: 0;
    height: max-content;
    -ms-overflow-style: none;
    scrollbar-width: none;
    @include fontSize(10);
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  &__icon {
    transition: all 0.3s ease;
    color: #e8e8e8;
    aside {
      color: #f55320;
      @include width(20);
      @include height(20);
    }
  }
  &--not-data {
    pointer-events: none;
  }
  &__box-tag {
    display: flex;
    position: absolute;
    width: 100%;
    z-index: 2;
    top: 50%;
    left: 5 / 7.1 + vh;
    margin: 0;
    padding: 0;
    transform: translate(0, -50%);
  }
  &__tag {
    list-style-type: none;
    background: #f55320;
    color: #fff;
    border-radius: 10 / 7.1 + vh;
    @include margin(0, 0, 0, 5);
    @include padding(2, 5, 2, 5);
  }
}
</style>
