<template>
  <section class="ui-checkbox__wrapper">
    <label class="ui-checkbox__box">
      <input v-model="checked" type="checkbox" />
      <div class="ui-checkbox"></div>
      <span v-if="label">{{ label }}</span>
    </label>
    <div v-if="error" class="simple-error">{{ error }}</div>
  </section>
</template>

<script>
export default {
  name: "UiCheckbox",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
  },
  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value ? true : false);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  display: none;
  &:checked {
    & ~ .ui-checkbox {
      background: #f55320;
      &:before {
        left: 21 / 7.1 + vh;
        background: #fff;
      }
    }
  }
}
.ui-checkbox {
  position: relative;
  border-radius: 30 / 7.1 + vh;
  border: 1 / 7.1 + vh solid #e8e8e8;
  overflow: hidden;
  cursor: pointer;
  @include width(40);
  @include height(20);
  &:before {
    content: "";
    transition: left 0.3s ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2 / 7.1 + vh;
    border-radius: 50%;
    @include width(15);
    @include height(15);
    background: #f55320;
  }
  &__wrapper {
    @include margin(0, 0, 20, 0);
  }
  &__box {
    display: flex;
    align-items: center;
    span {
      @include margin(0, 0, 0, 10);
      @include fontSize(12);
    }
  }
}
label {
  width: max-content;
}
.simple-error {
  color: #ff0000;
  @include fontSize(10);
  @include margin(2, 0, 0, 0);
}
</style>
