import { render, staticRenderFns } from "./archive-out.vue?vue&type=template&id=98841768&scoped=true"
import script from "./archive-out.vue?vue&type=script&lang=js"
export * from "./archive-out.vue?vue&type=script&lang=js"
import style0 from "./archive-out.vue?vue&type=style&index=0&id=98841768&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98841768",
  null
  
)

export default component.exports