import axios from "axios";
import { app } from "@/app/index.js";

const baseURL = process.env.VUE_APP_API;
const http = axios.create({ baseURL });

http.interceptors.request.use((config) => {
  return config;
});

export const namespace = "authResetPassword";

export const module = {
  namespaced: true,
  state: {
    pending: false,
    email: "",
    token: "",
    password: "",
  },
  getters: {
    pending: (s) => s.pending,
    email: (s) => s.email,
    password: (s) => s.password,
    token: (s) => s.token,
  },
  mutations: {
    pending(state, value) {
      state.pending = value;
    },
    email(state, value) {
      state.email = value;
    },
    password(state, value) {
      state.password = value;
    },
    token(state, value) {
      state.token = value;
    },
  },
  actions: {
    resetPassword({ state, commit }) {
      return new Promise((resolve, reject) => {
        app.$store.commit("errors", {});
        if (!state.pending) {
          commit("pending", true);
          http
            .post("/reset-password-confirm", {
              email: state.email,
              password: state.password,
              token: state.token,
            })
            .then((response) => {
              resolve(response);
              commit("pending", false);
            })
            .catch((error) => {
              reject(error);
              commit("pending", false);
            });
        } else {
          commit("pending", false);
          resolve(true);
        }
      });
    },
  },
};
