import { generateModuleStore } from "@/shared/lib";
import { http } from "@/shared/api";

const namespace = "offers";

const module = generateModuleStore({
  namespace,
  filterName: "candidate_id",
  state: {
    aditionFilter: "",
  },
  actions: {
    sendMail(_, offerId) {
      return new Promise((resolve, reject) => {
        http
          .get(`/mails/candidate/${offerId}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logs(_, offerId) {
      return new Promise((resolve, reject) => {
        http
          .get(`/${namespace}/${offerId}/logs`)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});

export { namespace, module };
