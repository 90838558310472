<template>
  <section ref="select" tabindex="0" class="ui-select">
    <input ref="input" @focus="focusInput" class="ui-select__value" readonly />
    <div class="ui-select__icon">
      <chevron-icon />
    </div>
    <ul class="ui-select__options">
      <template v-if="options.length">
        <li
          v-for="(option, index) in options"
          :key="index"
          class="ui-select__option"
          :class="
            selectedIdList.includes(option.id)
              ? 'ui-select__option--active'
              : ''
          "
          @click="selected(option)"
        >
          <slot name="option" :option="option">{{ option }}</slot>
        </li>
      </template>
      <li v-else class="ui-select__option--notfound">Нет данных</li>
    </ul>
  </section>
</template>

<script>
import { ChevronIcon } from "@/shared/icons";
import { isEqual } from "lodash";

export default {
  name: "UiSelect",
  components: { ChevronIcon },
  props: {
    value: {
      type: [String, Array, Number],
      default: null,
    },
    multiselect: {
      type: Boolean,
      required: false,
    },
    maskValue: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    defaultValue: {
      type: String,
      default: "Не выбрано",
    },
  },
  data() {
    return {
      isSearch: true,
      isFocus: true,
      timerId: 0,
      valueInput: "",
      lastValueInput: "",
      selectedList: [],
      selectedIdList: [],
    };
  },
  mounted() {
    this.setInput(this.value);
  },
  watch: {
    value(value, oldValue) {
      if (!isEqual(value, oldValue)) {
        this.setInput(value);
      }
    },
    options() {
      this.setInput(this.value);
    },
  },
  methods: {
    setInput(value) {
      this.selectedIdList = [];
      this.selectedList = [];
      this.valueInput = "";
      this.$refs.input.value = this.valueInput;

      if (!value) return;

      if (typeof value !== "object") {
        let index = -1;
        this.options.forEach((option, i) => {
          if (option.id === value) {
            index = i;
          }
        });

        if (index + 1) {
          this.selectedIdList.push(this.options[index]["id"]);
          this.selectedList.push(this.options[index]);
          this.valueInput = this.applyMask(this.options[index]);
          this.$refs.input.value = this.valueInput;
          if (this.multiselect) {
            this.$emit("input", this.selectedIdList);
            this.$emit("selected", this.selectedList);
          } else {
            this.$emit("input", this.selectedIdList[0]);
            this.$emit("selected", this.selectedList[0]);
          }
        }
      } else if (value?.length) {
        const indices = this.options
          .map((option, index) => {
            if (value.includes(option.id)) {
              return index;
            }
            return null;
          })
          .filter((index) => index !== null);

        if (indices?.length) {
          const options = [];
          indices.forEach((index) => {
            this.selectedIdList.push(this.options[index]["id"]);
            this.selectedList.push(this.options[index]);
            options.push(this.options[index]);
          });
          this.valueInput = this.applyMask(options);
          this.$refs.input.value = this.valueInput;

          if (this.multiselect) {
            this.$emit("input", this.selectedIdList);
            this.$emit("selected", this.selectedList);
          } else {
            this.$emit("input", this.selectedIdList[0]);
            this.$emit("selected", this.selectedList[0]);
          }
        }
      }
    },
    applyMask(obj) {
      if (typeof obj === "object" && (obj?.length || obj.length === 0)) {
        let temp = "";
        obj.forEach((item, index) => {
          if (!/{*}/.test(this.maskValue)) {
            temp += item[this.maskValue];
          } else {
            temp +=
              this.maskValue.replace(/{(.*?)}/gm, function (value) {
                return item[value.replace(/{|}/g, "")];
              }) || "";
          }
          if (index + 1 !== obj.length) {
            temp += ", ";
          }
        });
        return temp;
      } else {
        if (!/{*}/.test(this.maskValue)) {
          return obj[this.maskValue] ? obj[this.maskValue] : "";
        } else {
          return (
            this.maskValue.replace(/{(.*?)}/gm, function (value) {
              return obj[value.replace(/{|}/g, "")];
            }) || ""
          );
        }
      }
    },
    blurSelect(event) {
      const list = [
        "ui-select__value",
        "ui-select__option",
        "ui-select__options",
        "ui-select__icon",
      ];
      let checkClassName = true;
      [...event.target.classList].forEach((className) => {
        if (list.includes(className)) {
          checkClassName = false;
        }
      });

      if (checkClassName) {
        this.$refs.select.classList.remove("ui-select--focus-within");
        document.body.removeEventListener("click", this.blurSelect);
      }
    },
    focusInput(event) {
      if (this.isFocus) {
        this.$refs.select.classList.add("ui-select--focus-within");
        event.preventDefault();
        document.body.addEventListener("click", this.blurSelect);
        this.lastValueInput = this.valueInput;
        this.$refs.input.value = this.valueInput;
      } else {
        this.isFocus = true;
      }
    },
    selected(option) {
      if (this.multiselect) {
        const index = this.selectedIdList.indexOf(option.id);
        if (index + 1) {
          this.selectedList.splice(index, 1);
          this.selectedIdList.splice(index, 1);
          this.valueInput = this.applyMask(this.selectedList);
        } else {
          this.selectedList.push(option);
          this.selectedIdList.push(option.id);
          this.valueInput = this.applyMask(this.selectedList);
        }
      } else {
        this.selectedList.splice(0, 1, option);
        this.selectedIdList.splice(0, 1, option.id);
        this.valueInput = this.applyMask(this.selectedList);
      }

      if (this.multiselect) {
        this.$emit("input", this.selectedIdList);
        this.$emit("selected", this.selectedList);
      } else {
        this.isFocus = false;
        this.$refs.select.classList.remove("ui-select--focus-within");
        this.isSearch = false;
        this.$refs.input.value = this.applyMask(this.selectedList[0]);
        this.$emit("input", this.selectedIdList[0]);
        this.$emit("selected", this.selectedList[0]);
        document.body.removeEventListener("click", this.blurSelect);
        this.$refs.input.blur();
      }
      this.$refs.input.value = this.valueInput;
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-select {
  position: relative;
  border: 1 / 7.1 + vh solid #e8e8e8;
  border-radius: 20 / 7.1 + vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  @include fontSize(10);
  @include height(33);
  @include padding(0, 18, 0, 18);
  &--focus-within {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #f55320;
    .ui-select__icon {
      color: #f55320;
      transform: rotate(180deg);
    }
    .ui-select__options {
      display: block;
      color: #f55320;
      height: max-content;
      max-height: 250 / 7.1 + vh;
    }
  }
  @media (max-width: 500px) {
    border: 1px solid #e8e8e8;
  }
  &__value {
    pointer-events: none;
    outline: none;
    border: none;
    width: 100%;
    @include padding(0, 10, 0, 0);
    @include fontSize(10);
  }
  &__option {
    color: #000;
    margin: 0;
    list-style-type: none;
    @include padding(10, 18, 10, 18);
    cursor: pointer;
    &:hover {
      background: #f0f0f0;
    }
    &--loading {
      @include padding(18, 0, 18, 0);
      color: #f55320;
      aside {
        @include height(20);
      }
    }
    &--notfound {
      @include padding(18, 0, 18, 0);
      text-align: center;
      color: #f55320;
    }
    &--active {
      background: #f55320;
      color: #fff;
      &:hover {
        background: #f55320;
      }
    }
  }
  &__options {
    display: none;
    position: absolute;
    top: 100%;
    left: -1px;
    overflow: auto;
    border: 1px solid red;
    background: #fff;
    z-index: 1;
    margin: 0;
    border-radius: 20 / 7.1 + vh;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: calc(100% + 2px);
    margin: 0;
    padding: 0;
    @include fontSize(10);
    height: max-content;
  }
  &__icon {
    transition: all 0.3s ease;
    color: #e8e8e8;
  }
}
</style>
