<template>
  <ui-table
    :loading="pending"
    :concating="concating"
    :columns="columns"
    :data="data"
  >
    <template #row="{ row, column }">
      <div v-if="column.field === 'candidate'">
        <div class="response-vacancy">{{ row.vacancy_name }}</div>
        <div class="response-candidate">{{ row.surname }} {{ row.name }}</div>
        <div class="response-date">
          {{ row.offer_date | convertDate }}
        </div>
      </div>
      <div v-else-if="column.field === 'comment'" class="response-comment">
        <div class="response-comment-wrapper">
          <div class="response-comment-value">{{ row.comment || "-" }}</div>
          <div class="response-comment-label">комментарий</div>
        </div>
        <div class="response-comment-wrapper" style="min-width: 95px">
          <div
            class="response-contact-again response-comment-value"
            :class="contactAgainClass(row.contact_again)"
          >
            <cross-icon
              v-if="row.contact_again == 0"
              class="response-comment-icon"
            />
            <check-icon
              v-if="row.contact_again == 1"
              class="response-comment-icon"
            />
            {{ formatContactAgain(row.contact_again) }}
          </div>
          <div v-if="!isGazprom" class="response-comment-label">
            связаться еще раз
          </div>
        </div>
      </div>
      <span v-else class="opacity">
        {{ row[column.field] }}
      </span>
    </template>
  </ui-table>
</template>

<script>
import { UiTable } from "@/shared/ui";
import { responseModel } from "@/entities/response";
import { convertDate } from "@/shared/lib";
import { viewerModel } from "@/entities/viewer";
import { CheckIcon, CrossIcon } from "@/shared/icons";

export default {
  name: "vacancy-list",
  filters: { convertDate },
  components: {
    CheckIcon,
    CrossIcon,
    UiTable,
  },
  data() {
    return {
      columns: [
        {
          label: "Кандидат",
          field: "candidate",
        },
        {
          label: "Комментарии",
          field: "comment",
        },
      ],
    };
  },
  computed: {
    currentCompany() {
      const company =
        this.$store.getters[viewerModel.namespace + "/currentCompany"];

      if (!company) return;

      return company;
    },
    data() {
      const data =
        this.$store.getters[
          responseModel.namespace + "/" + responseModel.namespace
        ];

      if (!data?.length) return [];

      return data;
    },
    isGazprom() {
      return this.currentCompany.id === 1003;
    },
    pending() {
      return this.$store.getters[responseModel.namespace + "/pending"];
    },
    concating() {
      return this.$store.getters[responseModel.namespace + "/concating"];
    },
  },
  methods: {
    contactAgainClass(value) {
      if (value == 0) return "response-contact-again--danger";
      if (value == 1) return "response-contact-again--success";
      return "";
    },
    formatContactAgain(value) {
      if (value == 0) return "Нет";
      if (value == 1) return "Да";
      return "-";
    },
  },
};
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0.5;
}
.response {
  &-candidate {
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  &-vacancy {
    color: #9999a1;
    @include padding(0, 0, 5, 0);
    @include fontSize(12);
    // font-weight: 600;
    // border-radius: 16 / 7.1 + vh;
    // color: #f55320;
    // background: rgba(245, 83, 32, 0.1);
    // @include padding(6, 12, 6, 12);
    // @include fontSize(12);
  }
  &-date {
    color: #9999a1;
    @include padding(10, 0, 0, 0);
    @include fontSize(12);
  }
  &-comment {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: #fafafa;
    border-radius: 12 / 7.1 + vh;
    padding: 16 / 7.1 + vh 24 / 7.1 + vh;
    &-wrapper {
      display: flex;
      flex-direction: column;
      @media (max-width: 500px) {
        flex-direction: column-reverse;
      }
    }
    &-label {
      color: #9999a1;
      @include padding(5, 0, 0, 0);
      @include fontSize(12);
      @media (max-width: 500px) {
        @include padding(0, 0, 5, 0);
      }
    }
    &-value {
      @include padding(0, 5, 0, 0);
    }
    &-icon {
      @include margin(0, 8, 0, 0);
    }
  }
  &-contact-again {
    display: flex;
    align-items: center;
    &--success {
      color: #14b011;
    }
    &--danger {
      color: #f55320;
    }
  }
}
</style>
