import { generateModuleStore } from "@/shared/lib";
import { http } from "@/shared/api";
import { app } from "@/app/index.js";

const namespace = "directories";

const module = generateModuleStore({
  namespace,
  filterName: "name",
  actions: {
    values({ state }, parameters) {
      return new Promise((resolve, reject) => {
        let isSelect = false;
        let id = null;
        let page = 1;
        let query = "";
        let filter = "name";
        let filterValue = "";
        let additionalFilter = "";

        if (parameters?.isSelect) {
          isSelect = parameters.isSelect;
        }
        if (parameters?.id) {
          id = parameters.id;
        }
        if (parameters?.page) {
          page = parameters.page;
        }
        if (parameters?.filter) {
          filter = parameters.filter;
        }
        if (parameters?.filterValue) {
          filterValue = parameters.filterValue;
        }
        if (parameters?.additionalFilter) {
          additionalFilter = "&" + parameters.additionalFilter;
        }
        query += `?page=${page}${state.aditionFilter}&filter[${filter}]=${filterValue}${additionalFilter}`;

        if (isSelect) {
          http
            .get(namespace + "/" + id + "/values" + query)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    create({ state, commit }, { companyId, data }) {
      app.$store.commit("errors", {});
      return new Promise((resolve, reject) => {
        if (!state.pending) {
          commit("pending", true);
          http
            .post(`/companies/${companyId}/directories`, data)
            .then((response) => {
              resolve(response);
              commit("pending", false);
            })
            .catch((error) => {
              reject(error);
              commit("pending", false);
            });
        } else {
          resolve(true);
        }
      });
    },
  },
});

export { namespace, module };
