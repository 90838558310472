<template>
  <ui-table
    :loading="pending"
    :columns="columns"
    :data="data"
    @rowClick="rowClick"
  >
    <template #row="{ row, column }">
      <strong v-if="column.field === 'name'">
        {{ row[column.field] | cutText(30) }}
      </strong>
      <strong
        v-else-if="column.field === 'company_access_level'"
        class="access-level"
      >
        {{ row[column.field] | convertRoleLevel }}
      </strong>
      <span v-else class="opacity">
        {{ row[column.field] | cutText(40) }}
      </span>
    </template>
  </ui-table>
</template>

<script>
import { UiTable } from "@/shared/ui";
import { companyEmployeesModel } from "@/entities/company-employees";
import { cutText, convertRoleLevel } from "@/shared/lib";

export default {
  name: "employees",
  components: { UiTable },
  filters: { convertRoleLevel, cutText },
  data() {
    return {
      columns: [
        {
          field: "name",
          label: "name",
        },
        {
          field: "email",
          label: "email",
        },
        {
          field: "company_access_level",
          label: "company_access_level",
          style: "text-align: right",
        },
      ],
    };
  },
  created() {
    const id = this.$route.params.id;
    this.$store.dispatch(companyEmployeesModel.namespace + "/get", {
      companyId: id,
    });
  },
  computed: {
    data() {
      return this.$store.getters[
        companyEmployeesModel.namespace + "/" + companyEmployeesModel.namespace
      ];
    },
    pending() {
      return this.$store.getters[companyEmployeesModel.namespace + "/pending"];
    },
  },
  methods: {
    rowClick({ row }) {
      if (row.company_access_level !== 100) {
        this.$router.push(
          `/company/${this.$route.params.id}/employee/${row.id}/update`
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0.5;
}
.access-level {
  background: #fceee9;
  border-radius: 20 / 7.1 + vh;
  color: #e57047;
  max-width: 130px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  @include padding(4, 15, 4, 15);
}
</style>
