<template>
  <input
    type="text"
    :value="value"
    @input="input"
    @keydown="keydown"
    @focus="focus"
    @blur="blur"
  />
</template>

<script>
export default {
  name: "InputMasked",
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholderChar: {
      type: String,
      default: "_",
    },
    mask: {
      required: true,
      validator: (value) => !!value && value.length >= 1,
    },
    isRaw: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lastKeyPressList: [],
    };
  },
  created() {
    if (this.value) {
      this.lastKeyPressList = this.value
        .match(
          this.mask
            .replace(/(\(|\))/g, function (match) {
              return "\\" + match;
            })
            .replace(/(\\1|1)/g, function (m) {
              if (m === "\\1") {
                return m;
              }
              return "([0-9])";
            })
            .replace("+", "\\+")
        )
        .splice(1);
    }
  },
  watch: {
    value(value) {
      if (value) {
        this.lastKeyPressList = this.value
          .match(
            this.mask
              .replace(/(\(|\))/g, function (match) {
                return "\\" + match;
              })
              .replace(/(\\1|1)/g, function (m) {
                if (m === "\\1") {
                  return m;
                }
                return "([0-9])";
              })
              .replace("+", "\\+")
          )
          .splice(1);
      }
    },
  },
  methods: {
    input(e) {
      e.preventDefault();
    },
    // keyCode
    // 27 - Escape
    // 8 - Backspace <-
    // 32 - Space
    // 46 - Delete ->
    keydown(e) {
      const input = e.target;
      if (e.keyCode === 8) {
        const vue = this;
        this.lastKeyPressList.splice(this.lastKeyPressList.length - 1);
        let index = 0;
        let xxx = this.mask.replace(/(\\1|1)/g, function (m) {
          if (m === "\\1") {
            index += 1;
          } else {
            if (index < vue.lastKeyPressList.length) {
              const temp = vue.lastKeyPressList[index];
              index += 1;
              return temp;
            } else {
              return vue.placeholderChar;
            }
          }
        });

        input.value = xxx;
        const lastIndex = input.value.lastIndexOf(
          this.lastKeyPressList[this.lastKeyPressList.length - 1]
        );
        setTimeout(() => {
          input.setSelectionRange(lastIndex + 1, lastIndex + 1, "none");
        }, 0);
        e.preventDefault();
      } else if (/[^0-9]/.test(e.key)) {
        e.preventDefault();
      } else if (input.value.indexOf(this.placeholderChar) + 1) {
        this.lastKeyPressList.splice(this.lastKeyPressList.length, 0, e.key);
        input.setSelectionRange(
          input.value.indexOf(this.placeholderChar),
          input.value.indexOf(this.placeholderChar) + 1,
          "none"
        );
      } else {
        e.preventDefault();
      }
    },
    focus(e) {
      const vue = this;
      const input = e.target;

      if (input.value.replace(/[^0-9+]/g, "")?.length < 12) {
        input.value = this.mask.replace(/(\\1|1)/g, function (m) {
          if (m === "\\1") {
            return m;
          } else {
            return vue.placeholderChar;
          }
        });

        setTimeout(() => {
          input.setSelectionRange(
            input.value.indexOf(this.placeholderChar),
            input.value.indexOf(this.placeholderChar),
            "none"
          );
        }, 0);
      }
    },
    blur(e) {
      const input = e.target;

      // если есть установленное значение то сохраняем
      if (input.value.replace(/[^0-9+]/g, "")?.length < 12 && this.value) {
        input.value = this.value;
        this.lastKeyPressList = this.value
          .match(
            this.mask
              .replace(/(\(|\))/g, function (match) {
                return "\\" + match;
              })
              .replace(/(\\1|1)/g, function (m) {
                if (m === "\\1") {
                  return m;
                }
                return "([0-9])";
              })
              .replace("+", "\\+")
          )
          .splice(1);
        return;
      }

      if (input.value.replace(/[^0-9+]/g, "")?.length === 12) {
        this.$emit("input", input.value);
        return;
      }

      if (input.value.replace(/[^0-9+]/g, "")?.length < 12) {
        input.value = "";
        this.lastKeyPressList = [];
      }
    },
  },
};
</script>
