<template>
  <button
    class="ui-button"
    :class="{
      'ui-button--disabled': disabled,
    }"
  >
    <div v-if="loadingLeft" class="ui-button--loading-left">
      <simple-loading />
    </div>
    <div v-else-if="!loadingLeft && hasIcon" class="ui-button__icon">
      <slot name="icon"></slot>
    </div>
    <slot></slot>
    <div v-if="loadingRight" class="ui-button--loading-right">
      <simple-loading />
    </div>
  </button>
</template>

<script>
import "./style.scss";
import SimpleLoading from "@/shared/ui/loading/simple";

export default {
  name: "UiButton",
  props: {
    loadingLeft: {
      type: Boolean,
      default: false,
    },
    loadingRight: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SimpleLoading,
  },
  computed: {
    hasIcon() {
      return !!this.$slots.icon;
    },
  },
};
</script>
