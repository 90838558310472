<template>
  <aside v-if="value?.length" class="error-message">
    {{ value[0] }}
  </aside>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  color: #ff0000;
  @include fontSize(10);
}
</style>
