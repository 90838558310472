<template>
  <div>
    <date-picker
      class="datetime-simple"
      v-model="datetime"
      prefix-class="simple"
      value-type="format"
      format="DD.MM.YYYY HH:mm:ss"
      title-format="DD.MM.YYYY"
      time-title-format="DD.MM.YYYY"
      :type="type"
      :placeholder="placeholder"
      :lang="lang"
      :editable="false"
    >
      <template v-slot:icon-calendar>
        <span></span>
      </template>
    </date-picker>
    <div v-if="error" class="datetime-simple__error">{{ error }}</div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "datetime-simple",
  components: {
    DatePicker,
  },
  props: {
    error: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Выберите дату",
    },
    value: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "date",
    },
  },
  data() {
    return {
      lang: {
        formatLocale: {
          months: [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь",
          ],
          monthsShort: [
            "Янв",
            "Фев",
            "Мар",
            "Апр",
            "Май",
            "Июн",
            "Июл",
            "Авг",
            "Сен",
            "Окт",
            "Ноя",
            "Дек",
          ],
          weekdays: [
            "Воскресенье",
            "Понедельник",
            "Вторник",
            "Среда",
            "Четверг",
            "Пятница",
            "Суббота",
          ],
          weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
          weekdaysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
          firstDayOfWeek: 1,
          firstWeekContainsDate: 1,
        },
        yearFormat: "YYYY ",
        monthFormat: "MMM",
        monthBeforeYear: false,
      },
    };
  },
  computed: {
    datetime: {
      get() {
        if (this.value && typeof this.value === "string") {
          if (/^\d\d\d\d-\d\d-\d\d \d\d:\d\d:\d\d$/.test(this.value)) {
            const dateTime = this.value.split(" ");
            const dateList = dateTime[0].split("-");
            return `${dateList[2]}.${dateList[1]}.${dateList[0]} ${dateTime[1]}`;
          } else {
            return this.value;
          }
        } else {
          return null;
        }
      },
      set(value) {
        const dateTime = value.split(" ");
        const dateList = dateTime[0].split(".");
        this.$emit(
          "input",
          `${dateList[2]}-${dateList[1]}-${dateList[0]} ${dateTime[1]}`
        );
      },
    },
  },
};
</script>

<style lang="scss">
$namespace: "simple";
$default-color: #444855;
$primary-color: #f55320;

$today-color: mix(#fff, $primary-color, 10%);

$popup-z-index: 2001;

$input-border-color: #e8e8e8;
$input-color: #000;
$input-hover-border-color: #e8e8e8;

$disabled-color: #c8c8c8;
$disabled-background-color: #f3f3f3;

$border-color: #e8e8e8;

$calendar-active-color: #fff;
$calendar-active-background-color: $primary-color;

$calendar-hover-color: $default-color;
$calendar-hover-background-color: mix(
  #fff,
  $calendar-active-background-color,
  95%
);

$calendar-in-range-color: $default-color;
$calendar-in-range-background-color: mix(
  #fff,
  $calendar-active-background-color,
  85%
);

$time-active-color: $primary-color;
$time-active-background-color: transparent;

$time-hover-color: $default-color;
$time-hover-background-color: mix(#fff, $calendar-active-background-color, 95%);

$input-border-radius: 15 / 7.1 + vh;
$sidebar-margin-left: 100px;

@import "~vue2-datepicker/scss/index.scss";
.datetime-simple {
  width: 100%;
  &__error {
    color: #ff0000;
    @include fontSize(10);
    @include margin(2, 0, 0, 0);
  }
  .simple-input-wrapper {
    width: 100%;
  }
  .simple-input {
    box-shadow: none;
    font-family: "gilroy-regular", "Helvetica", "Arial", "sans-serif";
    @include height(33);
    @include fontSize(10);
    @include padding(0, 35, 0, 18);
    &:focus {
      border-color: #f55320;
    }
    &::-webkit-input-placeholder {
      color: #c8c8c8;
    }
    &::-moz-placeholder {
      color: #c8c8c8;
    }
    &:-ms-input-placeholder {
      color: #c8c8c8;
    }
    &:-moz-placeholder {
      color: #c8c8c8;
    }
  }
}
</style>
