<template>
  <ui-table
    :loading="pending"
    :concating="concating"
    :columns="columns"
    :data="data"
    @rowClick="rowClick"
  >
    <template #row="{ row, column }">
      <strong v-if="column.field === 'caption'">
        {{ row[column.field] }}
      </strong>
      <div v-else-if="column.field === 'directory'" class="directory">
        <div>
          <strong>{{ row.name }}</strong>
          <div class="directory__label">алиас</div>
        </div>
        <div class="directory__setup">
          <div class="directory__read">
            <div v-if="row.read_only" class="directory__value--success">
              <check-icon />Да
            </div>
            <div v-else class="directory__value--danger"><cross-icon />Нет</div>
            <div class="directory__label">только для чтения</div>
          </div>
          <div class="directory__custom">
            <div v-if="row.allow_custom" class="directory__value--success">
              <check-icon />Да
            </div>
            <div v-else class="directory__value--danger"><cross-icon />Нет</div>
            <div class="directory__label">пользовательское заполнение</div>
          </div>
        </div>
      </div>
      <span v-else class="opacity">
        {{ row[column.field] }}
      </span>
    </template>
  </ui-table>
</template>

<script>
import { UiTable } from "@/shared/ui";
import { directoryModel } from "@/entities/directory";
import { CheckIcon, CrossIcon } from "@/shared/icons";

export default {
  name: "entity-directory-list",
  components: { UiTable, CheckIcon, CrossIcon },
  data() {
    return {
      columns: [
        {
          field: "caption",
          label: "caption",
        },
        {
          field: "directory",
          label: "directory",
        },
      ],
    };
  },
  created() {
    this.$store.dispatch(directoryModel.namespace + "/get");
  },
  computed: {
    data() {
      return this.$store.getters[directoryModel.namespace + "/directories"];
    },
    pending() {
      return this.$store.getters[directoryModel.namespace + "/pending"];
    },
    concating() {
      return this.$store.getters[directoryModel.namespace + "/concating"];
    },
  },
  methods: {
    rowClick({ row }) {
      this.$router.push(`/template/directory/${row.id}/update`);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  background: #fafafa;
  height: 100%;
  @include padding(20, 50, 0, 50);
  overflow: auto;
}
.opacity {
  opacity: 0.5;
}
.directory {
  display: flex;
  justify-content: space-between;
  background: #fafafa;
  border-radius: 12 / 7.1 + vh;
  padding: 16 / 7.1 + vh 24 / 7.1 + vh;
  &__label {
    @include fontSize(12);
    @include margin(10, 0, 0, 0);
  }
  &__value {
    &--success {
      color: #14b011;
      display: flex;
      align-items: center;
      @include width(101);
      svg {
        width: auto;
        @include margin(0, 5, 0, 0);
        @include height(11);
      }
    }
    &--danger {
      color: #f55320;
      display: flex;
      align-items: center;
      @include width(101);
      svg {
        width: auto;
        @include margin(0, 5, 0, 0);
        @include height(11);
      }
    }
  }
  &__read {
    @include width(119);
  }
  &__custom {
    @include width(195);
  }
  &__setup {
    display: flex;
    justify-content: space-between;
    @include width(334);
  }
}
</style>
