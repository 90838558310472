import Vue from "vue";
import VueRouter from "vue-router";
import {
  LayoutSetting,
  LayoutSimple,
  LayoutVacancy,
  LayoutTemplate,
} from "@/pages/layout";
import Cookies from "js-cookie";
import { checkAccess, checkRole } from "@/shared/lib";
import { store } from "./store.js";
import { viewerModel } from "@/entities/viewer";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: { name: "vacancy-list" },
  },
  {
    path: "/sign-in",
    name: "sign-in",
    meta: {
      title: "Авторизация",
    },
    component: () => import("@/pages/auth/sign-in"),
  },
  {
    path: "/restore-password",
    name: "restore-password",
    meta: {
      title: "Восстановление пароля",
    },
    component: () => import("@/pages/auth/restore-password"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: {
      title: "Сброс пароля",
    },
    component: () => import("@/pages/auth/reset-password"),
  },
  {
    path: "/company/invite",
    name: "company-invite",
    component: () => import("@/pages/company/invite"),
    meta: {
      title: "Приглашение в компанию",
    },
    beforeEnter: checkInvite,
  },
  {
    path: "/",
    component: LayoutVacancy,
    redirect: { name: "vacancy-list" },
    children: [
      {
        path: "/vacancy/list",
        name: "vacancy-list",
        component: () => import("@/pages/vacancy/list"),
        meta: {
          title: "Список вакансий",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/response/list",
        name: "response-list",
        meta: {
          title: "Список ответов",
        },
        component: () => import("@/pages/response/list"),
      },
      {
        path: "/offer/list",
        name: "offer-list",
        component: () => import("@/pages/offer/list"),
        meta: {
          title: "Список офферов",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/candidate/list",
        name: "candidate-list",
        component: () => import("@/pages/candidate/list"),
        meta: {
          title: "Список кандидатов",
        },
        beforeEnter: beforeCode,
      },
    ],
  },
  {
    path: "/",
    component: LayoutSimple,
    redirect: { name: "setting_companies" },
    children: [
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/pages/profile/simple"),
        meta: {
          title: "Профиль",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/template/field/create",
        name: "template-field-create",
        component: () => import("@/pages/field/create"),
        meta: {
          title: "Добавление поля",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/template/field/:id/update",
        name: "template-field-update",
        component: () => import("@/pages/field/update"),
        meta: {
          title: "Редактирование поля",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/template/directory/create",
        name: "template-directory-create",
        component: () => import("@/pages/directory/create"),
        meta: {
          title: "Создание справочника",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/template/directory/:id/update",
        name: "template-directory-update",
        component: () => import("@/pages/directory/update"),
        meta: {
          title: "Редактирование справочника",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/template/vacancy/create",
        name: "template-vacancy-create",
        component: () => import("@/pages/vacancy-template/create"),
        meta: {
          title: "Добавление шаблона вакансии",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/template/vacancy/:id/update",
        name: "template-vacancy-update",
        component: () => import("@/pages/vacancy-template/update"),
        meta: {
          title: "Редактирование шаблона вакансии",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/company/create",
        name: "company-create",
        component: () => import("@/pages/company/create"),
        meta: {
          title: "Новая компания",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/company/:id/update",
        name: "company-update",
        component: () => import("@/pages/company/update"),
        meta: {
          title: "Редактирование компании",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/company/:id/employee/list",
        name: "company-employee-list",
        component: () => import("@/pages/company-employee/list"),
        meta: {
          title: "Сотрудники компании",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/company/:id/employee/create",
        name: "company-employee-create",
        component: () => import("@/pages/company-employee/create"),
        meta: {
          title: "Добавление сотрудника",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/company/:id/employee/:employeeId/update",
        name: "company-employee-update",
        component: () => import("@/pages/company-employee/update"),
        meta: {
          title: "Редактирование сотрудника",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/manager/create",
        name: "manager-create",
        component: () => import("@/pages/manager/create"),
        meta: {
          title: "Добавление менеджера",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/manager/:id/update",
        name: "manager-update",
        component: () => import("@/pages/manager/update"),
        meta: {
          title: "Редактирование менеджера",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/user/create",
        name: "user-create",
        component: () => import("@/pages/user/create"),
        meta: {
          title: "Добавление пользователя",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/user/:id/update",
        name: "user-update",
        component: () => import("@/pages/user/update"),
        meta: {
          title: "Редактирование пользователя",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/candidate/create",
        name: "candidate-create",
        component: () => import("@/pages/candidate/create"),
        meta: {
          title: "Добавление кандидата",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/candidate/:id/update",
        name: "candidate-update",
        component: () => import("@/pages/candidate/update"),
        meta: {
          title: "Редактирование кандидата",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/vacancy/create",
        name: "vacancy-create",
        component: () => import("@/pages/vacancy/create"),
        meta: {
          title: "Добавление вакансии",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/vacancy/:id/update",
        name: "vacancy-update",
        component: () => import("@/pages/vacancy/update"),
        meta: {
          title: "Редактирование вакансии",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/offer/create",
        name: "offer-create",
        component: () => import("@/pages/offer/create"),
        meta: {
          title: "Добавление оффера",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/offer/:id",
        name: "offer-details",
        component: () => import("@/pages/offer/details"),
        meta: {
          title: "Просмотр оффера",
        },
      },
      {
        path: "/offer/:id/update",
        name: "offer-update",
        component: () => import("@/pages/offer/update"),
        meta: {
          title: "Редактирование оффера",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/candidate/import",
        name: "candidate-import",
        component: () => import("@/pages/candidate/import"),
        meta: {
          title: "Импортирование кандидатов",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/not-access",
        name: "not-access",
        component: () => import("@/pages/access/not-access"),
        meta: {
          title: "Доступ ограничен",
        },
        beforeEnter: beforeNotAccess,
      },
    ],
  },
  {
    path: "/template",
    component: LayoutTemplate,
    redirect: { name: "template-directory-list" },
    children: [
      {
        path: "/template/directory/list",
        name: "template-directory-list",
        component: () => import("@/pages/directory/list"),
        meta: {
          title: "Список шаблонов справочников",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/template/field/list",
        name: "template-field-list",
        component: () => import("@/pages/field/list"),
        meta: {
          title: "Список шаблонов полей",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/template/vacancy/list",
        name: "template-vacancy-list",
        component: () => import("@/pages/vacancy-template/list"),
        meta: {
          title: "Список шаблонов вакансий",
        },
        beforeEnter: beforeCode,
      },
    ],
  },
  {
    path: "/settings",
    component: LayoutSetting,
    redirect: { name: "company-list" },
    children: [
      {
        path: "/company/list",
        name: "company-list",
        component: () => import("@/pages/company/list"),
        meta: {
          title: "Список компаний",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/user/list",
        name: "user-list",
        component: () => import("@/pages/user/list"),
        meta: {
          title: "Список пользователей",
        },
        beforeEnter: beforeCode,
      },
      {
        path: "/manager/list",
        name: "manager-list",
        component: () => import("@/pages/manager/list"),
        meta: {
          title: "Список менеджер",
        },
        beforeEnter: beforeCode,
      },
    ],
  },
];

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  chanteTitlePage(to.meta.title);
});

function checkRouteAccess(to, form, next) {
  const user = store.getters[viewerModel.namespace + "/current"];
  const accessLevel = store.getters[viewerModel.namespace + "/accessLevel"];
  const roleLevel = store.getters[viewerModel.namespace + "/roleLevel"];
  const companiesCount = user?.companies?.length;

  if (Number(accessLevel) >= 90) {
    next();
  } else if (companiesCount > 0) {
    if (
      checkAccess(to.name, Number(accessLevel)) ||
      checkRole(to.name, Number(roleLevel))
    ) {
      next();
    } else {
      next({ name: "vacancy-list" });
    }
  } else {
    next({ name: "not-access" });
  }
}

function beforeCode(to, from, next) {
  store.commit("errors", {});
  const user = store.getters[viewerModel.namespace + "/current"];

  if (!user) {
    store
      .dispatch(viewerModel.namespace + "/current")
      .then(() => {
        checkRouteAccess(to, from, next);
      })
      .catch(() => {
        next({ name: "not-access" });
      });
  } else {
    checkRouteAccess(to, from, next);
  }
}

function chanteTitlePage(title) {
  document.title = title;
}

function beforeNotAccess(to, from, next) {
  const companiesCount = Cookies.get("ft_companies_length");
  const accessLevel = Cookies.get("ft_access_level");

  if (Number(accessLevel) >= 90) {
    next({ name: "vacancy-list" });
  } else if (companiesCount === undefined) {
    Cookies.remove("token");
    next({ name: "sign-in" });
  } else if (companiesCount > 0) {
    next({ name: "vacancy-list" });
  } else {
    next();
  }
}

function checkInvite(to, from, next) {
  if (to.query?.token) {
    next();
  } else {
    next({ name: "vacancy-list" });
  }
}
