<template>
  <section v-if="!isUpdate || isEdit" class="directory-value__create">
    <label>
      <span>Название</span>
      <ui-input v-model="caption" />
    </label>
    <label>
      <span>Алиас</span>
      <ui-input v-model="alias" />
    </label>
    <label>
      <span>Доп. информация</span>
      <ui-input v-model="additionalInfo" />
    </label>
    <button-border @click.native="save">{{
      isUpdate ? "Сохранить" : "Добавить значение"
    }}</button-border>
  </section>
  <section v-else class="directory-value__create">
    <label
      >Название: <strong>{{ value.caption }}</strong></label
    >
    <label
      >Алиас: <strong>{{ value.alias }}</strong></label
    >
    <label v-if="value.additional_info"
      >Доп. информация: <strong>{{ value.additional_info }}</strong></label
    >
    <section class="box">
      <button-border class="button" @click.native="remove"
        >Удалить</button-border
      >
      <button-border class="button" @click.native="update"
        >Редактировать</button-border
      >
    </section>
  </section>
</template>

<script>
import { directoryValuesModel } from "@/entities/directory-value";
import { UiInput, ButtonBorder } from "@/shared/ui";

export default {
  name: "entity-directory-list",
  components: { UiInput, ButtonBorder },
  props: {
    value: {
      type: Object,
      default: null,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEdit: false,
    };
  },
  computed: {
    caption: {
      get() {
        if (this.value?.caption) {
          return this.value.caption;
        } else {
          return "";
        }
      },
      set(value) {
        const obj = {
          id: this.value.id,
          caption: this.value.caption,
          alias: this.value.alias,
          additional_info: this.value.additional_info,
        };
        if (obj) {
          obj["caption"] = value;
          this.$emit("input", obj);
        } else {
          this.$emit("input", {
            caption: value,
          });
        }
      },
    },
    additionalInfo: {
      get() {
        if (this.value?.additional_info) {
          return this.value.additional_info;
        } else {
          return "";
        }
      },
      set(value) {
        const obj = {
          id: this.value.id,
          caption: this.value.caption,
          alias: this.value.alias,
          additional_info: this.value.additional_info,
        };

        if (obj) {
          obj["additional_info"] = value;
          this.$emit("input", obj);
        } else {
          this.$emit("input", {
            additional_info: value,
          });
        }
      },
    },
    alias: {
      get() {
        if (this.value?.alias) {
          return this.value.alias;
        } else {
          return "";
        }
      },
      set(value) {
        const obj = {
          id: this.value.id,
          caption: this.value.caption,
          alias: this.value.alias,
          additional_info: this.value.additional_info,
        };
        if (obj) {
          obj["alias"] = value;
          this.$emit("input", obj);
        } else {
          this.$emit("input", {
            alias: value,
          });
        }
      },
    },
    pending() {
      return this.$store.getters[directoryValuesModel.namespace + "/pending"];
    },
  },
  methods: {
    save() {
      if (this.value?.caption?.length > 1 && this.value?.alias?.length > 1) {
        if (this.isUpdate) {
          this.isEdit = false;
          this.$emit("update", this.value);
        } else {
          this.$emit("add", this.value);
        }
      }
    },
    update() {
      if (!this.isEdit && this.isUpdate) {
        this.isEdit = true;
      }
    },
    remove() {
      this.$emit("delete");
    },
  },
};
</script>

<style lang="scss" scoped>
.directory-value__create {
  background: #fff;
  border-radius: 20 / 7.1 + vh;
  border: 1px solid #e8e8e8;
  width: 100%;
  @include padding(20, 20, 20, 20);
  @include margin(20, 0, 0, 0);
  @media (max-width: 440px) {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
.directory-value__list {
  background: #fff;
  border-radius: 20 / 7.1 + vh;
  border: 1px solid #e8e8e8;
  width: 100%;
  @include padding(20, 20, 20, 20);
  @include margin(0, 0, 20, 0);
  @media (max-width: 440px) {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
label {
  display: block;
  @include fontSize(12);
  @include margin(0, 0, 15, 0);
  span {
    display: block;
    @include margin(0, 0, 5, 0);
  }
}
.box {
  display: flex;
  @include margin(0, 0, 0, -20);
  .button {
    @include margin(0, 0, 0, 20);
  }
}
</style>
