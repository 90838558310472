<template>
  <ui-table
    :loading="pending"
    :concating="concating"
    :columns="columns"
    :data="data"
  >
    <template #row="{ row, column }">
      <div v-if="column.field === 'vacancy'">
        <div>
          <strong>
            {{ row.name }}
          </strong>
        </div>
      </div>
      <span
        v-else-if="column.field === 'candidates'"
        class="offer-link"
        @click="viewOffer(row.id)"
      >
        {{ transformCandidate(row.candidate_count) }}
      </span>
      <div
        v-else-if="column.field === 'active'"
        style="display: flex; justify-content: flex-end"
      >
        <div v-if="row[column.field]" class="vacancy-status--active">
          <check-icon />Активна
        </div>
        <div v-else class="vacancy-status--no-active">
          <cross-icon />Неактивна
        </div>
      </div>
      <section v-else-if="column.field === 'actions'" class="vacancy-actions">
        <button
          @click="toPageUpdate(row)"
          class="button-circle"
          title="Редактировать"
        >
          <pen-icon />
        </button>
        <button
          v-if="row.active"
          @click="toArchiveIn(row)"
          class="button-circle"
          title="Архивировать"
        >
          <archive-in-icon />
        </button>
        <button
          v-else
          @click="toArchiveOut(row)"
          class="button-circle"
          title="Разархивировать"
        >
          <archive-out-icon />
        </button>
      </section>
      <span v-else class="opacity">
        {{ row[column.field] }}
      </span>
    </template>
  </ui-table>
</template>

<script>
import { viewerModel } from "@/entities/viewer";
import "./styles.scss";
import { UiTable } from "@/shared/ui";
import {
  CheckIcon,
  CrossIcon,
  PenIcon,
  ArchiveInIcon,
  ArchiveOutIcon,
} from "@/shared/icons";
import { convertDate } from "@/shared/lib";
import { vacancyModel } from "@/entities/vacancy";
import { notificationModel } from "@/entities/notification";
import { ModalArchiveIn, ModalArchiveOut } from "@/features/vacancy-modal";

export default {
  name: "vacancy-list",
  components: {
    UiTable,
    CheckIcon,
    CrossIcon,
    PenIcon,
    ArchiveInIcon,
    ArchiveOutIcon,
  },
  filters: { convertDate },
  props: {
    vacancyStatus: {
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      columns: [
        {
          label: "vacancy",
          field: "vacancy",
        },
        {
          label: "candidates",
          field: "candidates",
        },
        {
          label: "active",
          field: "active",
        },
        {
          label: "actions",
          field: "actions",
        },
      ],
    };
  },
  created() {
    if (this.vacancyStatus) {
      const company = this.getCompanyId(this.currentCompany);

      this.$store.dispatch(vacancyModel.namespace + "/get", {
        additionalFilter: `filter[active]=${
          this.vacancyStatus === "all" ? "" : this.vacancyStatus
        }&filter[company_id]=${company}`,
      });
    }
  },
  watch: {
    vacancyStatus(value) {
      const company = this.getCompanyId(this.currentCompany);
      this.$store.dispatch(vacancyModel.namespace + "/get", {
        additionalFilter: `filter[active]=${
          value === "all" ? "" : value
        }&filter[company_id]=${company}`,
      });
    },
    currentCompany(value) {
      const company = this.getCompanyId(value);
      this.$store.dispatch(vacancyModel.namespace + "/get", {
        additionalFilter: `filter[active]=${
          this.vacancyStatus === "all" ? "" : this.vacancyStatus
        }&filter[company_id]=${company}`,
      });
    },
  },
  computed: {
    currentCompany() {
      const company =
        this.$store.getters[viewerModel.namespace + "/currentCompany"];

      if (!company) return;

      return company;
    },
    data() {
      if (
        this.$store.getters[
          vacancyModel.namespace + "/" + vacancyModel.namespace
        ]?.length
      ) {
        return this.$store.getters[
          vacancyModel.namespace + "/" + vacancyModel.namespace
        ];
      } else {
        return [];
      }
    },
    pending() {
      return this.$store.getters[vacancyModel.namespace + "/pending"];
    },
    concating() {
      return this.$store.getters[vacancyModel.namespace + "/concating"];
    },
  },
  methods: {
    getCompanyId(company) {
      if (!company) return "";
      if (!company?.id) return "";
      if (company.id === "all") return "";

      return company.id;
    },
    viewOffer(vacancyId) {
      this.$router.push({
        name: "offer-list",
        query: {
          vacancy: vacancyId,
        },
      });
    },
    toPageUpdate(row) {
      this.$router.push(`/vacancy/${row.id}/update`);
    },
    toArchiveIn(row) {
      window.vacancy = {
        name: row.name,
        id: row.id,
      };
      this.$store.commit(
        notificationModel.namespace + "/" + notificationModel.namespace,
        {
          display: true,
          label: "Уведомление",
          notClose: true,
          content: ModalArchiveIn,
        }
      );
    },
    toArchiveOut(row) {
      window.vacancy = {
        name: row.name,
        id: row.id,
      };
      this.$store.commit(
        notificationModel.namespace + "/" + notificationModel.namespace,
        {
          display: true,
          label: "Уведомление",
          notClose: true,
          content: ModalArchiveOut,
        }
      );
    },
    transformCandidate(countCandidate) {
      let count = countCandidate;
      if (count === null || count === undefined) {
        count = 0;
      }

      let n = Math.abs(count);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return count + " кандидатов";
      }
      n %= 10;
      if (n === 1) {
        return count + " кандидат";
      }
      if (n >= 2 && n <= 4) {
        return count + " кандидата";
      }
      return count + " кандидатов";
    },
  },
};
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0.5;
}
.offer-link {
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
}
.vacancy {
  &-status {
    &--active {
      color: #14b011;
      display: flex;
      align-items: center;
      @include width(101);
      svg {
        width: auto;
        @include margin(0, 5, 0, 0);
        @include height(11);
      }
    }
    &--no-active {
      color: #f55320;
      display: flex;
      align-items: center;
      @include width(101);
      svg {
        width: auto;
        @include margin(0, 5, 0, 0);
        @include height(11);
      }
    }
  }
  &-label {
    opacity: 0.5;
    @include fontSize(12);
    @include margin(10, 0, 0, 0);
  }
}
.button-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f55320;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  @include height(30);
  @include width(30);
  &:hover {
    transform: scale(1.06);
  }
  &:active {
    transform: scale(0.96);
  }
  svg {
    @include height(18);
    @include width(18);
  }
}
.vacancy-actions {
  display: flex;
  justify-content: flex-end;
  @include margin(0, 0, 0, -10);
  button {
    @include margin(0, 0, 0, 10);
  }
}
</style>
