<template>
  <ui-table
    :loading="pending"
    :concating="concating"
    :columns="columns"
    :data="data"
    @rowClick="rowClick"
  >
    <template #row="{ row, column }">
      <strong v-if="column.field === 'surname'">
        {{ row[column.field] }}
      </strong>
      <div
        v-else-if="column.field === 'actions'"
        style="display: flex; justify-content: flex-end"
      >
        <ui-button
          @click.native="createOffer(row.id)"
          style="width: max-content"
          >Создать оффер
          <template #icon><plus-icon /></template>
        </ui-button>
      </div>
      <span v-else class="opacity">
        {{ row[column.field] }}
      </span>
    </template>
  </ui-table>
</template>

<script>
import { UiTable, UiButton } from "@/shared/ui";
import { PlusIcon } from "@/shared/icons";
import { candidateModel } from "@/entities/candidate";
import { viewerModel } from "@/entities/viewer";

export default {
  name: "candidate-list",
  components: { UiTable, UiButton, PlusIcon },
  data() {
    return {
      columns: [
        {
          label: "surname",
          field: "surname",
        },
        {
          label: "name",
          field: "name",
        },
        {
          label: "patronymic",
          field: "patronymic",
        },
        {
          label: "phone",
          field: "phone",
        },
        {
          label: "email",
          field: "email",
        },
        {
          label: "actions",
          field: "actions",
        },
      ],
      roleLevel: 1,
      viewer: null,
      accessLevel: 1,
    };
  },
  created() {
    this.roleLevel = this.$store.getters[viewerModel.namespace + "/roleLevel"];
    this.accessLevel =
      this.$store.getters[viewerModel.namespace + "/accessLevel"];
    this.viewer = this.$store.getters[viewerModel.namespace + "/current"];

    if (this.accessLevel >= 90 || this.roleLevel > 50) {
      this.$store.dispatch(candidateModel.namespace + "/get");
    } else {
      this.$store.dispatch(candidateModel.namespace + "/get", {
        additionalFilter: `filter[responsible_id]=${this.viewer.id}`,
      });
    }
  },
  computed: {
    data() {
      if (
        this.$store.getters[
          candidateModel.namespace + "/" + candidateModel.namespace
        ]?.length
      ) {
        return this.$store.getters[
          candidateModel.namespace + "/" + candidateModel.namespace
        ];
      } else {
        return [];
      }
    },
    pending() {
      return this.$store.getters[candidateModel.namespace + "/pending"];
    },
    concating() {
      return this.$store.getters[candidateModel.namespace + "/concating"];
    },
  },
  methods: {
    createOffer(candidateId) {
      this.$router.push({
        name: "offer-create",
        query: {
          candidate: candidateId,
        },
      });
    },
    rowClick({ row, column }) {
      if (column.field !== "actions") {
        this.$router.push(`/candidate/${row.id}/update`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0.5;
}
</style>
