<template>
  <div class="ui-input__wrapper">
    <div
      class="ui-input__box"
      :class="{
        'ui-input--danger': error,
      }"
    >
      <div v-if="hasIcon" class="ui-input__icon">
        <slot name="icon"></slot>
      </div>
      <input-masked
        v-if="type === 'phone'"
        :value="value"
        @input="(value) => $emit('input', value)"
        class="ui-input__phone"
        mask="+7 (911) 111-11-11"
        :placeholder="placeholder"
      />
      <input
        v-else-if="type !== 'number'"
        :value="value"
        @input="(event) => $emit('input', event.target.value)"
        class="ui-input"
        :name="name ? name : 'input-' + _uid"
        :type="type"
        :placeholder="placeholder"
      />
      <input
        v-else
        v-model="modelNumber"
        :type="indicatorChange ? 'number' : 'text'"
        @focus="indicatorChange = true"
        @blur="indicatorChange = false"
        class="ui-input"
        :name="name ? name : 'input-' + _uid"
        :placeholder="placeholder"
      />
    </div>
    <div v-if="error" class="ui-input__error">{{ error }}</div>
  </div>
</template>

<script>
import InputMasked from "./masked.vue";
import "./style.scss";

export default {
  name: "UiInput",
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
  },
  components: {
    InputMasked,
  },
  data() {
    return {
      flagPhone: true,
      realNumber: 0,
      indicatorChange: false,
    };
  },
  created() {
    if (this.type === "number") {
      this.realNumber = +this.value;
    }
  },
  computed: {
    hasIcon() {
      return !!this.$slots.icon;
    },
    modelNumber: {
      get() {
        return this.indicatorChange
          ? this.realNumber
          : this.realNumber.toLocaleString();
      },
      set(value) {
        this.realNumber = +value.replace(/\s/g, "");
        this.$emit("input", this.realNumber);
      },
    },
  },
};
</script>
