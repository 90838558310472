<template>
  <section v-if="onlyCreate && !fieldList?.length"></section>
  <section
    v-else-if="!isUpdate || isEdit"
    class="field__create"
    :data-length="fieldList?.length"
  >
    <label :style="fieldList?.length ? '' : 'pointer-events: none'">
      <span>Поле</span>
      <select-search-new
        v-model="field"
        mask-value="{caption}"
        identificator="id"
        :options="fieldList"
        :loading="fieldLoading"
        @search="fieldSearch"
        @blur="fieldBlur"
        @scrollend="fieldScrollEnd"
      />
      <aside v-if="error" class="error-simple">{{ error }}</aside>
    </label>
    <button-border :disabled="disable" @click.native="save">{{
      isUpdate ? "Сохранить" : "Добавить поле"
    }}</button-border>
  </section>
  <section v-else class="field__create" :data-length="fieldList?.length">
    <label
      >Поле: <strong>{{ value.caption }}</strong></label
    >
    <section class="box">
      <button-border class="button" @click.native="remove"
        >Удалить</button-border
      >
      <button-border v-if="!notUpdate" class="button" @click.native="update"
        >Редактировать</button-border
      >
    </section>
  </section>
</template>

<script>
import { SelectSearchNew, ButtonBorder } from "@/shared/ui";
import { fieldModel } from "@/entities/field";

export default {
  name: "vacancy-template-field",
  components: {
    SelectSearchNew,
    ButtonBorder,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    notUpdate: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: Number,
      required: true,
    },
    useFields: {
      type: [Array, Object],
      default: () => [],
    },
    onlyCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEdit: false,
      fieldLoading: false,
      fieldList: [],
      meta: null,
      concating: false,
    };
  },
  watch: {
    useFields(value) {
      if (this.onlyCreate) {
        this.$emit("update", null);
        this.fieldLoading = true;
        if (!this.isUpdate || this.isEdit) {
          this.$store
            .dispatch(fieldModel.namespace + "/get", {
              additionalFilter: `filter[company_id]=${this.companyId}`,
            })
            .then((response) => {
              if (response?.data && response?.meta) {
                const useFieldIdList = value.map((v) => v.id);
                this.fieldList = response.data.filter((field) => {
                  if (!useFieldIdList.includes(field.id)) {
                    return true;
                  } else {
                    return false;
                  }
                });

                this.meta = response.meta;
              }
            })
            .finally(() => {
              this.fieldLoading = false;
            });
        }
      }
    },
    companyId(value) {
      if (value) {
        this.fieldLoading = true;
        this.$store
          .dispatch(fieldModel.namespace + "/get", {
            additionalFilter: `filter[company_id]=${value}`,
          })
          .then((response) => {
            if (!this.onlyCreate) {
              const useFieldIdList = this.useFields.map((v) => v.id);
              this.fieldList = response.data.filter((field) => {
                if (
                  !useFieldIdList.includes(field.id) ||
                  (this.value && field.id === this.value.id)
                ) {
                  return true;
                } else {
                  return false;
                }
              });
            } else {
              const useFieldIdList = this.useFields.map((v) => v.id);
              this.fieldList = response.data.filter((field) => {
                if (!useFieldIdList.includes(field.id)) {
                  return true;
                } else {
                  return false;
                }
              });
            }
            this.meta = response.meta;
            this.fieldLoading = false;
          });
      }
    },
  },
  computed: {
    disable() {
      if (!this.field) {
        return true;
      }
      return false;
    },
    field: {
      get() {
        return this.value;
      },
      set(value) {
        if (value?.id !== this.value?.id) {
          this.$emit("update", value);
          this.$emit("changeMode", "view");
        }
      },
    },
  },
  created() {
    this.fieldLoading = true;
    if (!this.isUpdate || this.isEdit) {
      this.$store
        .dispatch(fieldModel.namespace + "/get", {
          additionalFilter: `filter[company_id]=${this.companyId}`,
        })
        .then((response) => {
          if (response?.data && response?.meta) {
            if (!this.onlyCreate) {
              const useFieldIdList = this.useFields.map((v) => v.id);
              this.fieldList = response.data.filter((field) => {
                if (
                  !useFieldIdList.includes(field.id) ||
                  (this.value && field.id === this.value.id)
                ) {
                  return true;
                } else {
                  return false;
                }
              });
            } else {
              const useFieldIdList = this.useFields.map((v) => v.id);
              this.fieldList = response.data.filter((field) => {
                if (!useFieldIdList.includes(field.id)) {
                  return true;
                } else {
                  return false;
                }
              });

              // this.meta = response.meta;
            }

            this.meta = response.meta;
          }
        })
        .finally(() => {
          this.fieldLoading = false;
        });
    }
  },
  methods: {
    fieldScrollEnd() {
      if (!this.concating && this.meta?.current_page < this.meta?.last_page) {
        this.concating = true;
        this.$store
          .dispatch(fieldModel.namespace + "/get", {
            isSelect: true,
            page: this.meta.current_page + 1,
          })
          .then((response) => {
            if (response?.data?.length) {
              const useFieldIdList = this.useFields.map((v) => v.id);
              this.fieldList = this.fieldList.concat(
                response.data.filter((field) => {
                  if (
                    !useFieldIdList.includes(field.id) ||
                    (this.value && field.id === this.value.id)
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                })
              );
            }
            if (response?.meta) {
              this.meta = response.meta;
            }
          })
          .finally(() => {
            this.concating = false;
          });
      }
    },
    fieldBlur() {
      if (this.fieldSaveList?.length) {
        this.fieldList = this.fieldSaveList;
      }
    },
    fieldSearch(value) {
      this.$store
        .dispatch(fieldModel.namespace + "/get", {
          isSelect: true,
          filterValue: value,
        })
        .then((response) => {
          if (response?.data) {
            if (!this.onlyCreate) {
              const useFieldIdList = this.useFields.map((v) => v.id);
              this.fieldList = response.data.filter((field) => {
                if (
                  !useFieldIdList.includes(field.id) ||
                  (this.value && field.id === this.value.id)
                ) {
                  return true;
                } else {
                  return false;
                }
              });
            } else {
              this.fieldList = response.data;
            }
          }
          if (response?.meta) {
            this.meta = response.meta;
          }
        })
        .finally(() => {
          this.fieldLoading = false;
        });
    },
    save() {
      if (this.field) {
        if (this.isUpdate) {
          this.isEdit = false;
          this.$emit("changeMode", "view");
          this.$emit("updateField", this.value);
        } else {
          this.$emit("add", this.value);
        }
      }
    },
    update() {
      if (!this.isEdit && this.isUpdate) {
        this.isEdit = true;
        this.$emit("changeMode", "edit");
        this.fieldLoading = true;
        this.$store
          .dispatch(fieldModel.namespace + "/get", {
            additionalFilter: `filter[company_id]=${this.companyId}`,
          })
          .then((response) => {
            if (response?.data) {
              if (!this.onlyCreate) {
                const useFieldIdList = this.useFields.map((v) => v.id);
                this.fieldList = response.data.filter((field) => {
                  if (
                    !useFieldIdList.includes(field.id) ||
                    (this.value && field.id === this.value.id)
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                });
              } else {
                this.fieldList = response.data;
              }
            }
            if (response?.meta) {
              this.meta = response.meta;
            }
          })
          .finally(() => {
            this.fieldLoading = false;
          });
      }
    },
    remove() {
      this.$emit("delete");
    },
  },
};
</script>

<style lang="scss" scoped>
.field__create {
  background: #fff;
  border-radius: 20 / 7.1 + vh;
  border: 1px solid #e8e8e8;
  width: 100%;
  @include padding(20, 20, 20, 20);
  @include margin(0, 0, 20, 0);
  @media (max-width: 440px) {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
.field__list {
  background: #fff;
  border-radius: 20 / 7.1 + vh;
  border: 1px solid #e8e8e8;
  width: 100%;
  @include padding(20, 20, 20, 20);
  @include margin(0, 0, 20, 0);
  @media (max-width: 440px) {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
label {
  display: block;
  @include fontSize(12);
  @include margin(0, 0, 15, 0);
  span {
    display: block;
    @include margin(0, 0, 5, 0);
  }
}
.box {
  display: flex;
  @include margin(0, 0, 0, -20);
  .button {
    @include margin(0, 0, 0, 20);
  }
}
.error-simple {
  color: #ff0000;
  @include fontSize(10);
  @include margin(2, 0, 0, 0);
}
</style>
