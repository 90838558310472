<template>
  <section>
    Офферы данной вакансии будут архивированы. Чтобы отменить изменения,
    выключите данную опцию.
  </section>
</template>

<script>
export default {
  name: "modal-archive",
};
</script>
