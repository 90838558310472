<template>
  <ui-modal v-if="isShow" :label="label" @close="close">
    <content :is="content" />
  </ui-modal>
</template>

<script>
import { UiModal } from "@/shared/ui";
import { selectModalModel } from "@/features/select-modal";

export default {
  name: "feauty-modal",
  components: { UiModal },
  computed: {
    isShow() {
      return this.$store.getters[selectModalModel.namespace + "/show"];
    },
    label() {
      return this.$store.getters[selectModalModel.namespace + "/label"];
    },
    content() {
      return this.$store.getters[selectModalModel.namespace + "/content"];
    },
  },
  methods: {
    close() {
      return this.$store.dispatch(selectModalModel.namespace + "/setup", {
        show: false,
      });
    },
  },
};
</script>
