<template>
  <section v-if="!loading && !message">
    <section>
      Вы действительно хотите разархивировать вакансию:
      <strong>«{{ vacancyName }}»</strong>?
    </section>
    <section v-if="isActions" class="actions">
      <button-border @click.native="reject">Нет</button-border>
      <ui-button @click.native="resolve">Да</ui-button>
    </section>
  </section>
  <section v-else-if="message">
    {{ message }}
    <section v-if="isActions" class="actions">
      <button-border @click.native="reject">Нет</button-border>
      <ui-button @click.native="resolve">Да</ui-button>
    </section>
  </section>
  <section v-else class="modal-loading">
    <loading-simple />
  </section>
</template>

<script>
import { LoadingSimple, UiButton, ButtonBorder } from "@/shared/ui";
import { vacancyModel } from "@/entities/vacancy";

export default {
  name: "feature-vacancy-archive-out",
  components: {
    UiButton,
    ButtonBorder,
    LoadingSimple,
  },
  data() {
    return {
      vacancyId: null,
      vacancyName: "",
      loading: false,
      message: null,
      isActions: true,
    };
  },
  created() {
    const vacancy = window.vacancy;
    if (vacancy) {
      this.vacancyName = vacancy.name;
      this.vacancyId = vacancy.id;
    }
  },
  methods: {
    resolve() {
      this.isActions = true;
      this.message = null;
      this.loading = true;
      this.$store
        .dispatch(vacancyModel.namespace + "/update", {
          id: this.vacancyId,
          data: {
            active: true,
          },
        })
        .then(() => {
          this.$store.commit(
            vacancyModel.namespace + "/" + vacancyModel.namespace,
            []
          );
          this.$store
            .dispatch(vacancyModel.namespace + "/get", {
              additionalFilter: `filter[active]=0`,
            })
            .finally(() => {
              this.$emit("close");
              this.loading = false;
            });
        })
        .catch(() => {
          this.isActions = true;
          this.message = `Не удалось разархивировать вакансию: «${this.vacancyName}». Повторить попытку?`;
          this.loading = false;
        });
    },
    reject() {
      this.message = null;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  @include margin(15, 0, 0, -10);
  button {
    width: max-content;
    @include margin(0, 0, 0, 10);
  }
}
.modal-loading {
  aside {
    height: 40px;
    color: #f55320;
  }
}
</style>
