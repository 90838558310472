<template>
  <form @submit.prevent="reset" class="reset-password">
    <h2>Сброс пароля</h2>
    <template v-if="!isResetSuccess">
      <ui-input
        v-model="password"
        name="password"
        placeholder="Пароль"
        class="reset-password__input"
        :error="error('password')"
      />
      <ui-button
        class="reset-password__button"
        :disabled="pending"
        :loading-left="pending"
        >Сбросить</ui-button
      >
    </template>
    <template v-else>
      <div style="color: #787878">
        Пароль успешно сброшен. Перейдите на страницу авторизации для входа в
        систему.
      </div>
    </template>
    <router-link class="link" to="/sign-in">Авторизация</router-link>
  </form>
</template>

<script>
import { AuthResetPasswordModel } from "@/features/auth";
import { UiInput, UiButton } from "@/shared/ui";

export default {
  name: "AuthResetPassword",
  components: { UiInput, UiButton },
  data() {
    return {
      namespace: AuthResetPasswordModel.namespace,
      isResetSuccess: false,
    };
  },
  created() {
    this.isResetSuccess = false;
    this.$store.commit("errors", {});
    this.$store.commit(this.namespace + "/email", this.$route.query.email);
    this.$store.commit(this.namespace + "/token", this.$route.query.token);
  },
  computed: {
    pending() {
      return this.$store.getters[this.namespace + "/pending"];
    },
    password: {
      get() {
        return this.$store.getters[this.namespace + "/password"];
      },
      set(value) {
        this.$store.commit(this.namespace + "/password", value);
      },
    },
  },
  methods: {
    error(key) {
      if (this.$store.getters.errors[key]) {
        return this.$store.getters.errors[key][0];
      } else {
        return "";
      }
    },
    reset() {
      this.$store.dispatch(this.namespace + "/resetPassword").then(() => {
        this.isResetSuccess = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  display: block;
  width: 100%;
  text-align: right;
  margin-top: 20px;
  font-size: 14px;
  color: #b6b6b6;
  transition: color 0.35s ease;
  &:hover {
    color: #f55320;
  }
}

.reset-password {
  border: 1px solid #e8e8e8;
  border-radius: 7 / 7.1 + vh;
  background: #fff;
  @include padding(40, 40, 70, 40);
  @include width(350);
  @media (max-width: 500px) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    padding: 20px;
    height: 100%;
  }
  &__input {
    @include margin(15, 0, 0, 0);
  }
  &__button {
    @include margin(15, 0, 0, 0);
  }
  h2 {
    text-align: center;
    @include margin(0, 0, 30, 0);
    @include fontSize(20);
    @media (max-width: 500px) {
      margin-bottom: 20px;
    }
  }
}
</style>
