import { generateModuleStore } from "@/shared/lib";
import { http } from "@/shared/api";
import { app } from "@/app/index.js";

const namespace = "managers";

const module = generateModuleStore({
  namespace,
  filterName: "name",
  actions: {
    update({ state, commit }, { id, data }) {
      app.$store.commit("errors", {});
      return new Promise((resolve, reject) => {
        if (!state.pending) {
          commit("pending", true);
          http
            .post("/" + namespace + "/" + id + "?_method=PUT", data)
            .then((response) => {
              resolve(response);
              commit("pending", false);
            })
            .catch((error) => {
              reject(error);
              commit("pending", false);
            });
        } else {
          resolve(true);
        }
      });
    },
  },
});

export { namespace, module };
