<template>
  <section class="topbar">
    <tabs-simple
      class="tabs"
      :tabs="tabs"
      :active="active"
      @change="changeTab"
    />
    <div class="box">
      <ui-input v-model="search" placeholder="Поиск" class="search">
        <template #icon>
          <search-icon />
        </template>
      </ui-input>
      <button-border v-if="viewCreateButton" @click.native="create">
        Добавить
        <template #icon>
          <plus-icon />
        </template>
      </button-border>
    </div>
  </section>
</template>

<script>
import { TabsSimple, UiInput, ButtonBorder } from "@/shared/ui";
import { PlusIcon, SearchIcon } from "@/shared/icons";
import Cookies from "js-cookie";
import { checkAccess } from "@/shared/lib";

export default {
  name: "topbar-templates",
  components: { TabsSimple, UiInput, ButtonBorder, PlusIcon, SearchIcon },
  data() {
    return {
      search: "",
      searchTimer: 0,
      isWatch: true,
      tabs: [],
    };
  },
  created() {
    const accessLevel = Cookies.get("ft_access_level");
    if (checkAccess("template-directory-list", Number(accessLevel))) {
      this.tabs.push({
        field: "template-directory-list",
        name: "Справочники",
        notification: null,
      });
    }
    if (checkAccess("template-vacancy-list", Number(accessLevel))) {
      this.tabs.push({
        field: "template-vacancy-list",
        name: "Шаблоны вакансии",
        notification: null,
      });
    }
    if (checkAccess("template-field-list", Number(accessLevel))) {
      this.tabs.push({
        field: "template-field-list",
        name: "Поля",
        notification: null,
      });
    }
  },
  watch: {
    search(value) {
      if (this.isWatch) {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
          switch (this.$route.name) {
            case "template-directory-list":
              this.$store.dispatch("directories/get", {
                filterValue: value,
              });
              break;
            case "template-vacancy-list":
              this.$store.dispatch("vacancyTemplates/get", {
                filterValue: value,
              });
              break;
            case "template-field-list":
              this.$store.dispatch("fieldTemplates/get", {
                filterValue: value,
              });
              break;
          }
        }, 800);
      } else {
        this.isWatch = true;
      }
    },
  },
  computed: {
    active() {
      if (this.$route.name === "field-list") {
        return "directory-list";
      }
      return this.$route.name;
    },
    viewCreateButton() {
      const accessLevel = Cookies.get("ft_access_level");

      if (this.$route.name === "template-directory-list") {
        return checkAccess("template-directory-create", Number(accessLevel));
      } else if (this.$route.name === "template-vacancy-list") {
        return checkAccess("template-vacancy-create", Number(accessLevel));
      } else if (this.$route.name === "template-field-list") {
        return checkAccess("template-field-create", Number(accessLevel));
      } else {
        return false;
      }
    },
  },
  methods: {
    create() {
      if (this.$route.name === "template-directory-list") {
        this.$router
          .push({
            name: "template-directory-create",
          })
          .catch(() => {});
      }
      if (this.$route.name === "template-field-list") {
        this.$router
          .push({
            name: "template-field-create",
          })
          .catch(() => {});
      }
      if (this.$route.name === "template-vacancy-list") {
        this.$router
          .push({
            name: "template-vacancy-create",
          })
          .catch(() => {});
      }
    },
    changeTab(tab) {
      this.isWatch = false;
      this.search = "";
      this.$router.push({ name: tab }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1 / 7.1 + vw solid #e8e8e8;
  @include height(62);
  @include padding(0, 50, 0, 50);
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    border-bottom: 1 / 7.1 + vw solid #e8e8e8;
    padding: 0 20px;
    height: auto;
    min-height: auto;
    max-height: max-content;
  }
}
.box {
  display: flex;
  width: 40%;
  align-items: flex-start;
  @media (max-width: 900px) {
    width: 100%;
  }
}
.tabs {
  margin-top: auto;
  @media (max-width: 900px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.search {
  @include margin(0, 12, 0, 0);
}
</style>
