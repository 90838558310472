import axios from "axios";
import { app } from "@/app/index.js";
import Cookies from "js-cookie";

const baseURL = process.env.VUE_APP_API;
const http = axios.create({ baseURL });
const pagesWithoutToken = ["/company/invite", "/sign-in", "/reset-password"];

http.interceptors.request.use((config) => {
  const token = Cookies.get("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    if (!pagesWithoutToken.includes(window.location.pathname)) {
      app.$router.push({ name: "sign-in" });
    }
  }
  return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    errorHandling(error.response);
    return Promise.reject(error);
  }
);

function errorHandling(response) {
  if (response?.status) {
    switch (response.status) {
      case 401:
        if (!pagesWithoutToken.includes(window.location.pathname)) {
          app.$store.dispatch("viewer/logout");
        }
        break;
      case 422:
        // ошибки валидации
        app.$store.commit("errors", response.data.errors);
        break;
      case 429:
        // слишком много запросов
        break;
    }
  }
}

export { http };
