const USER = 1;
const MANAGER = 50;
const ADMINISTRATOR = 90;
const OWNER = 100;

const roleLevel = {
  [USER]: "Пользователь",
  [MANAGER]: "Менеджер",
  [ADMINISTRATOR]: "Администратор",
  [OWNER]: "Владелец",
};

const role = {
  profile: [USER, MANAGER, ADMINISTRATOR, OWNER],

  "vacancy-list": [ADMINISTRATOR, OWNER],
  "vacancy-create": [ADMINISTRATOR, OWNER],
  "vacancy-update": [ADMINISTRATOR, OWNER],

  "offer-list": [ADMINISTRATOR, OWNER],
  "offer-create": [ADMINISTRATOR, OWNER],
  "offer-update": [ADMINISTRATOR, OWNER],

  "candidate-list": [ADMINISTRATOR, OWNER, MANAGER],
  "candidate-create": [ADMINISTRATOR, OWNER],
  "candidate-update": [ADMINISTRATOR, OWNER],
  "candidate-import": [ADMINISTRATOR, OWNER],

  "company-list": [ADMINISTRATOR, OWNER],
  "company-create": [ADMINISTRATOR, USER],
  "company-update": [ADMINISTRATOR, OWNER],

  "company-employee-list": [ADMINISTRATOR, OWNER],
  "company-employee-create": [ADMINISTRATOR, OWNER],
  "company-employee-update": [ADMINISTRATOR, OWNER],

  "template-field-list": [],
  "template-field-create": [],
  "template-field-update": [],

  "template-directory-list": [],
  "template-directory-create": [],
  "template-directory-update": [],

  "template-vacancy-list": [],
  "template-vacancy-create": [],
  "template-vacancy-update": [],

  "manager-list": [ADMINISTRATOR, OWNER],
  "manager-create": [ADMINISTRATOR, OWNER],
  "manager-update": [ADMINISTRATOR, OWNER],

  "user-list": [OWNER],
  "user-create": [ADMINISTRATOR, OWNER],
  "user-update": [ADMINISTRATOR, OWNER],
  "user-blocked": [],
  "user-change-password": [],

  "response-list": [USER, MANAGER, ADMINISTRATOR, OWNER],
};

function convertRoleLevel(number) {
  if (roleLevel[number]) {
    return roleLevel[number];
  } else {
    return number;
  }
}

function checkRole(entity, roleLevel) {
  if (role[entity]) {
    return role[entity].includes(roleLevel);
  } else {
    return false;
  }
}

export { roleLevel, convertRoleLevel, checkRole };
