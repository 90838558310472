<template>
  <section class="topbar">
    <tabs-simple
      class="tabs"
      :tabs="tabs"
      :active="active"
      @change="changeTab"
    />
    <div class="box">
      <ui-input v-model="search" placeholder="Поиск">
        <template #icon>
          <search-icon />
        </template>
      </ui-input>
      <section class="box-button">
        <button-border
          class="button"
          v-if="viewCreateButton"
          @click.native="create"
        >
          Создать
          <template #icon>
            <plus-icon />
          </template>
        </button-border>
        <button-border
          v-if="$route.name === 'candidate-list'"
          class="button"
          @click.native="
            $router.push({ name: 'candidate-import' }).catch(() => {})
          "
        >
          Импортировать
          <template #icon>
            <plus-icon />
          </template>
        </button-border>
      </section>
    </div>
  </section>
</template>

<script>
import { TabsSimple, UiInput, ButtonBorder } from "@/shared/ui";
import { PlusIcon, SearchIcon } from "@/shared/icons";
import { accessCheck } from "@/shared/lib/check-access";
import { viewerModel } from "@/entities/viewer";

export default {
  name: "topbar-settings",
  components: { TabsSimple, UiInput, ButtonBorder, PlusIcon, SearchIcon },
  data() {
    return {
      search: "",
      searchTimer: 0,
      isWatch: true,
      // tabs: [],
    };
  },
  created() {
    this.$router.push({ name: this.tabs[0].field });
    // this.accessLevel =
    //   this.$store.getters[viewerModel.namespace + "/accessLevel"];
    // this.roleLevel = this.$store.getters[viewerModel.namespace + "/roleLevel"];
  },
  watch: {
    search(value) {
      if (this.isWatch) {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
          switch (this.$route.name) {
            case "vacancy-list":
              this.$store.dispatch("vacancies/get", {
                filterValue: value,
              });
              break;
            case "offer-list":
              this.$store.dispatch("offers/get", {
                filterValue: value,
              });
              break;
            case "candidate-list":
              this.$store.dispatch("candidates/get", {
                filterValue: value,
              });
              break;
          }
        }, 800);
      } else {
        this.isWatch = true;
      }
    },
  },
  computed: {
    tabs() {
      const tabs = [];
      if (this.isGazprom) {
        if (this.accessLevel === 100) {
          tabs.push({
            field: "vacancy-list",
            name: "Вакансии",
            notification: null,
          });
        }
      } else {
        if (
          accessCheck(
            "vacancy-list",
            Number(this.accessLevel),
            Number(this.roleLevel)
          )
        ) {
          tabs.push({
            field: "vacancy-list",
            name: "Вакансии",
            notification: null,
          });
        }
      }

      if (
        accessCheck(
          "offer-list",
          Number(this.accessLevel),
          Number(this.roleLevel)
        )
      ) {
        tabs.push({
          field: "offer-list",
          name: "Офферы",
          notification: null,
        });
      }
      if (
        accessCheck(
          "candidate-list",
          Number(this.accessLevel),
          Number(this.roleLevel)
        )
      ) {
        tabs.push({
          field: "candidate-list",
          name: "Кандидаты",
          notification: null,
        });
      }
      if (
        this.isResponse &&
        accessCheck(
          "response-list",
          Number(this.accessLevel),
          Number(this.roleLevel)
        )
      ) {
        tabs.push({
          field: "response-list",
          name: "Ответы",
          notification: null,
        });
      }
      return tabs;
    },
    accessLevel() {
      const accessLevel =
        this.$store.getters[viewerModel.namespace + "/accessLevel"];
      if (accessLevel) {
        return accessLevel;
      } else {
        return 1;
      }
    },
    roleLevel() {
      const roleLevel =
        this.$store.getters[viewerModel.namespace + "/roleLevel"];
      if (roleLevel) {
        return roleLevel;
      } else {
        return 1;
      }
    },
    isResponse() {
      if (this.isDeveloper) return true;

      const company = this.currentCompany?.id;

      if (!company) return false;
      if (company === 1001 || company === 1003) return true;

      return false;
    },
    isDeveloper() {
      const currentUser =
        this.$store.getters[viewerModel.namespace + "/current"];
      if (currentUser) {
        return currentUser.access_level >= 90;
      } else {
        return false;
      }
    },
    isGazprom() {
      return (
        this.$store.getters[viewerModel.namespace + "/currentCompany"].id ===
        1003
      );
    },
    currentCompany() {
      const company =
        this.$store.getters[viewerModel.namespace + "/currentCompany"];

      if (!company) return;

      return company;
    },
    active() {
      return this.$route.name;
    },
    viewCreateButton() {
      // const accessLevel = Cookies.get("ft_access_level");
      if (this.$route.name === "vacancy-list") {
        return accessCheck(
          "vacancy-create",
          Number(this.accessLevel),
          Number(this.roleLevel)
        );
      } else if (this.$route.name === "offer-list") {
        return accessCheck(
          "offer-create",
          Number(this.accessLevel),
          Number(this.roleLevel)
        );
      } else if (this.$route.name === "candidate-list") {
        return accessCheck(
          "candidate-create",
          Number(this.accessLevel),
          Number(this.roleLevel)
        );
      } else {
        return false;
      }
    },
  },
  methods: {
    create() {
      if (this.$route.name === "vacancy-list") {
        this.$router
          .push({
            name: "vacancy-create",
          })
          .catch(() => {});
      } else if (this.$route.name === "offer-list") {
        this.$router
          .push({
            name: "offer-create",
          })
          .catch(() => {});
      } else if (this.$route.name === "candidate-list") {
        this.$router
          .push({
            name: "candidate-create",
          })
          .catch(() => {});
      }
    },
    changeTab(tab) {
      this.isWatch = false;
      this.search = "";
      this.$router.push({ name: tab }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1 / 7.1 + vw solid #e8e8e8;
  @include height(62);
  @include padding(0, 50, 0, 50);
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    border-bottom: 1 / 7.1 + vw solid #e8e8e8;
    padding: 20px 20px 0;
    height: auto;
    min-height: auto;
    max-height: max-content;
  }
}
.box {
  display: flex;
  align-items: flex-start;
  width: 52%;
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    flex-direction: column;
  }
}
.tabs {
  margin-top: auto;
  @media (max-width: 900px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.box-button {
  display: flex;
  @media (max-width: 500px) {
    justify-content: flex-end;
    width: 100%;
    @include margin(12, 0, 0, 0);
  }
}
.button {
  @include margin(0, 0, 0, 12);
}
</style>
