export const scrollEndMixin = {
  data() {
    return {
      namespace: "",
      scrollEndMethodParameters: {
        concat: true,
      },
    };
  },
  methods: {
    callbackScrollEnd() {},
    scrollPage(e) {
      if (
        e.target.scrollTop + e.target.clientHeight + 20 >=
        e.target.scrollHeight
      ) {
        this.$store
          .dispatch(this.namespace, this.scrollEndMethodParameters)
          .finally(() => {
            this.callbackScrollEnd();
          });
      }
    },
  },
};
