import { app } from "@/app/index.js";
import Cookies from "js-cookie";
import { http } from "@/shared/api";

export const namespace = "viewer";

export const module = {
  namespaced: true,
  state: {
    current: null,
    currentCompany: null,
    token: null,
    pending: false,
    roleLevel: 1,
  },
  getters: {
    current: (s) => s.current,
    currentCompany: (s) => {
      const user = s.current?.id;

      if (s.currentCompany) return s.currentCompany;

      if (!user) return s.currentCompany;

      const localData = localStorage.getItem(`setup_${user}`);

      if (!localData) return s.currentCompany;

      const setup = JSON.parse(localData);

      const currentCompany = setup.filter.company;

      if (!currentCompany) return null;

      return currentCompany;
    },
    accessLevel: (state) => {
      const current = state.current;

      if (!current?.access_level) return 1;

      return current.access_level;
    },
    roleLevel: (_, getters) => {
      const currentCompany = getters.currentCompany;

      if (!currentCompany?.company_access_level) return 1;

      return currentCompany.company_access_level;
    },
    pending: (s) => s.pending,
    token: (s) => s.token,
  },
  mutations: {
    token(state, value) {
      state.token = value;
    },
    pending(state, value) {
      state.pending = value;
    },
    current(state, value) {
      state.current = value;
    },
    currentCompany(state, value) {
      state.currentCompany = value;

      const user = state.current.id;
      const localData = localStorage.getItem(`setup_${user}`);

      if (!localData) {
        const setup = {
          filter: {
            company: value,
          },
        };
        localStorage.setItem(`setup_${user}`, JSON.stringify(setup));
      } else {
        const setup = JSON.parse(localData);
        setup.filter.company = value;
        localStorage.setItem(`setup_${user}`, JSON.stringify(setup));
      }
    },
  },
  actions: {
    saveAvatar({ dispatch, commit, state }, { userId, avatar }) {
      return new Promise((resolve, reject) => {
        http
          .post(
            `/users/${userId}/avatar`,
            { avatar },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            resolve(response);
            const newCurrent = { ...state.current };
            newCurrent.avatar = "";
            commit("current", newCurrent);
            dispatch("current");
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    current({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (!state.pending) {
          commit("pending", true);
          commit("current", null);
          http
            .get("/users/current")
            .then((response) => {
              if (response?.data?.data) {
                // const logos = {
                //   all: logoFt,
                //   1000: logoPvm,
                //   1001: logoFt,
                // };
                const user = response.data.data;
                commit("current", response.data.data);

                if (user.companies?.length) {
                  commit("currentCompany", user.companies[0]);
                }
                commit("pending", false);
                Cookies.set("ft_access_level", user.access_level);
                Cookies.set(
                  "ft_companies_length",
                  user?.companies?.length ? user.companies.length : 0
                );
                Cookies.set("ft_email", user.email);
                Cookies.set("ft_name", user.name);
              }
              resolve(response.data.data);
            })
            .catch((error) => {
              commit("pending", false);
              reject(error);
            });
        } else {
          resolve(true);
        }
      });
    },
    logout({ commit }) {
      commit("token", null);
      Cookies.remove("token");
      Cookies.remove("ft_access_level");
      Cookies.remove("ft_companies_length");
      Cookies.remove("ft_email");
      Cookies.remove("ft_name");
      app.$router.push({ name: "sign-in" });
    },
  },
};
