<template>
  <ui-table
    :loading="pending"
    :concating="concating"
    :columns="columns"
    :data="data"
    @rowClick="rowClick"
  >
    <template #row="{ row, column }">
      <div v-if="column.field === 'vacancy'">
        <strong>
          {{ row.vacancy.name }}
        </strong>
      </div>
      <div v-else-if="column.field === 'candidate'" class="offer-candidate">
        <div>
          <strong>
            {{ row[column.field]["surname"] }}
            {{
              row[column.field]["name"]?.length
                ? row[column.field]["name"][0] + "."
                : ""
            }}
            {{
              row[column.field]["patronymic"]?.length
                ? row[column.field]["patronymic"][0] + "."
                : ""
            }}
          </strong>
        </div>
        <div class="offer-candidate__status-wrapper">
          <div
            v-if="row.status !== 'rejected' && row.status !== 'expired'"
            class="offer-candidate__status"
            :class="'offer-candidate__status--' + row.status"
          >
            <check-icon /> {{ row.status | convertStatus }}
          </div>
          <div
            v-else
            class="offer-candidate__status offer-candidate__status--rejected"
          >
            <cross-icon />{{ row.status | convertStatus }}
          </div>
        </div>
      </div>
      <div v-else-if="column.field === 'actions'" class="offer-actions">
        <button @click="toView(row)" class="button-circle" title="Просмотр">
          <eye-icon />
        </button>
        <button
          @click="update(row.id)"
          class="button-circle"
          title="Редактировать"
        >
          <pen-icon />
        </button>
        <button
          v-if="row.status !== 'archived'"
          @click="toArchiveIn(row)"
          class="button-circle"
          title="Архивировать"
        >
          <archive-in-icon />
        </button>
        <button
          v-else
          @click="toArchiveOut(row)"
          class="button-circle"
          title="Разархивировать"
        >
          <archive-out-icon />
        </button>
      </div>
      <span v-else class="opacity">
        {{ row[column.field] }}
      </span>
    </template>
  </ui-table>
</template>

<script>
import "./styles.scss";
import { UiTable } from "@/shared/ui";
import {
  PenIcon,
  CheckIcon,
  CrossIcon,
  ArchiveInIcon,
  ArchiveOutIcon,
  EyeIcon,
} from "@/shared/icons";
import { convertStatus } from "@/shared/lib/offer-status";
import { offerModel } from "@/entities/offer";
import { convertDate } from "@/shared/lib";
import { notificationModel } from "@/entities/notification";
import { ModalArchiveIn, ModalArchiveOut } from "@/features/offer-modal";
import { viewerModel } from "@/entities/viewer";

export default {
  name: "vacancy-list",
  filters: { convertStatus, convertDate },
  components: {
    ArchiveInIcon,
    EyeIcon,
    ArchiveOutIcon,
    UiTable,
    PenIcon,
    CheckIcon,
    CrossIcon,
  },
  props: {
    offerStatus: {
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      columns: [
        {
          label: "vacancy",
          field: "vacancy",
        },
        {
          label: "candidate",
          field: "candidate",
        },
        {
          label: "actions",
          field: "actions",
        },
      ],
    };
  },
  computed: {
    currentCompany() {
      const company =
        this.$store.getters[viewerModel.namespace + "/currentCompany"];

      if (!company) return;

      return company;
    },
    data() {
      if (
        this.$store.getters[offerModel.namespace + "/" + offerModel.namespace]
          ?.length
      ) {
        return this.$store.getters[
          offerModel.namespace + "/" + offerModel.namespace
        ];
      } else {
        return [];
      }
    },
    pending() {
      return this.$store.getters[offerModel.namespace + "/pending"];
    },
    concating() {
      return this.$store.getters[offerModel.namespace + "/concating"];
    },
  },
  methods: {
    getCompanyId(company) {
      if (!company) return "";
      if (!company?.id) return "";
      if (company.id === "all") return "";

      return company.id;
    },
    toView(row) {
      const enviroments = {
        1000: "VUE_APP_LANDING_DOMAIN",
        1001: "VUE_APP_LANDING_FT_DOMAIN",
        1003: "VUE_APP_LANDING_GAZPROM_DOMAIN",
      };

      const query = `?id=${row.id}&token=${row.token_candidate}&viewer=administration`;
      let landing = process.env.VUE_APP_LANDING_DOMAIN;

      const company = row.company;

      if (company) {
        const enviroment = enviroments[company.id];
        landing = process.env[enviroment] || landing;
      }

      window.open(landing + query, "_blank");
    },
    toArchiveIn(row) {
      window.offer = {
        name: row.vacancy.name,
        id: row.id,
      };
      this.$store.commit(
        notificationModel.namespace + "/" + notificationModel.namespace,
        {
          display: true,
          label: "Уведомление",
          notClose: true,
          content: ModalArchiveIn,
        }
      );
    },
    toArchiveOut(row) {
      window.vacancy = {
        name: row.vacancy.name,
        id: row.id,
      };
      this.$store.commit(
        notificationModel.namespace + "/" + notificationModel.namespace,
        {
          display: true,
          label: "Уведомление",
          notClose: true,
          content: ModalArchiveOut,
        }
      );
    },
    update(id) {
      this.$router.push(`/offer/${id}/update`);
    },
    rowClick({ row, column }) {
      if (column.field !== "actions") {
        this.$router.push(`/offer/${row.id}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0.5;
}
.offer {
  &-candidate {
    display: flex;
    justify-content: space-between;
    background: #fafafa;
    border-radius: 12 / 7.1 + vh;
    padding: 16 / 7.1 + vh 24 / 7.1 + vh;
    &__label {
      @include fontSize(12);
      @include margin(10, 0, 0, 0);
    }
    &__status {
      display: flex;
      align-items: center;
      svg {
        width: auto;
        @include margin(0, 5, 0, 0);
        @include height(11);
      }
      &-wrapper {
        @include width(100);
      }
      &--creating {
        color: #848484;
      }
      &--created {
        color: #848484;
      }
      &--updating {
        color: #848484;
      }
      &--published {
        color: #848484;
      }
      &--validating {
        color: #848484;
      }
      &--validated {
        color: #848484;
      }
      &--sent {
        color: #ce980c;
      }
      &--seen {
        color: #238fdd;
      }
      &--rejected {
        color: #f55320;
      }
      &--accepted {
        color: #14b011;
      }
      &--in_progress {
        color: #848484;
      }
      &--archived {
        color: #848484;
      }
    }
  }
  &-status {
    &--active {
      color: #14b011;
      display: flex;
      align-items: center;
      svg {
        width: auto;
        @include margin(0, 5, 0, 0);
        @include height(11);
      }
    }
    &--no-active {
      color: #f55320;
      display: flex;
      align-items: center;
      svg {
        width: auto;
        @include margin(0, 5, 0, 0);
        @include height(11);
      }
    }
  }
  &-label {
    opacity: 0.5;
    @include fontSize(12);
    @include margin(10, 0, 0, 0);
  }
}
.button-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f55320;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  @include height(30);
  @include width(30);
  &:hover {
    transform: scale(1.06);
  }
  &:active {
    transform: scale(0.96);
  }
  svg {
    @include height(18);
    @include width(18);
  }
}
.offer-actions {
  display: flex;
  justify-content: flex-end;
  @include margin(0, 0, 0, -10);
  button {
    @include margin(0, 0, 0, 10);
  }
}
</style>
