<template>
  <section class="topbar">
    <tabs-simple
      class="tabs"
      :tabs="tabs"
      :active="active"
      @change="changeTab"
    />
    <div class="box">
      <ui-input v-model="search" placeholder="Поиск" class="search">
        <template #icon>
          <search-icon />
        </template>
      </ui-input>
      <button-border v-if="viewCreateButton" @click.native="create">
        Добавить
        <template #icon>
          <plus-icon />
        </template>
      </button-border>
    </div>
  </section>
</template>

<script>
import { TabsSimple, UiInput, ButtonBorder } from "@/shared/ui";
import { PlusIcon, SearchIcon } from "@/shared/icons";
import { checkRole } from "@/shared/lib";
import { accessCheck } from "@/shared/lib/check-access";
import { viewerModel } from "@/entities/viewer";

export default {
  name: "topbar-settings",
  components: { TabsSimple, UiInput, ButtonBorder, PlusIcon, SearchIcon },
  data() {
    return {
      search: "",
      searchTimer: 0,
      isWatch: true,
      tabs: [],
      roleLevel: 1,
      accessLevel: 1,
      viewer: null,
    };
  },
  created() {
    this.viewer = this.$store.getters[viewerModel.namespace + "/current"];
    this.roleLevel = this.$store.getters[viewerModel.namespace + "/roleLevel"];
    this.accessLevel =
      this.$store.getters[viewerModel.namespace + "/accessLevel"];

    if (
      accessCheck(
        "company-list",
        Number(this.accessLevel),
        Number(this.roleLevel)
      )
    ) {
      this.tabs.push({
        field: "company-list",
        name: "Компании",
        notification: null,
      });
    }
    if (
      accessCheck("user-list", Number(this.accessLevel), Number(this.roleLevel))
    ) {
      this.tabs.push({
        field: "user-list",
        name: "Пользователи",
        notification: null,
      });
    }
    if (
      accessCheck("manager-list", Number(this.accessLevel)) ||
      (this.isCompanyFt && checkRole("manager-list", this.roleLevel))
    ) {
      this.tabs.push({
        field: "manager-list",
        name: "Менеджеры",
        notification: null,
      });
    }
  },
  watch: {
    isCompanyFt(value) {
      const tabManager = this.tabs.findIndex(
        (tab) => tab.field === "manager-list"
      );

      if (value && !(tabManager + 1)) {
        this.tabs.splice(this.tabs.length, 0, {
          field: "manager-list",
          name: "Менеджеры",
          notification: null,
        });
      }

      if (!value && tabManager + 1) {
        this.tabs.splice(tabManager, 1);
      }
    },
    search(value) {
      if (this.isWatch) {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
          switch (this.$route.name) {
            case "company-list":
              this.$store.dispatch("companies/get", {
                filterValue: value,
              });
              break;
            case "user-list":
              this.$store.dispatch("users/get", {
                filterValue: value,
              });
              break;
            case "manager-list":
              this.$store.dispatch("managers/get", {
                filterValue: value,
              });
              break;
          }
        }, 800);
      } else {
        this.isWatch = true;
      }
    },
  },
  computed: {
    isCompanyFt() {
      const company =
        this.$store.getters[viewerModel.namespace + "/currentCompany"];
      const companyId = company?.id;
      if (!companyId) return false;
      if (companyId === 1001) return true;
      return false;
    },
    // roleLevel() {
    //   return this.$store.getters[viewerModel.namespace + "/roleLevel"];
    // },
    active() {
      return this.$route.name;
    },
    viewCreateButton() {
      // const accessLevel = Cookies.get("ft_access_level");
      if (this.$route.name === "company-list") {
        return accessCheck(
          "company-create",
          Number(this.accessLevel),
          Number(this.roleLevel)
        );
      } else if (this.$route.name === "user-list") {
        return accessCheck(
          "user-create",
          Number(this.accessLevel),
          Number(this.roleLevel)
        );
      } else if (this.$route.name === "manager-list") {
        return accessCheck(
          "manager-create",
          Number(this.accessLevel),
          Number(this.roleLevel)
        );
      } else {
        return false;
      }
    },
  },
  methods: {
    create() {
      if (this.$route.name === "company-list") {
        this.$router
          .push({
            name: "company-create",
          })
          .catch(() => {});
      }
      if (this.$route.name === "user-list") {
        this.$router
          .push({
            name: "user-create",
          })
          .catch(() => {});
      }
      if (this.$route.name === "manager-list") {
        this.$router
          .push({
            name: "manager-create",
          })
          .catch(() => {});
      }
    },
    changeTab(tab) {
      if (this.$route.name !== tab) {
        this.isWatch = false;
        this.search = "";
        this.$router.push({ name: tab }).catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1 / 7.1 + vw solid #e8e8e8;
  @include height(62);
  @include padding(0, 50, 0, 50);
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    border-bottom: 1 / 7.1 + vw solid #e8e8e8;
    padding: 0 20px;
    height: auto;
    min-height: auto;
    max-height: max-content;
  }
}
.box {
  display: flex;
  width: 40%;
  align-items: flex-start;
  @media (max-width: 900px) {
    width: 100%;
  }
}
.tabs {
  margin-top: auto;
  @media (max-width: 900px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.search {
  @include margin(0, 12, 0, 0);
}
</style>
