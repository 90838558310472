<template>
  <section v-if="name && accessLevel" tabindex="0" class="avatar">
    <div class="avatar__image">
      <img
        v-if="avatar && !errorAvatar"
        :src="avatar"
        @error="() => (errorAvatar = true)"
      />
      <span v-else>{{ name[0] }}</span>
    </div>
    <div class="avatar__box">
      <div class="avatar__full-name">
        <span>{{ name }}</span>
        <chevron-icon class="avatar__icon" />
      </div>
      <div v-if="accessLevel > 1" class="avatar__role">
        {{ accessLevel | convertAccessLevel }}
      </div>
      <div v-else class="avatar__role">
        {{ roleLevel | convertRoleLevel }}
      </div>
    </div>
    <ul class="avatar__menu">
      <li class="avatar__option" @click="profile">
        <user-icon class="avatar__option-icon" />
        <span>Профиль</span>
      </li>
      <li class="avatar__option" @click="logout">
        <logout-icon class="avatar__option-icon" />
        <span>Выйти</span>
      </li>
    </ul>
  </section>
</template>

<script>
import { LogoutIcon, ChevronIcon, UserIcon } from "@/shared/icons";
import { convertAccessLevel } from "@/shared/lib";
import { convertRoleLevel } from "@/shared/lib";
import { viewerModel } from "@/entities/viewer";
import Cookies from "js-cookie";

export default {
  name: "viewer-avatar",
  filters: { convertAccessLevel, convertRoleLevel },
  components: { LogoutIcon, ChevronIcon, UserIcon },
  data() {
    return {
      errorAvatar: false,
    };
  },
  computed: {
    name() {
      const currentUser =
        this.$store.getters[viewerModel.namespace + "/current"];
      if (currentUser) {
        return currentUser.name;
      } else {
        return Cookies.get("ft_name");
      }
    },
    avatar() {
      if (this.$store.getters[viewerModel.namespace + "/current"]?.avatar) {
        return this.$store.getters[viewerModel.namespace + "/current"].avatar;
      } else {
        return null;
      }
    },
    roleLevel() {
      const currentUser =
        this.$store.getters[viewerModel.namespace + "/current"];
      if (currentUser?.companies?.length) {
        return currentUser.companies[0].company_access_level;
      } else {
        return 1;
      }
    },
    accessLevel() {
      const currentUser =
        this.$store.getters[viewerModel.namespace + "/current"];
      if (currentUser) {
        return currentUser.access_level;
      } else {
        const accessLevel = Cookies.get("ft_access_level");
        if (accessLevel) {
          return Number(accessLevel);
        } else {
          return 1;
        }
      }
    },
  },
  methods: {
    profile() {
      if (this.$route.name !== "profile") {
        this.$router.push({ name: "profile" });
      }
    },
    logout() {
      this.$store.dispatch(viewerModel.namespace + "/logout");
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  position: relative;
  display: flex;
  cursor: pointer;
  &:focus-within {
    .avatar__icon {
      transform: rotate(180deg);
    }
    .avatar__menu {
      display: block;
    }
  }
  &__image {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: #f0efef;
    color: #949494;
    border-radius: 50%;
    border: 2 / 7.1 + vh solid #fff;
    @include width(42);
    @include height(42);
    @include fontSize(16);
    img {
      width: auto;
      height: 100%;
    }
  }
  &__full-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 90 / 7.1 + vh;
    color: #fff;
    @include fontSize(10);
  }
  &__icon {
    transition: transform 0.3s ease;
    color: #fff;
    @include margin(0, 0, 0, 6);
  }
  &__role {
    color: #fff;
    @include fontSize(10);
  }
  &__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include margin(0, 0, 0, 12);
  }
  &__menu {
    display: none;
    position: absolute;
    top: 120%;
    right: 0;
    margin: 0;
    padding: 0;
    background: #fff;
    border-bottom-left-radius: 20 / 7.1 + vh;
    border-bottom-right-radius: 20 / 7.1 + vh;
    border-top-left-radius: 20 / 7.1 + vh;
    border-top-right-radius: 20 / 7.1 + vh;
    border: 1px solid #e8e8e8;
    overflow: hidden;
    @include padding(10, 0, 0, 0);
    @include height(100);
    @include width(200);
  }
  &__option {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-items: center;
    opacity: 0.3;
    @include fontSize(16);
    @include padding(10, 10, 10, 10);
    cursor: pointer;
    &:hover {
      opacity: 1;
      color: #f55320;
      background: #f0f0f0;
    }
    &-icon {
      @include width(15);
      @include height(15);
      @include margin(0, 8, 0, 0);
    }
  }
}
</style>
