export const namespace = "notification";

export const module = {
  namespaced: true,
  state: {
    [namespace]: {
      display: false,
      label: null,
      content: null,
    },
  },
  getters: {
    [namespace]: (s) => s[namespace],
  },
  mutations: {
    [namespace](state, value) {
      state[namespace] = value;
    },
  },
  actions: {},
};
