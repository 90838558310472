<template>
  <form @submit.prevent="signIn" class="auth-by-password">
    <h2>Авторизация</h2>
    <ui-input
      v-model="email"
      name="email"
      placeholder="E-mail"
      class="auth-by-password__input"
      :error="error('email')"
    />
    <ui-input
      v-model="password"
      name="password"
      type="password"
      placeholder="Пароль"
      class="auth-by-password__input"
      :error="error('password')"
    />
    <ui-button class="auth-by-password__button" :loading-left="pending"
      >Войти</ui-button
    >
    <router-link class="link" to="/restore-password"
      >Забыли пароль?</router-link
    >
  </form>
</template>

<script>
import { AuthByPasswordModel } from "@/features/auth";
import { UiInput, UiButton } from "@/shared/ui";

export default {
  name: "AuthByPassword",
  components: { UiInput, UiButton },
  data() {
    return {
      namespace: AuthByPasswordModel.namespace,
    };
  },
  created() {
    this.$store.commit("errors", {});
  },
  computed: {
    pending() {
      return this.$store.getters[this.namespace + "/pending"];
    },
    email: {
      get() {
        return this.$store.getters[this.namespace + "/email"];
      },
      set(value) {
        this.$store.commit(this.namespace + "/email", value);
      },
    },
    password: {
      get() {
        return this.$store.getters[this.namespace + "/password"];
      },
      set(value) {
        this.$store.commit(this.namespace + "/password", value);
      },
    },
  },
  methods: {
    error(key) {
      if (this.$store.getters.errors[key]) {
        return this.$store.getters.errors[key][0];
      } else {
        return "";
      }
    },
    signIn() {
      this.$store.dispatch(this.namespace + "/signIn");
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  display: block;
  width: 100%;
  text-align: right;
  margin-top: 20px;
  font-size: 14px;
  color: #b6b6b6;
  transition: color 0.35s ease;
  &:hover {
    color: #f55320;
  }
}

.auth-by-password {
  border: 1px solid #e8e8e8;
  border-radius: 7 / 7.1 + vh;
  background: #fff;
  @include padding(40, 40, 70, 40);
  @include width(350);
  @media (max-width: 500px) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    padding: 20px;
    height: 100%;
  }
  &__input {
    @include margin(15, 0, 0, 0);
  }
  &__button {
    @include margin(15, 0, 0, 0);
  }
  h2 {
    text-align: center;
    @include margin(0, 0, 30, 0);
    @include fontSize(20);
    @media (max-width: 500px) {
      margin-bottom: 20px;
    }
  }
}
</style>
