import Vue from "vue";
import { router, store } from "./providers";
import App from "./ui.vue";

Vue.config.productionTip = false;

export const app = new Vue({
  store,
  router,
  render: (h) => h(App),
});
