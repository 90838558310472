<template>
  <section class="templates">
    <header-widget />
    <h2>Шаблоны</h2>
    <topbar-templates />
    <router-view />
  </section>
</template>

<script>
import { HeaderWidget } from "@/widgets/header";
import { TopbarTemplates } from "@/widgets/topbar";

export default {
  name: "layout-setting",
  components: {
    HeaderWidget,
    TopbarTemplates,
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-style: normal;
  color: #444855;
  font-family: "gilroy-medium", "Helvetica", "Arial", "sans-serif";
  margin: 0;
  @include fontSize(18);
  @include padding(30, 0, 0, 50);
  @media (max-width: 900px) {
    padding: 20px;
  }
}
.templates {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
