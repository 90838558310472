<template>
  <ui-table
    :loading="pending"
    :concating="concating"
    :columns="columns"
    :data="data"
    @rowClick="rowClick"
  >
    <template #row="{ row, column }">
      <strong v-if="column.field === 'name'">
        {{ row[column.field] }}
      </strong>
      <div
        v-else-if="column.field === 'active'"
        style="display: flex; justify-content: flex-end"
      >
        <div v-if="row[column.field]" class="vacancy-status--active">
          <check-icon />Активен
        </div>
        <div v-else class="vacancy-status--no-active">
          <cross-icon />Неактивен
        </div>
      </div>
      <span v-else class="opacity">
        {{ row[column.field] }}
      </span>
    </template>
  </ui-table>
</template>

<script>
import { UiTable } from "@/shared/ui";
import { vacancyTemplateModel } from "@/entities/vacancy-template";
import { CheckIcon, CrossIcon } from "@/shared/icons";

export default {
  name: "vacancy-template-list",
  components: { UiTable, CheckIcon, CrossIcon },
  data() {
    return {
      columns: [
        {
          label: "name",
          field: "name",
        },
        {
          label: "active",
          field: "active",
        },
      ],
    };
  },
  created() {
    this.$store.dispatch(vacancyTemplateModel.namespace + "/get");
  },
  computed: {
    data() {
      if (
        this.$store.getters[
          vacancyTemplateModel.namespace + "/" + vacancyTemplateModel.namespace
        ]?.length
      ) {
        return this.$store.getters[
          vacancyTemplateModel.namespace + "/" + vacancyTemplateModel.namespace
        ];
      } else {
        return [];
      }
    },
    pending() {
      return this.$store.getters[vacancyTemplateModel.namespace + "/pending"];
    },
    concating() {
      return this.$store.getters[vacancyTemplateModel.namespace + "/concating"];
    },
  },
  methods: {
    rowClick({ row }) {
      this.$router.push(`/template/vacancy/${row.id}/update`);
    },
  },
};
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0.5;
}
.vacancy {
  &-status {
    &--active {
      color: #14b011;
      display: flex;
      align-items: center;
      @include width(101);
      svg {
        width: auto;
        @include margin(0, 5, 0, 0);
        @include height(11);
      }
    }
    &--no-active {
      color: #f55320;
      display: flex;
      align-items: center;
      @include width(101);
      svg {
        width: auto;
        @include margin(0, 5, 0, 0);
        @include height(11);
      }
    }
  }
  &-label {
    opacity: 0.5;
    @include fontSize(12);
    @include margin(10, 0, 0, 0);
  }
}
</style>
