import { generateModuleStore } from "@/shared/lib";
import { http } from "@/shared/api";

const namespace = "users";

const module = generateModuleStore({
  namespace,
  filterName: "name",
  actions: {
    changePassword(_, { id, data }) {
      return new Promise((resolve, reject) => {
        http
          .patch(`/users/${id}/change-password`, data)
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    blocked(_, { id }) {
      return new Promise((resolve, reject) => {
        http
          .patch(`/users/${id}/block`)
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    unblocked(_, { id }) {
      return new Promise((resolve, reject) => {
        http
          .patch(`/users/${id}/unblock`)
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});

export { namespace, module };
