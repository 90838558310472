import { generateModuleStore } from "@/shared/lib";
import { http } from "@/shared/api";
import { app } from "@/app/index.js";

const namespace = "files";

const module = generateModuleStore({
  namespace,
  generateActions: false,
  actions: {
    get({ state, commit }, id) {
      app.$store.commit("errors", {});
      return new Promise((resolve, reject) => {
        if (!state.pending) {
          commit("pending", true);
          http
            .get(`/users/files/${id}`)
            .then((response) => {
              resolve(response.data.data);
              commit("pending", false);
            })
            .catch((error) => {
              reject(error);
              commit("pending", false);
            });
        } else {
          resolve(true);
        }
      });
    },
    download(_, id) {
      return new Promise((resolve, reject) => {
        http
          .get(`/users/files/${id}/download`, {
            responseType: "blob",
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    create({ state, commit }, data) {
      data.is_public = 1;
      app.$store.commit("errors", {});
      return new Promise((resolve, reject) => {
        if (!state.pending) {
          commit("pending", true);
          http
            .post("/users/files", data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              resolve(response.data.data);
              commit("pending", false);
            })
            .catch((error) => {
              reject(error);
              commit("pending", false);
            });
        } else {
          resolve(true);
        }
      });
    },
    update({ state, commit }, { id, data }) {
      data.is_public = 1;
      app.$store.commit("errors", {});
      return new Promise((resolve, reject) => {
        if (!state.pending) {
          commit("pending", true);
          let query = "";
          if (data.candidate_read) {
            query += "?candidate_read=" + data.candidate_read;
          }
          if (data.user_read) {
            query +=
              (query?.length ? "&" : "?") + "user_read=" + data.user_read;
          }
          http
            .patch(`/users/files/${id}${query}`)
            .then((response) => {
              resolve(response);
              commit("pending", false);
            })
            .catch((error) => {
              reject(error);
              commit("pending", false);
            });
        } else {
          resolve(true);
        }
      });
    },
    delete({ state, commit }, id) {
      app.$store.commit("errors", {});
      return new Promise((resolve, reject) => {
        if (!state.pending) {
          commit("pending", true);
          http
            .delete(`/users/files/${id}`)
            .then((response) => {
              resolve(response);
              commit("pending", false);
            })
            .catch((error) => {
              reject(error);
              commit("pending", false);
            });
        } else {
          resolve(true);
        }
      });
    },
  },
});

export { namespace, module };
