<template>
  <div>
    <ui-checkbox
      v-model="candidateRead"
      label="Все кандидаты могут просматривать"
      class="file-checkbox"
    />
    <ui-checkbox
      v-model="userRead"
      label="Все менеджеры компании могут просмотривать"
      class="file-checkbox"
    />
    <section
      class="file-simple"
      @click="upload"
      @dragenter="dragenter"
      @dragover="dragover"
      @drop="drop"
    >
      <span v-if="!loading">{{ placeholder }}</span>
      <loading-simple
        v-else
        style="color: #f55320; width: 40px; height: 40px"
      />
      <input
        type="file"
        class="file-simple__value"
        ref="file"
        :accept="accept"
        :multiple="multiple"
        @change="changeFiles"
      />
    </section>
    <ul class="file-simple__list">
      <li
        class="file-simple__item"
        v-for="(file, index) in files"
        :key="file.name"
      >
        <div class="file-simple__name">
          <strong class="file-simple__download" @click="download(file.name)">{{
            file.name
          }}</strong>
          <strong
            class="file-simple__close"
            title="Удалить файл"
            @click="remove(index)"
            >&#10005;</strong
          >
        </div>
        <div class="file-simple__size" v-if="file.size">
          {{ convertSize(file.size) }}
        </div>
      </li>
    </ul>
    <div v-if="error" class="file-simple__error">{{ error }}</div>
  </div>
</template>

<script>
import { fileModel } from "@/entities/file";
import { LoadingSimple, UiCheckbox } from "@/shared/ui";

export default {
  name: "file-simple",
  props: {
    value: {
      type: [Number, Object],
    },
    placeholder: {
      type: String,
      default: "Нажмите или перетащите файлы для загрузки",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: null,
    },
  },
  components: {
    LoadingSimple,
    UiCheckbox,
  },
  data() {
    return {
      fileId: null,
      files: [],
      loading: false,
      candidateRead: false,
      userRead: false,
    };
  },
  created() {
    if (this.value && typeof this.value === "object") {
      this.fileId = this.value.id;
      this.files = [this.value];
      this.candidateRead = this.value.candidate_read ? true : false;
      this.userRead = this.value.user_read ? true : false;
    }
  },
  watch: {
    candidateRead(value) {
      if (this.fileId) {
        this.$store.dispatch(fileModel.namespace + "/update", {
          id: this.fileId,
          data: {
            candidate_read: value ? "1" : "0",
            user_read: this.userRead ? "1" : "0",
          },
        });
      }
    },
    userRead(value) {
      if (this.fileId) {
        this.$store.dispatch(fileModel.namespace + "/update", {
          id: this.fileId,
          data: {
            candidate_read: this.candidateRead ? "1" : "0",
            user_read: value ? "1" : "0",
          },
        });
      }
    },
  },
  methods: {
    download(fileName) {
      this.$store
        .dispatch(fileModel.namespace + "/download", this.fileId)
        .then((response) => {
          const href = window.URL.createObjectURL(response.data);
          const anchorElement = document.createElement("a");
          anchorElement.href = href;
          anchorElement.download = fileName;
          document.body.appendChild(anchorElement);
          anchorElement.click();
          document.body.removeChild(anchorElement);
          window.URL.revokeObjectURL(href);
        });
    },
    createFile(files) {
      this.removeFile(this.fileId).finally(() => {
        this.$store
          .dispatch(fileModel.namespace + "/create", {
            file: files[0],
            candidate_read: this.candidateRead ? "1" : "0",
            user_read: this.userRead ? "1" : "0",
          })
          .then((response) => {
            this.fileId = response.id;
            this.$emit("input", response.id);
          });
      });
    },
    removeFile(id) {
      return new Promise((resolve, reject) => {
        if (id) {
          this.$store
            .dispatch(fileModel.namespace + "/delete", id)
            .then(() => {
              this.fileId = null;
              this.$emit("input", null);
              resolve(true);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(true);
        }
      });
    },
    convertSize(byte) {
      if (byte >= 1073741824) {
        return (byte / 1073741824).toFixed(2) + " GB";
      } else if (byte >= 1048576) {
        return (byte / 1048576).toFixed(2) + " MB";
      } else if (byte >= 1024) {
        return (byte / 1024).toFixed(2) + " kB";
      } else {
        return byte + " B";
      }
    },
    remove(index) {
      const dataTransfer = new DataTransfer();
      for (let i = 0; i < this.files.length; i++) {
        if (i !== index) {
          dataTransfer.items.add(this.files[i]);
        }
      }
      this.removeFile(this.fileId).then(() => {
        const fileList = dataTransfer.files;
        this.$refs.file.files = fileList;
        this.files = this.$refs.file.files;
      });
    },
    changeFiles(e) {
      this.files = e.target.files;
      this.createFile(this.files);
    },
    dragenter(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    dragover(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    drop(e) {
      e.stopPropagation();
      e.preventDefault();
      const dt = e.dataTransfer;
      const files = dt.files;
      const allowedExtensionList = this.accept.replace(" ", "")?.length
        ? this.accept.replace(" ", "").split(",")
        : [];

      let newFiles = new DataTransfer();

      for (let i = 0; i < files.length; i++) {
        const ext = files[i].name.split(".");
        if (allowedExtensionList.length) {
          if (allowedExtensionList.includes("." + ext[ext.length - 1])) {
            newFiles.items.add(files[i]);
          }
        } else {
          newFiles = files;
        }
      }

      if (this.multiple) {
        this.$refs.file.files = newFiles.files;
      } else {
        let dataTransfer = new DataTransfer();
        dataTransfer.items.add(newFiles[0]);
        const fileList = dataTransfer.files;
        this.$refs.file.files = fileList;
      }
      this.files = this.$refs.file.files;
      this.createFile(this.files);
    },
    upload() {
      this.$refs.file.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.file-simple {
  font-family: "gilroy-regular", "Helvetica", "Arial", "sans-serif";
  width: 100%;
  border: 1 / 7.1 + vh solid #e8e8e8;
  border-radius: 15 / 7.1 + vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a8a8a8;
  cursor: pointer;
  @include height(150);
  @include fontSize(10);
  &__value {
    display: none;
  }
  &__list {
    padding: 0;
    @include margin(10, 0, 0, 0);
  }
  &__item {
    list-style-type: none;
    width: 100%;
    border: 1 / 7.1 + vh solid #e8e8e8;
    border-radius: 15 / 7.1 + vh;
    @include margin(10, 0, 0, 0);
    @include padding(15, 15, 15, 15);
  }
  &__name {
    display: flex;
    justify-content: space-between;
    @include fontSize(10);
  }
  &__close {
    cursor: pointer;
  }
  &__size {
    @include fontSize(8);
  }
  &__error {
    color: #ff0000;
    @include fontSize(10);
    @include margin(2, 0, 0, 0);
  }
}
.file-simple__download {
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.file-checkbox {
  @include margin(0, 0, 10, 0);
}
</style>
