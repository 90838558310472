import { generateModuleStore } from "@/shared/lib";

const namespace = "fieldTemplates";

const module = generateModuleStore({
  namespace,
  filterName: "caption",
});

export { namespace, module };
