<template>
  <ui-table
    :loading="pending"
    :concating="concating"
    :columns="columns"
    :data="data"
    @rowClick="rowClick"
  >
    <template #row="{ row, column }">
      <strong v-if="column.field === 'caption'">
        {{ row[column.field] }}
      </strong>
      <div v-else-if="column.field === 'type'" class="field-type">
        <div :class="'field-type--' + row[column.field]">
          {{ row[column.field] | convertType }}
        </div>
      </div>
      <span v-else class="opacity">
        {{ row[column.field] }}
      </span>
    </template>
  </ui-table>
</template>

<script>
import "./styles.scss";
import { UiTable } from "@/shared/ui";
import { fieldModel } from "@/entities/field";
import { convertType } from "@/shared/lib/field-type.js";

export default {
  name: "field-list",
  components: { UiTable },
  filters: { convertType },
  data() {
    return {
      columns: [
        {
          label: "caption",
          field: "caption",
        },
        {
          label: "type",
          field: "type",
        },
      ],
    };
  },
  created() {
    this.$store.dispatch(fieldModel.namespace + "/get");
  },
  computed: {
    data() {
      if (
        this.$store.getters[fieldModel.namespace + "/" + fieldModel.namespace]
          ?.length
      ) {
        return this.$store.getters[
          fieldModel.namespace + "/" + fieldModel.namespace
        ];
      } else {
        return [];
      }
    },
    pending() {
      return this.$store.getters[fieldModel.namespace + "/pending"];
    },
    concating() {
      return this.$store.getters[fieldModel.namespace + "/concating"];
    },
  },
  methods: {
    rowClick({ row }) {
      this.$router.push(`/template/field/${row.id}/update`);
    },
  },
};
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0.5;
}
.field {
  &-type {
    display: flex;
    justify-content: flex-end;
    &--number {
      color: #ffb800;
    }
    &--string {
      color: #14b011;
    }
    &--directory {
      color: #ce980c;
    }
    &--file {
      color: #238fdd;
    }
    &--text {
      color: #0f890c;
    }
    &--boolean {
      color: #f55320;
    }
  }
}
</style>
