<template>
  <section v-if="!pending">
    <ui-input v-model="name" placeholder="Поиск..." />
    <ul class="ui-options">
      <li
        v-for="(user, index) in data"
        :key="index"
        @click="selected(user)"
        class="ui-option"
        :class="{
          'ui-option--active': user.id === userSelected?.id,
        }"
      >
        {{ user.name }} ({{ user.email }})
      </li>
    </ul>
  </section>
  <section v-else class="ui-loading">
    <loading-simple />
  </section>
</template>

<script>
import { UiInput, LoadingSimple } from "@/shared/ui";
import { userModel } from "@/entities/user";

export default {
  name: "entity-user-search",
  components: { UiInput, LoadingSimple },
  data() {
    return {
      name: "",
    };
  },
  created() {
    this.$store.dispatch(userModel.namespace + "/get");
  },
  computed: {
    data() {
      return this.$store.getters[userModel.namespace + "/users"];
    },
    pending() {
      return this.$store.getters[userModel.namespace + "/pending"];
    },
    userSelected() {
      return this.$store.getters[userModel.namespace + "/selected"];
    },
  },
  methods: {
    selected(user) {
      this.$store.commit(userModel.namespace + "/selected", user);
      this.$store.dispatch("notification", {
        show: false,
        label: null,
        content: null,
      });
    },
    convertAccessLevel(number) {
      switch (number) {
        case 100:
          return "Разработчик";
        case 90:
          return "Администратор";
        default:
          return number;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  width: 100%;
}
.ui-options {
  margin: 10 / 7.1 + vh 0 0 0;
  padding: 0;
}
.ui-option {
  border-radius: 5 / 7.1 + vh;
  cursor: pointer;
  list-style-type: none;
  @include fontSize(14);
  @include padding(10, 10, 10, 10);
  transition: all 0.3s ease;
  &--active {
    background: #f55320;
    color: #fff;
    &:hover {
      background: #f55320 !important;
      color: #fff !important;
    }
  }
  &:hover {
    background: #e8e8e8;
  }
}
.ui-loading {
  color: #ff4c00;
  aside {
    margin: 0 auto;
    @include width(40);
    @include height(40);
  }
}
</style>
