<template>
  <table class="ui-table">
    <thead v-if="isHeader">
      <tr>
        <th
          v-for="(column, index) in columns"
          :key="'column-' + index"
          :style="column.style"
        >
          {{ column.label || "Колонка-" + index }}
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-if="!loading && data?.length">
        <tr
          v-for="(row, indexRow) in data"
          :key="'row-' + indexRow"
          class="ui-table__row"
        >
          <td
            v-for="(column, indexColumn) in columns"
            :key="'row-column-' + indexColumn"
            class="ui-table__cell"
            :class="{ 'ui-table__cell--pointer': isRowClick }"
            :style="column.style"
            @click="rowClick(row, column)"
          >
            <slot name="row" :row="row" :column="column">{{
              column?.field ? row[column.field] : ""
            }}</slot>
          </td>
        </tr>
      </template>
      <tr v-if="loading" class="ui-table__loading">
        <td :colspan="columns.length">
          <slot name="loading">
            <loading-simple />
          </slot>
        </td>
      </tr>
      <tr v-else-if="!data.length" class="ui-table__notfound">
        <td :colspan="columns.length">
          <slot name="notfound">Нет данных</slot>
        </td>
      </tr>
      <tr v-else-if="concating" class="ui-table__concating">
        <td :colspan="columns.length">
          <slot name="concating">
            <loading-pagination />
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { LoadingSimple, LoadingPagination } from "@/shared/ui";

export default {
  name: "ui-table",
  props: {
    isHeader: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    concating: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: { LoadingSimple, LoadingPagination },
  computed: {
    isRowClick() {
      if (this.$listeners["rowClick"]) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    rowClick(row, column) {
      this.$emit("rowClick", { row, column });
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-table {
  border-collapse: separate;
  border-spacing: 0 12 / 7.1 + vh;
  width: 100%;
  @include fontSize(13);
  th,
  td {
    table-layout: fixed;
    @media (max-width: 900px) {
      width: 100% !important;
    }
  }
  &__row {
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
  &__cell {
    background: #fff;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    @include padding(17, 17, 17, 17);
    @media (max-width: 900px) {
      border-top: none;
      border-bottom: none;
      border-left: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
    }
    &--pointer {
      cursor: pointer;
    }
    &:first-child {
      border-left: 1px solid #e8e8e8;
      border-top-left-radius: 7 / 7.1 + vh;
      border-bottom-left-radius: 7 / 7.1 + vh;
      @media (max-width: 900px) {
        border-top: 1px solid #e8e8e8;
        border-top-left-radius: 7 / 7.1 + vh;
        border-top-right-radius: 7 / 7.1 + vh;
      }
    }
    &:last-child {
      border-right: 1px solid #e8e8e8;
      border-top-right-radius: 7 / 7.1 + vh;
      border-bottom-right-radius: 7 / 7.1 + vh;
      @media (max-width: 900px) {
        border-top-right-radius: 0;
        border-bottom: 1px solid #e8e8e8;
        border-bottom-left-radius: 7 / 7.1 + vh;
      }
    }
  }
  &__loading {
    td {
      background: #fff;
      border: 1px solid #e8e8e8;
      border-radius: 7 / 7.1 + vh;
      text-align: center;
      color: #ff4c00;
      @include padding(87.5, 17, 87.5, 17);
      aside {
        margin: 0 auto;
        @include width(40);
        @include height(40);
        path {
          stroke: #ff0000 !important;
        }
      }
    }
  }
  &__notfound {
    td {
      background: #fff;
      border: 1px solid #e8e8e8;
      border-radius: 7 / 7.1 + vh;
      text-align: center;
      color: #9999a1;
      @include padding(100, 17, 100, 17);
    }
  }
}
</style>
