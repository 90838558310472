import { http } from "@/shared/api";
import axios from "axios";
import { app } from "@/app/index.js";

export const namespace = "directoryValues";

export const module = {
  namespaced: true,
  state: {
    values: [],
    pending: false,
  },
  getters: {
    values: (s) => s.values,
    pending: (s) => s.pending,
  },
  mutations: {
    values(state, value) {
      state.values = value;
    },
    pending(state, value) {
      state.pending = value;
    },
  },
  actions: {
    async get({ state, commit }, directoryId) {
      app.$store.commit("errors", {});
      try {
        if (!state.pending) {
          commit("pending", true);
          commit("values", []);
          const response = await http.get(`/directories/${directoryId}/values`);
          commit("values", response.data.data);
          commit("pending", false);
        }
      } catch (error) {
        commit("pending", false);
      }
    },
    async create({ state, commit }, { directoryId, data }) {
      app.$store.commit("errors", {});
      if (!state.pending) {
        commit("pending", true);
        const response = await http.post(
          `/directories/${directoryId}/values`,
          data
        );
        commit("pending", false);
        return response;
      }
    },
    async createAll({ state, commit, dispatch }, { directoryId, dataList }) {
      app.$store.commit("errors", {});
      if (!state.pending && directoryId && dataList?.length) {
        commit("pending", true);
        const axiosList = dataList.map((data) => {
          return dispatch("create", { directoryId, data });
        });
        const response = await axios.all(axiosList);
        commit("pending", false);
        return response;
      }
    },
    async update({ state, commit }, { id, data }) {
      app.$store.commit("errors", {});
      if (!state.pending) {
        commit("pending", true);
        const response = await http.patch(`directory-values/${id}`, data);
        commit("pending", false);
        return response;
      }
    },
    async delete({ state, commit }, id) {
      app.$store.commit("errors", {});
      try {
        if (!state.pending) {
          commit("pending", true);
          const response = await http.delete(`directory-values/${id}`);
          commit("pending", false);
          return response;
        }
      } catch (error) {
        commit("pending", false);
      }
    },
  },
};
