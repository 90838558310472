export function convertDate(date) {
  if (!date) {
    return "-";
  }
  let newDate = date;
  if (date && typeof date === "string") {
    newDate = new Date(date.replace("-", "/"));
  }
  if (date && typeof date === "number") {
    newDate = new Date(date * 1000);
  }
  const day =
    newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
  const month =
    newDate.getMonth() + 1 < 10
      ? "0" + (newDate.getMonth() + 1)
      : newDate.getMonth();
  const year = newDate.getFullYear();
  return `${day}/${month}/${year}`;
}
