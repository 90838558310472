<template>
  <section class="avatar-simple__wrapper">
    <section v-show="!isCropper" class="avatar-simple">
      <label class="avatar-simple__content">
        <input
          type="file"
          ref="file"
          class="avatar-simple__value"
          @change="changeFile"
        />
        <img
          v-if="!isErrorAvatar && image && typeof image === 'string'"
          :src="image"
          alt="avatar"
          @error="() => (isErrorAvatar = true)"
        />
        <aside v-else class="avatar-simple__plug">
          {{ placeholder }}
        </aside>
      </label>
    </section>
    <cropper
      v-show="isCropper"
      class="cropper"
      :src="avatarImage"
      :stencil-component="$options.components.CircleStencil"
      :stencil-props="{
        aspectRatio: 10 / 10,
      }"
      @change="change"
    ></cropper>
    <aside v-if="errorAvatar" class="simple-error">{{ errorAvatar }}</aside>
    <aside v-if="isCropper" class="cropper-controls">
      <button-border
        :disabled="loadingAvatar"
        @click.native="changeAvatar"
        class="button"
        >Изменить изображение</button-border
      >
      <button-border
        :loadingLeft="loadingAvatar"
        @click.native="saveAvatar"
        class="button"
        >Сохранить</button-border
      >
    </aside>
    <circle-stencil v-if="false" />
  </section>
</template>

<script>
import { ButtonBorder } from "@/shared/ui";
import { Cropper, CircleStencil } from "vue-advanced-cropper";
import { viewerModel } from "@/entities/viewer";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "avatar-simple",
  components: { Cropper, CircleStencil, ButtonBorder },
  props: {
    image: {
      type: [String, Object],
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isErrorAvatar: false,
      loadingAvatar: false,
      isCropper: false,
      isImage: false,
      avatarImage: null,
      errorAvatar: null,
      file: null,
      cropFile: null,
    };
  },
  methods: {
    saveAvatar() {
      this.errorAvatar = "";
      const currentUser =
        this.$store.getters[viewerModel.namespace + "/current"];

      if (currentUser?.id && this.cropFile) {
        this.loadingAvatar = true;
        this.$store
          .dispatch(viewerModel.namespace + "/saveAvatar", {
            userId: currentUser.id,
            avatar: this.cropFile,
          })
          .then(() => {
            this.loadingAvatar = false;
            this.isCropper = false;
          })
          .catch(() => {
            this.errorAvatar = "Не удалось загрузить изображение!";
            this.loadingAvatar = false;
          });
      }
    },
    changeAvatar() {
      this.$refs.file.click();
    },
    change({ canvas }) {
      this.cropFile = this.dataURLtoFile(canvas.toDataURL(), "avatar.jpeg");
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    changeFile(event) {
      const files = event.target.files;
      const vue = this;
      for (let i = 0; i < files?.length; i++) {
        const file = files[i];
        if (file.type.startsWith("image/")) {
          this.file = file;
          const reader = new FileReader();
          reader.onload = function (e) {
            vue.avatarImage = e.target.result;
            vue.isCropper = true;
          };
          reader.readAsDataURL(file);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-simple {
  position: relative;
  background: #fff;
  border-radius: 20 / 7.1 + vh;
  border: 1px solid #e8e8e8;
  max-width: 246 / 7.1 + vh;
  width: 100%;
  overflow: hidden;
  &__wrapper {
    .cropper-controls {
      display: flex;
      @include margin(10, 0, 0, -10);
      .button {
        @include margin(0, 0, 0, 10);
      }
    }
    .cropper {
      width: 100%;
      max-width: 246 / 7.1 + vh;
    }
  }
  &__value {
    display: none;
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
  }
  &__plug {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #949494;
    @include fontSize(100);
  }
  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &:hover {
      &:before {
        content: "Изменить";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #00000080;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
      }
    }
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #00000080;
      color: #fff;
      opacity: 0;
      translate: opacity 0.3s ease;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}
.simple-error {
  color: #ff0000;
  @include fontSize(10);
  @include margin(2, 0, 0, 0);
}
</style>
