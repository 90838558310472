<template>
  <section v-if="!loading && !message">
    <section>
      Вы действительно хотите разархивировать оффер:
      <strong>«{{ offerName }}»</strong>?
    </section>
    <section v-if="isActions" class="actions">
      <button-border @click.native="reject">Нет</button-border>
      <ui-button @click.native="resolve">Да</ui-button>
    </section>
  </section>
  <section v-else-if="message">
    {{ message }}
    <section v-if="isActions" class="actions">
      <button-border @click.native="reject">Нет</button-border>
      <ui-button @click.native="resolve">Да</ui-button>
    </section>
  </section>
  <section v-else class="modal-loading">
    <loading-simple />
  </section>
</template>

<script>
import { LoadingSimple, UiButton, ButtonBorder } from "@/shared/ui";
import { offerModel } from "@/entities/offer";

export default {
  name: "feature-offer-archive-out",
  components: {
    UiButton,
    ButtonBorder,
    LoadingSimple,
  },
  data() {
    return {
      offerId: null,
      offerName: "",
      loading: false,
      message: null,
      isActions: true,
    };
  },
  created() {
    const offer = window.offer;
    if (offer) {
      this.offerName = offer.name;
      this.offerId = offer.id;
    }
  },
  methods: {
    resolve() {
      this.isActions = true;
      this.message = null;
      this.loading = true;
      this.$store
        .dispatch(offerModel.namespace + "/update", {
          id: this.offerId,
          data: {
            status: "unarchived",
          },
        })
        .then(() => {
          this.$store.commit(
            offerModel.namespace + "/" + offerModel.namespace,
            []
          );
          this.$store.dispatch(offerModel.namespace + "/get").finally(() => {
            this.$emit("close");
            this.loading = false;
          });
        })
        .catch(() => {
          this.isActions = true;
          this.message = `Не удалось разархивировать оффер: «${this.offerName}». Повторить попытку?`;
          this.loading = false;
        });
    },
    reject() {
      this.message = null;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  @include margin(15, 0, 0, -10);
  button {
    width: max-content;
    @include margin(0, 0, 0, 10);
  }
}
.modal-loading {
  aside {
    height: 40px;
    color: #f55320;
  }
}
</style>
