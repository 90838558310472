<template>
  <section ref="select" tabindex="0" class="ui-select">
    <input
      ref="input"
      @input="changeValue"
      @click="focusInput"
      class="ui-select__value"
      placeholder="Поиск..."
    />
    <div class="ui-select__icon">
      <chevron-icon />
    </div>
    <ul class="ui-select__options" @scroll="scrollPage">
      <template v-if="!pending && options.length">
        <li
          v-for="(option, index) in options"
          :key="index"
          class="ui-select__option"
          :class="{
            'ui-select__option--active': selectedIdList.includes(option.id),
          }"
          @click="selected(option)"
        >
          <slot name="option" :option="option">{{ option }}</slot>
        </li>
      </template>
      <li
        v-else-if="!pending && !options.length"
        class="ui-select__option--notfound"
      >
        Нет данных
      </li>
      <li v-else class="ui-select__option--loading">
        <loading-simple />
      </li>
    </ul>
  </section>
</template>

<script>
import { ChevronIcon } from "@/shared/icons";
import { LoadingSimple } from "@/shared/ui";

export default {
  name: "UiSelect",
  components: { ChevronIcon, LoadingSimple },
  props: {
    value: {
      type: [String, Array, Number, Object],
      default: null,
    },
    multiselect: {
      type: Boolean,
      required: false,
    },
    maskValue: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    outputId: {
      type: Boolean,
      default: true,
    },
    defaultValue: {
      type: String,
      default: "Не выбрано",
    },
    filterName: {
      type: String,
      default: "",
    },
    filterValue: {
      type: String,
      default: "",
    },
    additionalFilter: {
      type: String,
      default: "",
    },
    parameters: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      concating: false,
      namespace: this.method,
      isSearch: true,
      isFocus: true,
      pending: false,
      timerId: 0,
      options: [],
      saveOptions: [],
      valueInput: "",
      lastValueInput: "",
      selectedList: [],
      selectedIdList: [],
      meta: null,
      saveMeta: null,
    };
  },
  mounted() {
    let parameters = {
      isSelect: true,
    };
    if (
      typeof this.parameters === "object" &&
      Object.keys(this.parameters)?.length
    ) {
      parameters = { ...parameters, ...this.parameters };
    }
    const inputElement = this.$refs.input;
    parameters["additionalFilter"] = this.additionalFilter;
    if (this.filterName && this.filterValue) {
      parameters["filter"] = this.filterName;
      parameters["filterValue"] = this.filterValue;
    }
    this.$emit("loading", true);
    this.$store.dispatch(this.method, parameters).then((response) => {
      const temp = response?.data ? response.data : [];
      if (response?.meta) {
        this.meta = response.meta || null;
        this.saveMeta = response.meta || null;
      }
      this.saveOptions = [...temp];
      this.options = temp;
      this.$emit("count", this.options.length);
      if (this.value) {
        this.setInput(this.value);
      } else if (this.options?.length) {
        this.selectedIdList.push(this.options[0]["id"]);
        this.selectedList.push(this.options[0]);
        this.valueInput = this.applyMask(this.options[0]);
        // this.$refs.input.value = this.valueInput;
        inputElement.value = this.valueInput;
        if (this.multiselect) {
          if (this.outputId) {
            this.$emit("input", this.selectedIdList);
          } else {
            this.$emit("input", this.selectedList);
          }
        } else {
          if (this.outputId) {
            this.$emit("input", this.selectedIdList[0]);
            this.$emit("change", this.selectedList[0]);
          } else {
            this.$emit("input", this.selectedList[0]);
          }
        }
      }
      this.$emit("loading", false);
    });
  },
  watch: {
    parameters() {
      let parameters = {
        isSelect: true,
      };
      if (
        typeof this.parameters === "object" &&
        Object.keys(this.parameters)?.length
      ) {
        parameters = { ...parameters, ...this.parameters };
      }
      const inputElement = this.$refs.input;
      parameters["additionalFilter"] = this.additionalFilter;
      if (this.filterName && this.filterValue) {
        parameters["filter"] = this.filterName;
        parameters["filterValue"] = this.filterValue;
      }
      this.$emit("loading", true);
      this.$store.dispatch(this.method, parameters).then((response) => {
        const temp = response?.data ? response.data : [];
        if (response?.meta) {
          this.meta = response.meta || null;
          this.saveMeta = response.meta || null;
        }
        this.saveOptions = [...temp];
        this.options = temp;
        this.$emit("count", this.options.length);
        if (this.value) {
          this.setInput(this.value);
        } else if (this.options?.length) {
          this.selectedIdList.push(this.options[0]["id"]);
          this.selectedList.push(this.options[0]);
          this.valueInput = this.applyMask(this.options[0]);
          // this.$refs.input.value = this.valueInput;
          inputElement.value = this.valueInput;
          if (this.multiselect) {
            if (this.outputId) {
              this.$emit("input", this.selectedIdList);
            } else {
              this.$emit("input", this.selectedList);
            }
          } else {
            if (this.outputId) {
              this.$emit("input", this.selectedIdList[0]);
              this.$emit("change", this.selectedList[0]);
            } else {
              this.$emit("input", this.selectedList[0]);
            }
          }
        }
        this.$emit("loading", false);
      });
    },
    filterName() {
      let parameters = {
        isSelect: true,
      };
      if (
        typeof this.parameters === "object" &&
        Object.keys(this.parameters)?.length
      ) {
        parameters = { ...parameters, ...this.parameters };
      }

      parameters["additionalFilter"] = this.additionalFilter;
      if (this.filterName && this.filterValue) {
        parameters["filter"] = this.filterName;
        parameters["filterValue"] = this.filterValue;
      }
      // this.$emit("loading", true);
      this.$store.dispatch(this.method, parameters).then((response) => {
        this.options = response.data;
        if (response?.meta) {
          this.meta = response.meta;
          this.saveMeta = response.meta;
        }

        if (this.value) {
          this.setInput(this.value);
        } else if (this.options?.length) {
          this.selectedIdList.push(this.options[0]["id"]);
          this.selectedList.push(this.options[0]);
          this.valueInput = this.applyMask(this.options[0]);
          this.$refs.input.value = this.valueInput;
          if (this.multiselect) {
            if (this.outputId) {
              this.$emit("input", this.selectedIdList);
            } else {
              this.$emit("input", this.selectedList);
            }
          } else {
            if (this.outputId) {
              this.$emit("input", this.selectedIdList[0]);
            } else {
              this.$emit("input", this.selectedList[0]);
            }
          }
        }
      });
    },
    filterValue() {
      let parameters = {
        isSelect: true,
      };
      if (
        typeof this.parameters === "object" &&
        Object.keys(this.parameters)?.length
      ) {
        parameters = { ...parameters, ...this.parameters };
      }
      parameters["additionalFilter"] = this.additionalFilter;
      if (this.filterName && this.filterValue) {
        parameters["filter"] = this.filterName;
        parameters["filterValue"] = this.filterValue;
      }
      // this.$emit("loading", true);
      this.$store.dispatch(this.method, parameters).then((response) => {
        this.options = response.data;
        if (response?.meta) {
          this.meta = response.meta;
          this.saveMeta = response.meta;
        }
        this.$emit("count", this.options.length);
        if (this.value) {
          this.setInput(this.value);
        } else if (this.options?.length) {
          this.selectedIdList.push(this.options[0]["id"]);
          this.selectedList.push(this.options[0]);
          this.valueInput = this.applyMask(this.options[0]);
          this.$refs.input.value = this.valueInput;
          if (this.multiselect) {
            if (this.outputId) {
              this.$emit("input", this.selectedIdList);
            } else {
              this.$emit("input", this.selectedList);
            }
          } else {
            if (this.outputId) {
              this.$emit("input", this.selectedIdList[0]);
            } else {
              this.$emit("input", this.selectedList[0]);
            }
          }
        }
        // this.$emit("loading", false);
      });
    },
    value(value, oldValue) {
      if (value !== oldValue) {
        this.setInput(value);
      }
    },
  },
  methods: {
    scrollPage(e) {
      if (
        e.target.scrollTop + e.target.clientHeight + 20 >=
        e.target.scrollHeight
      ) {
        if (!this.concating && this.meta?.current_page < this.meta?.last_page) {
          this.concating = true;
          this.$store
            .dispatch(this.namespace, {
              isSelect: true,
              page: this.meta.current_page + 1,
            })
            .then((response) => {
              this.options = this.options.concat(response.data);
              // this.$emit("count", this.options.length);
              this.saveOptions = [...this.options];
              this.meta.current_page = response.meta.current_page;
            })
            .finally(() => {
              this.concating = false;
            });
        }
      }
    },
    callbackScrollEnd() {
      this.options =
        this.$store.getters[
          this.method.slice(0, -4) + "/" + this.method.slice(0, -4)
        ];
    },
    setInput(value) {
      this.selectedIdList = [];
      this.selectedList = [];
      this.valueInput = "";
      this.$refs.input.value = this.valueInput;

      if (value) {
        if (typeof value !== "object") {
          let index = -1;
          this.options.forEach((option, i) => {
            if (option.id === value) {
              index = i;
            }
          });

          if (index + 1) {
            this.selectedIdList.push(this.options[index]["id"]);
            this.selectedList.push(this.options[index]);
            this.valueInput = this.applyMask(this.options[index]);
            this.$refs.input.value = this.valueInput;
            if (this.multiselect) {
              if (this.outputId) {
                this.$emit("input", this.selectedIdList);
              } else {
                this.$emit("input", this.selectedList);
              }
            } else {
              if (this.outputId) {
                this.$emit("input", this.selectedIdList[0]);
              } else {
                this.$emit("input", this.selectedList[0]);
              }
            }
          } else {
            // если не нашли нужного поля в полученном списке
            let parameters = {
              isSelect: true,
            };
            if (
              typeof this.parameters === "object" &&
              Object.keys(this.parameters)?.length
            ) {
              parameters = { ...parameters, ...this.parameters };
            }
            parameters["additionalFilter"] = this.additionalFilter;
            if (this.filterName && this.filterValue) {
              parameters["filter"] = this.filterName;
              parameters["filterValue"] = this.filterValue;
            }
            this.$store
              .dispatch(this.method, {
                id: value,
                ...parameters,
              })
              .then((response) => {
                this.selectedIdList.push(response["id"]);
                this.selectedList.push(response);
                this.valueInput = this.applyMask(response);
                this.$refs.input.value = this.valueInput;
                if (this.multiselect) {
                  if (this.outputId) {
                    this.$emit("input", this.selectedIdList);
                  } else {
                    this.$emit("input", this.selectedList);
                  }
                } else {
                  if (this.outputId) {
                    this.$emit("input", this.selectedIdList[0]);
                    this.$emit("change", this.selectedList[0]);
                  } else {
                    this.$emit("input", this.selectedList[0]);
                  }
                }
              });
          }
        } else if (value?.length) {
          value.forEach((val) => {
            let index = -1;
            if (typeof val === "object") {
              this.options.forEach((option, i) => {
                if (option.id === val.id) {
                  index = i;
                }
              });
            } else {
              this.options.forEach((option, i) => {
                if (option.id === val) {
                  index = i;
                }
              });
            }
            if (index + 1) {
              this.selectedIdList.push(this.options[index]["id"]);
              this.selectedList.push(this.options[index]);
              this.valueInput = this.applyMask(this.options[index]);
              this.$refs.input.value = this.valueInput;
              if (this.multiselect) {
                if (this.outputId) {
                  this.$emit("input", this.selectedIdList);
                } else {
                  this.$emit("input", this.selectedList);
                }
              } else {
                if (this.outputId) {
                  this.$emit("input", this.selectedIdList[0]);
                } else {
                  this.$emit("input", this.selectedList[0]);
                }
              }
            }
          });
        } else {
          let index = -1;
          this.options.forEach((option, i) => {
            if (option.id === value.id) {
              index = i;
            }
          });
          if (index + 1) {
            this.selectedIdList.push(this.options[index]["id"]);
            this.selectedList.push(this.options[index]);
            this.valueInput = this.applyMask(this.options[index]);
            this.$refs.input.value = this.valueInput;
            if (this.multiselect) {
              if (this.outputId) {
                this.$emit("input", this.selectedIdList);
              } else {
                this.$emit("input", this.selectedList);
              }
            } else {
              if (this.outputId) {
                this.$emit("input", this.selectedIdList[0]);
              } else {
                this.$emit("input", this.selectedList[0]);
              }
            }
          }
        }
      }
    },
    applyMask(obj) {
      if (typeof obj === "object" && (obj?.length || obj.length === 0)) {
        let temp = "";
        obj.forEach((item, index) => {
          if (!/{*}/.test(this.maskValue)) {
            temp += item[this.maskValue];
          } else {
            temp +=
              this.maskValue.replace(/{(.*?)}/gm, function (value) {
                return item[value.replace(/{|}/g, "")];
              }) || "";
          }
          if (index + 1 !== obj.length) {
            temp += ", ";
          }
        });
        return temp;
      } else {
        if (!/{*}/.test(this.maskValue)) {
          return obj[this.maskValue] ? obj[this.maskValue] : "";
        } else {
          return (
            this.maskValue.replace(/{(.*?)}/gm, function (value) {
              return obj[value.replace(/{|}/g, "")];
            }) || ""
          );
        }
      }
    },
    blurSelect(event) {
      const list = [
        "ui-select__value",
        "ui-select__option",
        "ui-select__options",
        "ui-select__icon",
      ];
      let checkClassName = true;
      [...event.target.classList].forEach((className) => {
        if (list.includes(className)) {
          checkClassName = false;
        }
      });

      if (checkClassName) {
        this.$refs.select.classList.remove("ui-select--focus-within");
        document.body.removeEventListener("click", this.blurSelect);
        this.isSearch = false;
        this.valueInput = this.lastValueInput;
        this.$refs.input.value = this.valueInput;
        this.options = [...this.saveOptions];
      }
    },
    focusInput(event) {
      if (this.isFocus) {
        this.$refs.select.classList.add("ui-select--focus-within");
        event.preventDefault();
        document.body.addEventListener("click", this.blurSelect);
        this.lastValueInput = this.valueInput;
        // this.$refs.input.value = this.valueInput;
        this.$refs.input.value = "";
      } else {
        this.isFocus = true;
      }
    },
    selected(option) {
      if (this.multiselect) {
        const index = this.selectedIdList.indexOf(option.id);
        if (index + 1) {
          this.selectedList.splice(index, 1);
          this.selectedIdList.splice(index, 1);
          this.valueInput = this.applyMask(this.selectedList);
        } else {
          this.selectedList.push(option);
          this.selectedIdList.push(option.id);
          this.valueInput = this.applyMask(this.selectedList);
        }
      } else {
        this.selectedList.splice(0, 1, option);
        this.selectedIdList.splice(0, 1, option.id);
        this.valueInput = this.applyMask(this.selectedList);
      }

      if (this.multiselect) {
        if (this.outputId) {
          this.$emit("input", this.selectedIdList);
        } else {
          this.$emit("input", this.selectedList);
        }
      } else {
        this.isFocus = false;
        this.isSearch = false;
        this.$refs.select.classList.remove("ui-select--focus-within");
        this.$refs.input.value = this.applyMask(this.selectedList[0]);
        if (this.outputId) {
          this.$emit("input", this.selectedIdList[0]);
          this.$emit("change", this.selectedList[0]);
        } else {
          this.$emit("input", this.selectedList[0]);
        }
        this.options = [...this.saveOptions];
        document.body.removeEventListener("click", this.blurSelect);
        this.$refs.input.blur();
      }
      this.$refs.input.value = this.valueInput;
    },
    changeValue(e) {
      clearTimeout(this.timerId);
      if (this.isSearch) {
        this.pending = true;
        this.timerId = setTimeout(() => {
          let parameters = {
            isSelect: true,
          };
          if (
            typeof this.parameters === "object" &&
            Object.keys(this.parameters)?.length
          ) {
            parameters = { ...parameters, ...this.parameters };
          }
          parameters["filterValue"] = e.target.value;
          parameters["additionalFilter"] = this.additionalFilter;
          // this.$emit("loading", true);
          this.$store
            .dispatch(this.method, parameters)
            .then((response) => {
              this.saveOptions = response.data;
              this.options = response.data;
              if (response?.meta) {
                this.meta = response.meta;
                this.saveMeta = response.meta;
              }
            })
            .finally(() => {
              // this.$emit("loading", true);
              this.pending = false;
            });
        }, 800);
      } else {
        this.isSearch = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-select {
  position: relative;
  border: 1 / 7.1 + vh solid #e8e8e8;
  border-radius: 17 / 7.1 + vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  @include fontSize(10);
  @include height(33);
  @include padding(0, 18, 0, 18);
  &--focus-within {
    border-color: #f55320;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .ui-select__icon {
      color: #f55320;
      transform: rotate(180deg);
    }
    .ui-select__options {
      display: block;
      color: #f55320;
      height: max-content;
      max-height: 250 / 7.1 + vh;
    }
  }
  @media (max-width: 500px) {
    border: 1px solid #e8e8e8;
  }
  &__value {
    outline: none;
    border: none;
    width: 100%;
    @include padding(0, 10, 0, 0);
    @include fontSize(10);
  }
  &__option {
    color: #000;
    margin: 0;
    list-style-type: none;
    @include padding(10, 18, 10, 18);
    cursor: pointer;
    &:hover {
      background: #f0f0f0;
    }
    &--loading {
      color: #f55320;
      @include padding(18, 0, 18, 0);
      aside {
        @include height(20);
      }
    }
    &--notfound {
      @include padding(18, 0, 18, 0);
      text-align: center;
      color: #f55320;
    }
    &--active {
      background: #f55320;
      color: #fff;
      &:hover {
        background: #f55320;
      }
    }
  }
  &__options {
    display: none;
    position: absolute;
    top: 100%;
    left: -1px;
    overflow: auto;
    border: 1px solid red;
    background: #fff;
    z-index: 1;
    margin: 0;
    border-radius: 17 / 7.1 + vh;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: calc(100% + 2px);
    margin: 0;
    padding: 0;
    @include fontSize(10);
    height: max-content;
  }
  &__icon {
    transition: all 0.3s ease;
    color: #e8e8e8;
  }
}
</style>
