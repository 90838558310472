const typeList = {
  string: "Строка",
  number: "Число",
  directory: "Справочник",
  boolean: "Логический",
  text: "Текст",
  file: "Файл",
  manager: "Менеджер",
};

function convertType(type) {
  if (typeList[type]) {
    return typeList[type];
  } else {
    return type;
  }
}

export { typeList, convertType };
