import {
  AuthByPasswordModel,
  AuthRestorePasswordModel,
  AuthResetPasswordModel,
} from "./auth";
import { selectModalModel } from "./select-modal";

export const features = {
  AuthByPasswordModel,
  AuthRestorePasswordModel,
  AuthResetPasswordModel,
  selectModalModel,
};
