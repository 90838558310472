import { generateModuleStore } from "@/shared/lib";

const namespace = "companies";

const module = generateModuleStore({
  namespace,
  filterName: "name",
});

export { namespace, module };
