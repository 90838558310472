<template>
  <section class="modal-simple__box">
    <section class="modal-simple__bg" @click="close(notClose)"></section>
    <section class="modal-simple" ref="modal">
      <section class="modal-simple__header">
        <label v-if="label">{{ label }}</label>
        <div class="close" @click="close(false)" ref="close">&#x2715;</div>
      </section>
      <section>
        <slot></slot>
      </section>
    </section>
  </section>
</template>

<script>
export default {
  name: "ModalSimple",
  props: {
    label: {
      type: String,
      default: "",
    },
    notClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      animationTimer: 0,
    };
  },
  methods: {
    close(notClose = false) {
      if (!notClose) {
        this.$emit("close");
      } else {
        clearTimeout(this.animationTimer);
        this.$refs.modal.style.animation = "0.85s ease-in zoomInZoom";
        this.animationTimer = setTimeout(() => {
          this.$refs.modal.style.animation = "";
        }, 850);
      }
    },
  },
};
</script>

<style lang="scss">
.modal-simple {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10 / 7.1 + vh;
  box-shadow: 0 0 20 / 7.1 + vh 0 rgba(0, 0, 0, 0.2);
  @include padding(0, 20, 20, 20);
  @include width(400);
  @media (max-width: 400px) {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
  &__box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &__bg {
    width: 100%;
    height: 100%;
    background: #00000059;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    @include padding(10, 0, 10, 0);
    @include fontSize(16);
    .close {
      margin-left: auto;
      opacity: 0.5;
      cursor: pointer;
      transition: all 0.5s ease;
      &:hover {
        opacity: 1;
        transform: rotate(90deg);
      }
    }
  }
}
@keyframes zoomInZoom {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
  12% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  37% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  62% {
    transform: translate(-50%, -50%) scale(1.05);
  }
  87% {
    transform: translate(-50%, -50%) scale(1.025);
  }
}
</style>
