import { userModel } from "./user";
import { managerModel } from "./manager";
import { fileModel } from "./file";
import { candidateModel } from "./candidate";
import { companyModel } from "./company";
import { companyEmployeesModel } from "./company-employees";
import { directoryModel } from "./directory";
import { directoryValuesModel } from "./directory-value";
import { vacancyTemplateModel } from "./vacancy-template";
import { fieldModel } from "./field";
import { fieldValueModel } from "./field-value";
import { vacancyModel } from "./vacancy";
import { offerModel } from "./offer";
import { responseModel } from "./response";
import { viewerModel } from "./viewer";
import { notificationModel } from "./notification";

export const entities = {
  fileModel,
  companyModel,
  fieldValueModel,
  companyEmployeesModel,
  userModel,
  managerModel,
  fieldModel,
  vacancyModel,
  directoryModel,
  vacancyTemplateModel,
  viewerModel,
  offerModel,
  responseModel,
  directoryValuesModel,
  candidateModel,
  notificationModel,
};
