<template>
  <section class="simple">
    <header-widget />
    <section class="layout-header">
      <h2>{{ $route.meta.title }}</h2>
      <button-border v-if="viewCreateButton" @click.native="create">
        Добавить
        <template #icon>
          <plus-icon />
        </template>
      </button-border>
    </section>
    <section class="content">
      <router-view />
    </section>
  </section>
</template>

<script>
import { HeaderWidget } from "@/widgets/header";
import { ButtonBorder } from "@/shared/ui";
import { PlusIcon } from "@/shared/icons";
import Cookies from "js-cookie";
import { checkAccess } from "@/shared/lib";

export default {
  name: "layout-simple",
  components: {
    HeaderWidget,
    ButtonBorder,
    PlusIcon,
  },
  computed: {
    viewCreateButton() {
      const accessLevel = Cookies.get("ft_access_level");
      if (accessLevel && this.$route.name === "company-employee-list") {
        return checkAccess("company-employee-list", Number(accessLevel));
      } else {
        return false;
      }
    },
  },
  methods: {
    create() {
      if (this.$route.name === "company-employee-list") {
        this.$router.push({
          name: "company-employee-create",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-style: normal;
  color: #444855;
  font-family: "gilroy-medium", "Helvetica", "Arial", "sans-serif";
  margin: 0;
  padding: 0;
  @include fontSize(18);
}
.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include padding(30, 50, 30, 50);
  @media (max-width: 900px) {
    padding: 20px;
  }
  h2 {
    padding: 0;
  }
}
.simple {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.content {
  overflow: auto;
  border-top: 1 / 7.1 + vw solid #e8e8e8;
  height: 100%;
  background: #fafafa;
  @include padding(20, 50, 0, 50);
  @media (max-width: 900px) {
    padding: 20px 20px 0;
  }
}
.layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include padding(30, 50, 30, 50);
  @media (max-width: 900px) {
    padding: 20px;
  }
}
</style>
