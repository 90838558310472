import { http } from "@/shared/api";

export const namespace = "companyEmployees";

export const module = {
  namespaced: true,
  state: {
    [namespace]: [],
    concating: false,
    pending: false,
    entity: null,
    meta: null,
  },
  getters: {
    [namespace]: (s) => s[namespace],
    pending: (s) => s.pending,
    entity: (s) => s.entity,
    concating: (s) => s.concating,
    meta: (s) => s.meta,
  },
  mutations: {
    [namespace](state, value) {
      state[namespace] = value;
    },
    pending(state, value) {
      state.pending = value;
    },
    concating(state, value) {
      state.concating = value;
    },
    meta(state, value) {
      state.meta = value;
    },
    entity(state, value) {
      state.entity = value;
    },
    [namespace + "Concat"](state, value) {
      if (state[namespace]?.length) {
        state[namespace] = state[namespace].concat(value);
      }
    },
  },
  actions: {
    async get({ state, commit }, parameters) {
      try {
        let isSelect = false;
        let id = null;
        let companyId = null;
        let page = 1;
        let concat = false;
        let query = "";
        let filter = "email";
        let filterValue = "";
        let additionalFilter = "";

        if (parameters?.isSelect) {
          isSelect = parameters.isSelect;
        }
        if (parameters?.id) {
          id = parameters.id;
        }
        if (parameters?.companyId) {
          companyId = parameters.companyId;
        }
        if (parameters?.page) {
          page = parameters.page;
        }

        if (parameters?.filter) {
          filter = parameters.filter;
        }
        if (parameters?.filterValue) {
          filterValue = parameters.filterValue;
        }
        if (typeof parameters?.concat === "boolean") {
          concat = parameters.concat;
          page = state.meta.current_page + 1;
        }
        if (parameters?.additionalFilter) {
          additionalFilter = "&" + parameters.additionalFilter;
        }

        query += `?page=${page}&filter[${filter}]=${filterValue}${additionalFilter}`;

        if (isSelect) {
          if (companyId) {
            const response = await http.get(
              `/companies/${companyId}/users` + query
            );
            return response.data;
          } else {
            return [];
          }
        } else if (!state.pending) {
          if (id) {
            commit("pending", true);
            commit(namespace, null);
            const response = await http.get(
              `/companies/${companyId}/users/${id}`
            );
            commit("entity", response.data.data);
            commit("pending", false);
            return response.data.data;
          } else if (concat) {
            if (
              !state.concating &&
              state.meta?.current_page < state.meta?.last_page
            ) {
              commit("concating", true);
              const response = await http.get(
                `/companies/${companyId}/users` + query
              );
              commit(namespace + "Concat", response.data.data);
              commit("meta", response.data.meta);
              commit("concating", false);
              return response.data;
            }
          } else {
            commit("pending", true);
            commit(namespace, []);
            const response = await http.get(
              `/companies/${companyId}/users` + query
            );
            commit(namespace, response.data.data);
            commit("pending", false);
            return response.data;
          }
        }
      } catch (error) {
        commit("pending", false);
      }
    },
    inviteRegister(_, data) {
      return new Promise((resolve, reject) => {
        http
          .post("/company-invites/register", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    invite(_, data) {
      return new Promise((resolve, reject) => {
        http
          .post("/company-invites", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    create({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        if (!state.pending && data.companyId && data.data) {
          commit("pending", true);
          http
            .post(`/companies/${data.companyId}/users`, data.data)
            .then((response) => {
              commit("pending", false);
              resolve(response);
            })
            .catch((error) => {
              commit("pending", false);
              reject(error);
            });
        } else {
          reject(false);
        }
      });
    },
    delete({ state, commit }, { id, companyId }) {
      return new Promise((resolve, reject) => {
        if (!state.pending && id && companyId) {
          commit("pending", true);
          http
            .delete(`/companies/${companyId}/users/${id}`)
            .then((response) => {
              commit("pending", false);
              resolve(response);
            })
            .catch((error) => {
              commit("pending", false);
              reject(error);
            });
        } else {
          reject(false);
        }
      });
    },
    update({ state, commit }, { id, companyId, companyAccessLevel }) {
      return new Promise((resolve, reject) => {
        if (!state.pending && id && companyId && companyAccessLevel) {
          commit("pending", true);
          http
            .patch(
              `/companies/${companyId}/users/${id}?company_access_level=${companyAccessLevel}`,
              {
                company_access_level: companyAccessLevel,
              }
            )
            .then((response) => {
              commit("pending", false);
              resolve(response);
            })
            .catch((error) => {
              commit("pending", false);
              reject(error);
            });
        } else {
          reject(false);
        }
      });
    },
    createFromUser({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        if (!state.pending && data.companyId && data.userId && data.roleLevel) {
          commit("pending", true);
          http
            .get(
              `/companies/${data.companyId}/users/${data.userId}?company_access_level=${data.roleLevel}`
            )
            .then((response) => {
              commit("pending", false);
              resolve(response);
            })
            .catch((error) => {
              commit("pending", false);
              reject(error);
            });
        } else {
          reject(false);
        }
      });
    },
  },
};
