import { generateModuleStore } from "@/shared/lib";
import { http } from "@/shared/api";

const namespace = "vacancyTemplates";

const module = generateModuleStore({
  namespace,
  filterName: "name",
  actions: {
    async createField(_, { vacancyTemplateId, fieldId }) {
      const response = await http.post(
        `/vacancy-templates/${vacancyTemplateId}/fields/${fieldId}`
      );
      return response;
    },
    async deleteField(_, { vacancyTemplateId, fieldId }) {
      const response = await http.delete(
        `/vacancy-templates/${vacancyTemplateId}/fields/${fieldId}`
      );
      return response;
    },
  },
});

export { namespace, module };
