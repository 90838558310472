const USER = 1;
const ADMINISTRATOR = 90;
const DEVELOPER = 100;

const accessLevel = {
  [USER]: "Пользователь",
  [ADMINISTRATOR]: "Администратор",
  [DEVELOPER]: "Разработчик",
};

const access = {
  profile: [USER, ADMINISTRATOR, DEVELOPER],

  "vacancy-list": [USER, ADMINISTRATOR, DEVELOPER],
  "vacancy-create": [USER, ADMINISTRATOR, DEVELOPER],
  "vacancy-update": [USER, ADMINISTRATOR, DEVELOPER],

  "offer-list": [USER, ADMINISTRATOR, DEVELOPER],
  "offer-create": [USER, ADMINISTRATOR, DEVELOPER],
  "offer-update": [USER, ADMINISTRATOR, DEVELOPER],

  "candidate-list": [ADMINISTRATOR, DEVELOPER],
  "candidate-create": [ADMINISTRATOR, DEVELOPER],
  "candidate-update": [ADMINISTRATOR, DEVELOPER],
  "candidate-import": [ADMINISTRATOR, DEVELOPER],

  "company-list": [USER, ADMINISTRATOR, DEVELOPER],
  "company-create": [ADMINISTRATOR, DEVELOPER],
  "company-update": [ADMINISTRATOR, DEVELOPER],

  "company-employee-list": [USER, ADMINISTRATOR, DEVELOPER],
  "company-employee-create": [USER, ADMINISTRATOR, DEVELOPER],
  "company-employee-update": [USER, ADMINISTRATOR, DEVELOPER],

  "template-field-list": [ADMINISTRATOR, DEVELOPER],
  "template-field-create": [ADMINISTRATOR, DEVELOPER],
  "template-field-update": [ADMINISTRATOR, DEVELOPER],

  "template-directory-list": [ADMINISTRATOR, DEVELOPER],
  "template-directory-create": [ADMINISTRATOR, DEVELOPER],
  "template-directory-update": [ADMINISTRATOR, DEVELOPER],

  "template-vacancy-list": [ADMINISTRATOR, DEVELOPER],
  "template-vacancy-create": [ADMINISTRATOR, DEVELOPER],
  "template-vacancy-update": [ADMINISTRATOR, DEVELOPER],

  "manager-list": [ADMINISTRATOR, DEVELOPER],
  "manager-create": [ADMINISTRATOR, DEVELOPER],
  "manager-update": [ADMINISTRATOR, DEVELOPER],

  "user-list": [ADMINISTRATOR, DEVELOPER],
  "user-create": [ADMINISTRATOR, DEVELOPER],
  "user-update": [ADMINISTRATOR, DEVELOPER],
  "user-blocked": [ADMINISTRATOR, DEVELOPER],
  "user-change-password": [ADMINISTRATOR, DEVELOPER],

  "response-list": [ADMINISTRATOR, DEVELOPER, USER],
};

function convertAccessLevel(number) {
  if (accessLevel[number]) {
    return accessLevel[number];
  } else {
    return number;
  }
}

function checkAccess(entity, accessLevel) {
  if (access[entity]) {
    return access[entity].includes(accessLevel);
  } else {
    return false;
  }
}

export { accessLevel, convertAccessLevel, checkAccess };
