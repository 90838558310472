<template>
  <nav>
    <ul>
      <li>
        <router-link to="/" class="animate--users" title="Главная">
          <vacancy-icon />
        </router-link>
      </li>
      <li
        v-if="
          checkAccess('template-field-list', accessLevel) ||
          checkAccess('template-directory-list', accessLevel) ||
          checkAccess('template-vacancy-list', accessLevel)
        "
      >
        <router-link
          to="/template"
          class="animate--notification"
          title="Шаблоны"
        >
          <template-icon />
        </router-link>
      </li>
      <li>
        <router-link to="/settings" class="animate--setting" title="Настройки">
          <setting-icon />
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import VacancyIcon from "@/shared/icons/vacancy";
import TemplateIcon from "@/shared/icons/template";
import SettingIcon from "@/shared/icons/setting";
import { viewerModel } from "@/entities/viewer";
import { checkAccess } from "@/shared/lib";

export default {
  name: "nav-desktop-icon",
  components: {
    VacancyIcon,
    TemplateIcon,
    SettingIcon,
  },
  computed: {
    accessLevel() {
      if (this.$store.getters[viewerModel.namespace + "/current"]) {
        return Number(
          this.$store.getters[viewerModel.namespace + "/current"].access_level
        );
      } else {
        return 0;
      }
    },
  },
  methods: {
    checkAccess(entity, accessLevel) {
      return checkAccess(entity, accessLevel);
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    @include margin(0, 0, 0, 45);
    a {
      display: flex;
      color: #fff;
      width: auto;
      @include height(15);
      svg {
        width: auto;
        height: 100%;
      }
    }
  }
}
.animate {
  &--users {
    &:hover {
      @media (min-width: 500px) {
        animation: users 1.5s linear infinite;
      }
    }
  }
  &--notification {
    &:hover {
      @media (min-width: 500px) {
        animation: notification 0.5s linear infinite;
      }
    }
  }
  &--setting {
    &:hover {
      @media (min-width: 500px) {
        animation: setting 3s linear infinite;
      }
    }
  }
}

@keyframes users {
  0%,
  50%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(2px);
  }
  75% {
    transform: translateY(-2px);
  }
}

@keyframes notification {
  0%,
  50%,
  100% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
}

@keyframes setting {
  to {
    transform: rotate(360deg);
  }
}
</style>
