<template>
  <section class="wrapper">
    <header-widget @openMenu="(value) => (menuOpen = value)" />
    <topbar-simple />
    <section class="content">
      <router-view />
    </section>
  </section>
</template>

<script>
import { HeaderWidget } from "@/widgets/header";
import { TopbarSimple } from "@/widgets/topbar";

export default {
  name: "layout-vacancy",
  components: {
    HeaderWidget,
    TopbarSimple,
  },
  data() {
    return {
      menuOpen: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.content {
  background: #fafafa;
  width: 100%;
  height: 100%;
  padding: 32 / 7.1 + vh 64 / 7.1 + vh 0 64 / 7.1 + vh;
  overflow: auto;
  @media (max-width: 500px) {
    padding: 20 / 4.8 + vw;
  }
}
</style>
