import axios from "axios";
import { app } from "@/app/index.js";
import { viewerModel } from "@/entities/viewer";
import Cookies from "js-cookie";

const baseURL = process.env.VUE_APP_API;
const http = axios.create({ baseURL });

http.interceptors.request.use((config) => {
  return config;
});

export const namespace = "authByPassword";

export const module = {
  namespaced: true,
  state: {
    pending: false,
    email: "",
    password: "",
  },
  getters: {
    pending: (s) => s.pending,
    email: (s) => s.email,
    password: (s) => s.password,
  },
  mutations: {
    pending(state, value) {
      state.pending = value;
    },
    email(state, value) {
      state.email = value;
    },
    password(state, value) {
      state.password = value;
    },
  },
  actions: {
    signIn({ state, commit }) {
      return new Promise((resolve, reject) => {
        app.$store.commit("errors", {});
        if (!state.pending) {
          commit("pending", true);
          http
            .post("/login", {
              email: state.email,
              password: state.password,
            })
            .then((response) => {
              Cookies.set("token", response.data.token);
              // getting current user data
              app.$store
                .dispatch(viewerModel.namespace + "/current")
                .then(() => {
                  app.$router.push({ name: "vacancy-list" }).catch(() => {});
                  resolve(response);
                  commit("pending", false);
                })
                .catch((error) => {
                  commit("pending", false);
                  reject(error);
                });
            })
            .catch((error) => {
              // commit("errors", error.response.data.errors);
              reject(error);
              if (error.response.status === 422) {
                app.$store.commit("errors", error.response.data.errors);
              }
              commit("pending", false);
            });
        } else {
          resolve(true);
        }
      });
    },
  },
};
