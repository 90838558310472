<template>
  <header>
    <router-link
      v-if="companyCount || accessLevel >= 90"
      to="/vacany"
      title="Главная"
    >
      <img class="logo" :src="logo" alt="" />
    </router-link>
    <img v-else class="logo" :src="logo" alt="" />
    <div class="box">
      <nav-desktop-icon v-if="companyCount || accessLevel >= 90" />
      <label v-if="viewSwitchCompany" class="header-select">
        <!-- Filter by company -->
        <select-simple
          v-model="company"
          mask-value="{name}"
          :options="options"
          @selected="companyData"
        >
          <template #option="{ option }">{{ option.name }}</template>
        </select-simple>
      </label>
      <ViewerAvatar class="profile" />
    </div>
    <div class="mobile-menu" @click="toogleMenu">
      <burger-icon />
    </div>
  </header>
</template>

<script>
import NavDesktopIcon from "@/features/nav/desktop/icon";
import BurgerIcon from "@/shared/icons/burger";
import { ViewerAvatar } from "@/entities/viewer";
import Cookies from "js-cookie";
import { companyModel } from "@/entities/company";
import { viewerModel } from "@/entities/viewer";
import { SelectSimple } from "@/shared/ui";
import logoFt from "@/shared/img/logo-ft.png";
import logoPvm from "@/shared/img/logo-pvm.png";
import logoSber from "@/shared/img/logo-sber.png";
import logoGazprombank from "@/shared/img/logo-gazprombank.svg";

export default {
  name: "header-simple",
  components: { NavDesktopIcon, ViewerAvatar, BurgerIcon, SelectSimple },
  data() {
    return {
      openMenu: false,
      companyMethod: companyModel.namespace + "/get",
      companyCount: 0,
    };
  },
  computed: {
    viewSwitchCompany() {
      const currentUser =
        this.$store.getters[viewerModel.namespace + "/current"];

      if (this.accessLevel >= 90) {
        return true;
      }

      if (currentUser.companies.length > 1) {
        return true;
      } else {
        return false;
      }
    },
    logo() {
      const logos = {
        all: logoFt,
        1000: logoPvm,
        1001: logoFt,
        1002: logoSber,
        1003: logoGazprombank,
      };

      const company =
        this.$store.getters[viewerModel.namespace + "/currentCompany"];

      if (!company) return logoFt;

      const logo = logos[company.id];

      if (!logo) return "";

      return logo;
    },
    accessLevel() {
      const currentUser =
        this.$store.getters[viewerModel.namespace + "/current"];
      if (currentUser?.access_level) {
        return currentUser.access_level;
      } else {
        return 1;
      }
    },
    options() {
      const companyMethod =
        companyModel.namespace + "/" + companyModel.namespace;

      const currentUser =
        this.$store.getters[viewerModel.namespace + "/current"];

      const data = [
        {
          id: "all",
          name: "Все",
        },
      ];

      if (this.accessLevel >= 90) {
        const companies = this.$store.getters[companyMethod];

        return data.concat(companies || []);
      }

      if (currentUser?.companies?.length) {
        if (currentUser?.companies.length > 1) {
          return data.concat(currentUser?.companies);
        } else {
          return currentUser?.companies;
        }
      } else {
        return [];
      }
    },
    company: {
      get() {
        const currentUser =
          this.$store.getters[viewerModel.namespace + "/current"];

        const setup = localStorage.getItem(`setup_${currentUser.id}`);

        const company =
          this.$store.getters[viewerModel.namespace + "/currentCompany"];

        if (setup) {
          const setupObject = JSON.parse(setup);
          const company = setupObject?.filter?.company;
          if (company?.id) {
            return company.id;
          }
        }

        if (company) {
          return company.id;
        } else {
          return 0;
        }
      },
      set(value) {
        if (this.accessLevel < 90) return;

        if (value === "all") {
          this.$store.commit(viewerModel.namespace + "/currentCompany", {
            id: "all",
            name: "Все",
          });
        }

        const currentUser =
          this.$store.getters[viewerModel.namespace + "/current"];
        const companies = currentUser.companies;

        if (!companies?.length) return;

        const filters = companies.filter((company) => company.id === value);

        if (!filters?.length) return;

        const company = filters[0];

        this.$store.commit(viewerModel.namespace + "/currentCompany", company);
      },
    },
  },
  created() {
    const currentUser = this.$store.getters[viewerModel.namespace + "/current"];
    const currentCompany =
      this.$store.getters[viewerModel.namespace + "/currentCompany"];

    if (this.accessLevel >= 90) {
      const companyMethod = companyModel.namespace + "/get";
      this.$store.dispatch(companyMethod);
    }

    if (this.currentUser?.companies?.length) {
      if (currentUser.companies.length > 1) {
        this.viewSwitchCompany = true;
      }
      if (!currentCompany) {
        this.$store.commit(
          viewerModel.namespace + "/currentCompany",
          currentUser.companies[0]
        );
      }
    }
    this.companyCount = Number(Cookies.get("ft_companies_length"));
  },
  methods: {
    companyData(company) {
      if (this.accessLevel >= 90) {
        const method = viewerModel.namespace + "/currentCompany";
        this.$store.commit(method, company);
      }
    },
    toogleMenu() {
      this.openMenu = !this.openMenu;
      this.$emit("openMenu", this.openMenu);
    },
  },
};
</script>

<style lang="scss">
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include height(70);
  @include padding(0, 50, 0, 50);
  background: radial-gradient(
    113.64% 113.64% at 50% 86.36%,
    #ee6823 0%,
    #d11e12 100%
  );
  @media (max-width: 900px) {
    padding: 0 20px;
  }
  .logo {
    @include height(40);
  }
  .box {
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .mobile-menu {
    @media (min-width: 600px) {
      display: none;
    }
  }
  .profile {
    @include margin(0, 0, 0, 44);
  }
}
.header-select {
  @include margin(0, 0, 0, 44);
  .ui-select {
    background: #fff !important;
    &__options {
      border: 1px solid #e8e8e8 !important;
    }
    &--focus-within {
      border-color: #e8e8e8 !important;
    }
  }
}
</style>
