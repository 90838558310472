import { http } from "@/shared/api";
import { app } from "@/app/index.js";

function convertNamespace(namespace) {
  return namespace.replace(/[A-Z]/g, (c) => {
    return "-" + c.toLowerCase();
  });
}

const generateModuleStore = (data) => {
  let generateState = true;
  let generateActions = true;
  let generateMutations = true;
  if (data?.generateState === false) {
    generateState = false;
  }
  if (data?.generateActions === false) {
    generateActions = false;
  }
  if (data?.generateMutations === false) {
    generateMutations = false;
  }
  const transformNamespace = convertNamespace(data.namespace);

  const state = generateState
    ? {
        pending: false,
        concating: false,
        meta: null,
        aditionFilter: "",
        entity: null,
        [data.namespace]: null,
      }
    : {};
  const mutations = generateMutations
    ? {
        aditionFilter(state, value) {
          state.aditionFilter = value;
        },
        concating(state, value) {
          state.concating = value;
        },
        entity(state, value) {
          state.entity = value;
        },
        pending(state, value) {
          state.pending = value;
        },
        meta(state, value) {
          state.meta = value;
        },
        [data.namespace](state, value) {
          state[data.namespace] = value;
        },
        [data.namespace + "Concat"](state, value) {
          if (state[data.namespace]?.length) {
            state[data.namespace] = state[data.namespace].concat(value);
          }
        },
      }
    : {};

  const actions = generateActions
    ? {
        get({ state, commit }, parameters) {
          return new Promise((resolve, reject) => {
            let isSelect = false;
            let id = null;
            let page = 1;
            let concat = false;
            let query = "";
            let filter = data.filterName;
            let filterValue = "";
            let additionalFilter = "";

            if (parameters?.isSelect) {
              isSelect = parameters.isSelect;
            }
            if (parameters?.id) {
              id = parameters.id;
            }
            if (parameters?.page) {
              page = parameters.page;
            }
            if (parameters?.filter) {
              filter = parameters.filter;
            }
            if (parameters?.filterValue) {
              filterValue = parameters.filterValue;
            }
            if (parameters?.additionalFilter) {
              additionalFilter = "&" + parameters.additionalFilter;
            }
            if (typeof parameters?.concat === "boolean") {
              concat = parameters.concat;
              page = state.meta.current_page + 1;
            }
            query += `?page=${page}${state.aditionFilter}&filter[${filter}]=${filterValue}${additionalFilter}`;

            if (isSelect) {
              http
                .get("/" + transformNamespace + query)
                .then((response) => {
                  resolve(response.data);
                })
                .catch((error) => {
                  reject(error);
                });
            } else if (!state.pending) {
              if (id) {
                commit("pending", true);
                commit("entity", null);
                http
                  .get(`/${transformNamespace}/${id}`)
                  .then((response) => {
                    commit("entity", response.data.data);
                    commit("pending", false);
                    resolve(response.data.data);
                  })
                  .catch((error) => {
                    commit("pending", false);
                    reject(error);
                  });
              } else if (concat) {
                if (
                  !state.concating &&
                  state.meta?.current_page < state.meta?.last_page
                ) {
                  commit("concating", true);
                  http
                    .get("/" + transformNamespace + query)
                    .then((response) => {
                      commit(data.namespace + "Concat", response.data.data);
                      commit("meta", response.data.meta);
                      commit("concating", false);
                      resolve(response.data);
                    })
                    .catch((error) => {
                      commit("concating", false);
                      reject(error);
                    });
                }
              } else {
                commit("pending", true);
                commit(data.namespace, []);
                http
                  .get("/" + transformNamespace + query)
                  .then((response) => {
                    if (state[data.namespace]?.length) {
                      commit(
                        data.namespace,
                        state[data.namespace].concat(response.data.data)
                      );
                    } else {
                      state[data.namespace] = response.data.data;
                    }
                    commit("meta", response.data.meta);
                    commit("pending", false);
                    resolve(response.data);
                  })
                  .catch((error) => {
                    commit("pending", false);
                    reject(error);
                  });
              }
            }
          });
        },
        create({ state, commit }, data) {
          app.$store.commit("errors", {});
          return new Promise((resolve, reject) => {
            if (!state.pending) {
              commit("pending", true);
              http
                .post("/" + transformNamespace, data)
                .then((response) => {
                  resolve(response);
                  commit("pending", false);
                })
                .catch((error) => {
                  reject(error);
                  commit("pending", false);
                });
            } else {
              resolve(true);
            }
          });
        },
        update({ state, commit }, { id, data }) {
          app.$store.commit("errors", {});
          return new Promise((resolve, reject) => {
            if (!state.pending) {
              commit("pending", true);
              http
                .patch("/" + transformNamespace + "/" + id, data)
                .then((response) => {
                  resolve(response);
                  commit("pending", false);
                })
                .catch((error) => {
                  reject(error);
                  commit("pending", false);
                });
            } else {
              resolve(true);
            }
          });
        },
        delete({ state, commit }, id) {
          app.$store.commit("errors", {});
          return new Promise((resolve, reject) => {
            if (!state.pending) {
              commit("pending", true);
              http
                .delete("/" + transformNamespace + "/" + id)
                .then((response) => {
                  resolve(response);
                  commit("pending", false);
                })
                .catch((error) => {
                  reject(error);
                  commit("pending", false);
                });
            } else {
              resolve(true);
            }
          });
        },
      }
    : {};

  if (typeof data.state === "object" && data?.state) {
    Object.keys(data.state).forEach((key) => {
      state[key] = data.state[key];
    });
  }
  if (typeof data.actions === "object" && data?.actions) {
    Object.keys(data.actions).forEach((key) => {
      actions[key] = data.actions[key];
    });
  }
  if (typeof data.mutations === "object" && data?.mutations) {
    Object.keys(data.mutations).forEach((key) => {
      mutations[key] = data.mutations[key];
    });
  }
  return {
    namespaced: true,
    state,
    getters: {
      pending: (s) => s.pending,
      concating: (s) => s.concating,
      meta: (s) => s.meta,
      entity: (s) => s.entity,
      [data.namespace]: (s) => s[data.namespace],
    },
    mutations,
    actions,
  };
};

export { generateModuleStore };
